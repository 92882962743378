/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './style.css';

import moment from 'moment';
// import { ImPaste } from 'react-icons/im';
import SweetAlert2 from 'react-sweetalert2';
import { useAuth } from '../../hooks/useAuth';

const Promo = (props) => {
  const [swalProps, setSwalProps] = useState({});
  const [promoData, setPromoData] = useState();
  const { user, isLoading } = useAuth({
    middleware: 'auth'
  });

  const navigate = useNavigate();

  useEffect(() => {
    setPromoData(props.item);
  }, [props.item]);

  useEffect(() => {
    if (!isLoading && !user) {
      navigate('/account/email/signin');
    }
    window.scrollTo(0, 0);
  }, [user]);

  const copyFunction = (promoName) => {
    try {
      var copyText = document.getElementById(`couponInput${promoName}`);
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(copyText.value);
      setSwalProps({
        show: true,
        title: 'Copy Promo Success',
        text: 'Success copied ' + copyText.value,
        icon: 'success'
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="promoThumb">
      {props.tag == null && props.tag == undefined && props.tag === 'public' ? (
        <span className={`badge Umum`}>Umum</span>
      ) : (
        <span className={`badge Khusus Pengguna`}>Khusus Pengguna</span>
      )}

      {promoData !== undefined && (
        <>
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              navigate(`/promo/${promoData.id}`, { state: { type: promoData.type } });
            }}
          >
            <div className="imgWrapper">
              <div className="p-4 wrapper mb-3">
                <img
                  src={
                    `${promoData && promoData?.image_url ? promoData?.image_url : `${process.env.REACT_APP_BACKEND_URL}/storage/images/${promoData?.type === 'global' ? 'globalPromo' : 'promo'}/${promoData && promoData.image}`}` +
                    '?im=Resize=(420,420)'
                  }
                  className="w-100"
                  style={{ backgroundSize: 'cover', height: '100%', widt: '100%' }}
                />
              </div>
            </div>
          </div>

          <div className="info">
            <span className="d-block catName">{promoData.companyName}</span>
            <h4 className="title">
              <Link>{promoData.name}</Link>
            </h4>

            <hr />

            <div className="d-flex justify-content-between">
              <span className="brand d-block text-g">Start Date:</span>
              <span className="brand d-block text-g">{promoData && moment(promoData.start_date).format('DD MMMM YYYY')}</span>
            </div>

            <div className="d-flex justify-content-between">
              <span className="brand d-block text-g">End Date:</span>
              <span className="brand d-block text-g">{promoData && moment(promoData.end_date).format('DD MMMM YYYY')}</span>
            </div>

            <div className="d-flex justify-content-between">
              <span className="brand d-block text-g">Platform:</span>
              <span className="brand d-block text-g">Web, Android, IOS</span>
            </div>

            <div
              className="promoBox my-3 px-3 pb-3"
              style={{ cursor: 'pointer' }}
              onClick={() => copyFunction(promoData ? promoData.name : '')}
            >
              <div className="d-flex align-items-center mt-3">
                <div className="d-flex flex-column">
                  <div className="brand d-block text-g">PROMO CODE:</div>
                  <input
                    style={{ outline: 'none', border: 'none', backgroundColor: 'transparent', color: '#2365cc', cursor: 'pointer' }}
                    readOnly={true}
                    className="uniqueCode mb-2 price text-g font-weight-bold"
                    type="text"
                    onClick={() => copyFunction(promoData ? promoData.name : '')}
                    value={promoData ? promoData.name : ''}
                    id={`couponInput${promoData ? promoData.name : ''}`}
                  />
                </div>
                {/* <div className="mb-3">
                  <ImPaste  style={{ fontSize: '24px', cursor: 'pointer', color: '#9FA0A2' }} />
                </div> */}
              </div>
            </div>
          </div>
          <SweetAlert2
            {...swalProps}
            didClose={() => {
              setSwalProps({
                show: false
              });
            }}
          />
        </>
      )}
    </div>
  );
};

export default Promo;
