/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { Button } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import React, { useContext } from 'react';
import 'react-range-slider-input/dist/style.css';
import bannerImg from '../../assets/images/banner1.jpg';

import { MyContext } from '../../App';
import { useNavigate } from 'react-router-dom';

const SidebarPromo = (props) => {
  const navigate = useNavigate();
  const context = useContext(MyContext);

  const filterByViewType = (keyword) => {
    props.filterByViewType(keyword);
  };

  const filterByType = (keyword) => {
    props.filterByType(keyword);
  };

  return (
    <>
      <div className={`sidebar ${context.isOpenFilters === true && 'open'}`}>
        <div className="card border-0 shadow">
          <div className="filters pt-5">
            <h5>Filter By View Type</h5>

            <ul className="mb-0">
              <RadioGroup aria-labelledby="demo-radio-buttons-group-label" defaultValue="female" name="radio-buttons-group">
                <li>
                  <FormControlLabel value={'public'} control={<Radio onChange={() => filterByViewType('public')} />} label={'Public'} />
                </li>
                <li>
                  <FormControlLabel value={'user'} control={<Radio onChange={() => filterByViewType('user')} />} label={'User'} />
                </li>
              </RadioGroup>
            </ul>
          </div>

          <div className="filters pt-0">
            <h5>Filter By Type</h5>
            <ul>
              <RadioGroup aria-labelledby="demo-radio-buttons-group-label" defaultValue="female" name="radio-buttons-group">
                <li>
                  <FormControlLabel value={'reguler'} control={<Radio onChange={() => filterByType('reguler')} />} label={'Reguler'} />
                </li>
                <li>
                  <FormControlLabel value={'global'} control={<Radio onChange={() => filterByType('global')} />} label={'Global'} />
                </li>
              </RadioGroup>
            </ul>
          </div>

          <div className="d-flex filterWrapper">
            <Button className="btn btn-g w-100" onClick={() => context.openFilters()}>
              <FilterAltOutlinedIcon /> Filter
            </Button>
          </div>
        </div>

        <img style={{ cursor: 'pointer' }} onClick={() => navigate('/products')} src={bannerImg} className="w-100" />
      </div>
    </>
  );
};

export default SidebarPromo;
