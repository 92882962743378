/* eslint-disable react-hooks/exhaustive-deps */
import { Backdrop, CircularProgress, TextField } from '@mui/material';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Button } from '@mui/material';
import Axios from '../../lib/axios';
import { toast } from 'react-toastify';
import { handleErrors } from '../../lib/handleErrors';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import replacePhoneNumber from '../../functions/replacePhoneNumber';

const SignUpEmail = () => {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid }
  } = useForm({ mode: 'onChange' });

  const onSubmit = async (data) => {
    try {
      const modifiedData = { ...data, phone: replacePhoneNumber(data.phone) };
      let response = await Axios().post(`/client/register/email`, modifiedData);
      if (response.data.status) {
        toast.success(response.data.message);
        navigate('/account/email/verify-otp', {
          state: {
            email: data.email
          }
        });
      }
    } catch (error) {
      handleErrors(error);
    }
  };

  return (
    <section className="signIn mb-5">
      <div className="breadcrumbWrapper">
        <div className="container-fluid">
          <ul className="breadcrumb breadcrumb2 mb-0">
            <li>
              <Link to="/">Home</Link>{' '}
            </li>
            <li>Sign Up Email</li>
          </ul>
        </div>
      </div>

      <div className="loginWrapper">
        <div className="card shadow">
          <Backdrop sx={{ color: '#000', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isSubmitting} className="formLoader">
            <CircularProgress color="inherit" />
          </Backdrop>

          <h3>Sign Up Email</h3>
          <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
            <div className="form-group mb-4 w-100">
              <TextField
                autoFocus
                margin="dense"
                name="name"
                id="name"
                label="Name"
                type="text"
                fullWidth
                variant="outlined"
                {...register('name', {
                  required: { value: true, message: 'Name is required' },
                  pattern: {
                    value: /^[A-Za-z\s]+$/,
                    message: 'Please enter a valid name (letters and spaces only)'
                  }
                })}
                error={Boolean(errors.name)}
                helperText={errors.name && errors.name.message}
                required
              />
            </div>
            <div className="form-group mb-4 w-100">
              <TextField
                margin="dense"
                name="username"
                id="username"
                label="Username"
                type="text"
                fullWidth
                variant="outlined"
                {...register('username', {
                  required: { value: true, message: 'Username is required' },
                  pattern: {
                    value: /^[a-z][a-z0-9_]*$/,
                    message: 'Username must start with a letter and can only contain lowercase letters, digits, and underscores'
                  }
                })}
                error={Boolean(errors.username)}
                helperText={errors.username && errors.username.message}
                required
              />
            </div>
            <div className="form-group mb-4 w-100">
              <TextField
                margin="dense"
                name="email"
                id="email"
                label="Email"
                type="email"
                fullWidth
                variant="outlined"
                {...register('email', {
                  required: 'Email is required',
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: 'Invalid email address'
                  }
                })}
                error={Boolean(errors.email)}
                helperText={errors.email && errors.email.message}
                required
              />
            </div>
            <div className="form-group mb-4 w-100">
              <TextField
                margin="dense"
                name="phone"
                id="phone"
                label="Phone Number"
                type="tel"
                fullWidth
                variant="outlined"
                {...register('phone', {
                  required: 'Phone is required',
                  pattern: {
                    value: /^08\d{8,13}$/,
                    message: 'Invalid phone number format. Format Number: 08xxxxx (cth: 08151xxx,085735xxx) With 10-15 digits'
                  }
                })}
                error={Boolean(errors.phone)}
                helperText={errors.phone && errors.phone.message}
                required
              />
              <div className="form-text mb-3 font-italic" style={{ fontStyle: 'italic' }}>
                *Format Number: 08xxxxx (cth: 08151xxx,085735xxx) With 10-15 digits
              </div>
            </div>
            <div className="form-group mb-4 w-100">
              <div className="position-relative">
                <TextField
                  margin="dense"
                  name="password"
                  id="password"
                  label="Password"
                  type={showPassword === false ? 'password' : 'text'}
                  fullWidth
                  variant="outlined"
                  {...register('password', {
                    required: { value: true, message: 'Password is required' }
                  })}
                  error={Boolean(errors.password)}
                  helperText={errors.password && errors.password.message}
                  required
                />
                <Button className="icon mt-2" onClick={() => setShowPassword(!showPassword)}>
                  {showPassword === false ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                </Button>
              </div>
            </div>

            <div className="form-group mt-5 mb-4 w-100">
              <Button className="btn btn-g btn-lg w-100" disabled={!isValid || isSubmitting} type="submit">
                Sign Up
              </Button>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <p className="text-left">
                <Link to="/account/email/signin">Sign In</Link>
              </p>
              <p className="text-right">
                <Link to="/account/email/forgotpassword">Forgot password?</Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default SignUpEmail;
