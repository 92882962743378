/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/alt-text */
import React, { useContext } from 'react';
import Slider from 'react-slick';
import './index.css';

// import Slide1 from '../../../assets/images/slider-1.png';
// import Slide2 from '../../../assets/images/slider-2.png';
// import Button from '@mui/material/Button';

// import Newsletter from '../../../components/newsletter';
import parse from 'html-react-parser';
import { MyContext } from '../../../App';
import Skeleton from 'react-loading-skeleton';

const HomeSlider = ({ banners }) => {
  const context = useContext(MyContext);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    arrows: context.windowWidth > 992 ? true : false,
    autoplay: true
  };

  return (
    <section className="homeSlider">
      <div className="container-fluid position-relative">
        <Slider {...settings} className="home_slider_Main">
          {banners && banners.length !== 0 ? (
            banners.map((item, index) => {
              return (
                <div key={index}>
                  <div className="item">
                    <img
                      src={item.image_url !== null || item.image_url !== '' ? `${item.image_url}` : `images/NO_IMG.png`}
                      className="w-100"
                    />
                    <div className="info">
                      <h2 style={{ display: 'block' }} className="mb-4">
                        {parse(item.title)}
                      </h2>
                      <p>{parse(item.description)}</p>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="imgWrapper">
              <div className="p-4 wrapper mb-3 sm-item-primary">
                <Skeleton height={700} />
              </div>
            </div>
          )}
          {/* <div className="item">
            <img src={Slide1} className="w-100" />
            <div className="info">
              <h2 className="mb-4">
                Don’t miss amazing
                <br />
                grocery deals
              </h2>
              <p>Sign up for the daily newsletter</p>
            </div>
          </div>
          <div className="item">
            <img src={Slide2} className="w-100" />
            <div className="info">
              <h2 className="mb-3">
                Fresh Vegetables
                <br />
                Big discount
              </h2>
              <p>Sign up for the daily newsletter</p>
            </div>
          </div> */}
        </Slider>

        {/* {
            context.windowWidth>992 && <Newsletter/>
        } */}
      </div>
    </section>
  );
};

export default HomeSlider;
