/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import SearchIcon from '@mui/icons-material/Search';
import React, { useEffect, useRef, useState } from 'react';
import IconCart from '../../assets/images/icon-cart.svg';
import IconHeart from '../../assets/images/icon-heart.svg';
import IconUser from '../../assets/images/icon-user.svg';
import Logo from '../../assets/images/logo.png';
import '../header/header.css';
import Select from '../selectDrop/select';

import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import Button from '@mui/material/Button';

import { ClickAwayListener } from '@mui/base/ClickAwayListener';

import { useContext } from 'react';
import { Link } from 'react-router-dom';
import Nav from './nav/nav';

import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import { MyContext } from '../../App';

import { BookOutlined, ListOutlined, NotificationsOutlined, SupportAgent } from '@mui/icons-material';
// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useAuth } from '../../hooks/useAuth';
import Search from '../Search';

const Header = (props) => {
  const { user, isLoading, logout } = useAuth({
    middleware: 'guest'
  });
  const [isOpenDropDown, setisOpenDropDown] = useState(false);
  const [isOpenAccDropDown, setisOpenAccDropDown] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isopenSearch, setOpenSearch] = useState(false);
  const [isOpenNav, setIsOpenNav] = useState(false);

  const headerRef = useRef();
  const searchInput = useRef();

  const context = useContext(MyContext);
  const navigate = useNavigate();

  useEffect(() => {}, [context.cartItems]);

  const [categories, setcategories] = useState([
    'Milks and Dairies',
    'Wines & Drinks',
    'Clothing & beauty',
    'Fresh Seafood',
    'Pet Foods & Toy',
    'Fast food',
    'Baking material',
    'Vegetables',
    'Fresh Fruit',
    'Bread and Juice',
    'Milks and Dairies',
    'Wines & Drinks',
    'Clothing & beauty',
    'Fresh Seafood'
  ]);

  // const countryList = [];

  // useEffect(() => {
  //   getCountry('https://countriesnow.space/api/v0.1/countries/');
  // }, []);

  // const getCountry = async (url) => {
  //   try {
  //     await axios.get(url).then((res) => {
  //       if (res !== null) {
  //         res.data.data.map((item, index) => {
  //           countryList.push(item.country);
  //         });
  //       }
  //     });
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      let position = window.pageYOffset;
      if (position > 100) {
        headerRef.current.classList.add('fixed');
      } else {
        headerRef.current.classList.remove('fixed');
      }
    });
  }, []);

  // const openSearch = () => {
  //   setOpenSearch(true);
  //   searchInput.current.focus();
  // };

  // const closeSearch = () => {
  //   setOpenSearch(false);
  //   searchInput.current.blur();
  //   searchInput.current.value = '';
  // };

  const openNav = () => {
    setIsOpenNav(true);
    context.setIsopenNavigation(true);
  };

  const closeNav = () => {
    setIsOpenNav(false);
    setisOpenAccDropDown(false);
    context.setIsopenNavigation(false);
  };

  return (
    <>
      <div className="headerWrapper" ref={headerRef}>
        <header>
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-2 part1 d-flex align-items-center">
                <Link to="/">
                  <img src={Logo} className="logo" />
                </Link>
                {windowWidth < 992 && (
                  <div className="ml-auto d-flex align-items-center">
                    {/* Search Part */}
                    {/* <div className="navbarToggle mr-0" onClick={openSearch}>
                      <SearchIcon />
                    </div> */}
                    <ul className="list list-inline mb-0 headerTabs pl-0 mr-4">
                      <li className="list-inline-item">
                        <span>
                          <Link to={'/account/wishlist'}>
                            <img src={IconHeart} />
                            {/* Wishlist */}
                          </Link>
                        </span>
                      </li>
                      <li className="list-inline-item">
                        <span>
                          <Link to={'/cart'}>
                            <img src={IconCart} />
                            {props.totalCount !== 0 && (
                              <span hidden={!props.totalCount || props.totalCount == 0} className="badge bg-success rounded-circle">
                                {props.totalCount}
                              </span>
                            )}
                          </Link>
                        </span>
                      </li>
                    </ul>
                    <div className={`navbarToggle mr-2`} onClick={openNav}>
                      <MenuIcon />
                    </div>
                    {!isLoading && user && (
                      <div className="myAccDrop" onClick={() => setisOpenAccDropDown(!isOpenAccDropDown)}>
                        <PersonOutlineOutlinedIcon />
                      </div>
                    )}
                  </div>
                )}
              </div>

              {/*headerSearch start here */}
              <div
                className={windowWidth < 992 ? `col-sm-5 part2 d-flex align-items-center mt-4` : `col-sm-5 part2 d-flex align-items-center`}
              >
                <Search />
                {/* Search Part */}
                {/* <div className={`headerSearch d-flex align-items-center ${isopenSearch === true ? 'open' : ''}`}> */}
                {/* {
                      windowWidth < 992 &&
                      <div className='countryWrapper mb-4 w-100'>
                          <Select data={countryList} placeholder={'Your Location'} icon={<LocationOnOutlinedIcon style={{ opacity: '0.5' }} />} />
                      </div>
                  } */}
                {/* {windowWidth < 992 && (
                    <div className="closeSearch" onClick={closeSearch}>
                      <ArrowBackIosIcon />
                    </div>
                  )}
                  
                  <Select data={categories} placeholder={'All Categories'} icon={false} />

                  <div className="search">
                    <input type="text" placeholder="Search for items..." ref={searchInput} />
                    <SearchIcon className="searchIcon cursor" />
                  </div> */}
                {/* </div> */}
              </div>
              {/*headerSearch start here */}

              <div className="col-sm-5 d-flex align-items-center part3 res-hide">
                <div className="ml-auto d-flex align-items-center">
                  {/* <div className='countryWrapper'>
                                        <Select data={countryList} placeholder={'Your Location'} icon={<LocationOnOutlinedIcon style={{ opacity: '0.5' }} />} />
                                    </div> */}
                  <ClickAwayListener onClickAway={() => setisOpenDropDown(false)}>
                    <ul className="list list-inline mb-0 headerTabs">
                      <li className="list-inline-item">
                        <span>
                          <Link to={'/account/wishlist'}>
                            <img src={IconHeart} />
                            {/* <span className="badge bg-success rounded-circle">3</span> */}
                            Wishlist
                          </Link>
                        </span>
                      </li>
                      <li className="list-inline-item">
                        <span>
                          <Link to={'/cart'}>
                            <img src={IconCart} />
                            {props.totalCount !== 0 && (
                              <span hidden={!props.totalCount || props.totalCount == 0} className="badge bg-success rounded-circle">
                                {props.totalCount}
                              </span>
                            )}
                            Cart
                          </Link>
                        </span>
                      </li>

                      {!isLoading && user ? (
                        <li className="list-inline-item">
                          <span onClick={() => setisOpenDropDown(!isOpenDropDown)}>
                            <img src={IconUser} />
                            Account
                          </span>

                          {isOpenDropDown !== false && (
                            <ul className="dropdownMenu">
                              <li>
                                <Button className="align-items-center" onClick={() => navigate('/account/profile')}>
                                  <Person2OutlinedIcon /> My Account
                                </Button>
                              </li>
                              <li>
                                <Button onClick={() => navigate('/account/list-address')}>
                                  <LocationOnOutlinedIcon /> My Address
                                </Button>
                              </li>
                              <li>
                                <Button onClick={() => navigate('/account/transaction')}>
                                  <ListOutlined /> My Transaction
                                </Button>
                              </li>
                              <li>
                                <Button onClick={() => navigate('/account/wishlist')}>
                                  <FavoriteBorderOutlinedIcon /> My Wishlist
                                </Button>
                              </li>
                              <li>
                                <Button onClick={() => navigate('/account/notification')}>
                                  <NotificationsOutlined /> Notification
                                </Button>
                              </li>
                              <li>
                                <Button onClick={() => (window.location.href = 'https://client.simasco.live/support')}>
                                  <SupportAgent /> Support
                                </Button>
                              </li>
                              <li>
                                <Button onClick={logout}>
                                  <LogoutOutlinedIcon /> Sign out
                                </Button>
                              </li>
                            </ul>
                          )}
                        </li>
                      ) : (
                        <li className="list-inline-item">
                          <Link to={'/account/email/signin'}>
                            <Button className="btn btn-g">Sign In</Button>
                          </Link>
                        </li>
                      )}
                    </ul>
                  </ClickAwayListener>
                </div>
              </div>
            </div>
          </div>
        </header>

        <Nav data={props.data} openNav={isOpenNav} closeNav={closeNav} />
      </div>

      <div className="afterHeader"></div>

      {isOpenAccDropDown !== false && (
        <>
          <div className="navbarOverlay" onClick={closeNav}></div>
          <ul className="dropdownMenu dropdownMenuAcc" onClick={closeNav}>
            <li>
              <Button className="align-items-center">
                <Link to="/account/profile">
                  <Person2OutlinedIcon /> My Account
                </Link>
              </Button>
            </li>
            <li>
              <Button className="align-items-center">
                <Link to="/cart">
                  <img src={IconCart} />
                  Cart
                </Link>
              </Button>
            </li>
            <li>
              <Button onClick={() => navigate('/account/list-address')}>
                <Link>
                  <LocationOnOutlinedIcon /> My Address
                </Link>
              </Button>
            </li>
            <li>
              <Button onClick={() => navigate('/account/transaction')}>
                <Link>
                  <ListOutlined /> My Transaction
                </Link>
              </Button>
            </li>
            <li>
              <Button>
                <Link to="/account/wishlist">
                  <FavoriteBorderOutlinedIcon /> My Wishlist
                </Link>
              </Button>
            </li>
            <li>
              <Button>
                <Link to="/account/notification">
                  <NotificationsOutlined /> Notification
                </Link>
              </Button>
            </li>
            <li>
              <Button>
                <Link to="https://client.simasco.live/support">
                  <SupportAgent /> Support
                </Link>
              </Button>
            </li>
            <li>
              <Button onClick={logout}>
                <Link>
                  <LogoutOutlinedIcon /> Sign out
                </Link>
              </Button>
            </li>
          </ul>
        </>
      )}
    </>
  );
};

export default Header;
