import { Backdrop, CircularProgress, TextField } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Button } from 'react-bootstrap';
import Axios from '../../lib/axios';
import { toast } from 'react-toastify';
import { handleErrors } from '../../lib/handleErrors';

const ForgotPasswordEmail = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid }
  } = useForm();

  const onSubmit = async (data) => {
    try {
      let response = await Axios().post(`/client/forgot-password`, data);
      if (response.data.status) {
        toast.success(response.data.message);
      }
    } catch (error) {
      handleErrors(error);
    }
  };

  return (
    <section className="signIn mb-5">
      <div className="breadcrumbWrapper">
        <div className="container-fluid">
          <ul className="breadcrumb breadcrumb2 mb-0">
            <li>
              <Link to="/">Home</Link>{' '}
            </li>
            <li>Forgot Password</li>
          </ul>
        </div>
      </div>

      <div className="loginWrapper">
        <div className="card shadow">
          <Backdrop sx={{ color: '#000', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isSubmitting} className="formLoader">
            <CircularProgress color="inherit" />
          </Backdrop>

          <h3>Forgot Password</h3>
          <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
            <div className="form-group w-100">
              <TextField
                margin="dense"
                id="email"
                label="Email"
                type="email"
                fullWidth
                variant="outlined"
                {...register('email', {
                  required: 'Email is required',
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: 'Invalid email address'
                  }
                })}
                error={Boolean(errors.email)}
                helperText={errors.email && errors.email.message}
              />
            </div>

            <div className="form-group  mb-4 w-100">
              <Button type="submit" disabled={!isValid || isSubmitting} style={{ border: 'none' }} className="btn btn-g btn-lg w-100">
                Submit
              </Button>
            </div>

            <div className="d-flex justify-content-between">
              <Link className="float-start" to="/account/email/signup" title="Sign Up">
                Create your account
              </Link>
              <Link className="float-end" to="/account/email/signin" title="Sign In">
                Sign In
              </Link>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ForgotPasswordEmail;
