/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Row, Container, Col, Button } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState, Fragment } from 'react';
import Axios from '../../lib/axios';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { loadingUpdate } from '../../redux/loadingSlice';
import numeral from 'numeral';
import { handleErrors } from '../../lib/handleErrors';
import { ReactComponent as IconHeartFill } from 'bootstrap-icons/icons/heart-fill.svg';
import RatingModal from '../../components/RatingModal';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

const getTransactionStatusLabel = (transaction) => {
  if (!transaction) {
    return null;
  }

  const { status } = transaction;

  if (['WAITING_PAYMENT', 'ON_PROCESSING_PAYMENT', 'WAITING_CONFIRMATION', 'ON_PROCESS', 'ON_HOLD'].includes(status)) {
    return <div className="labelPending">{status.toLowerCase().replace(/_/g, ' ')}</div>;
  } else if (['ON_DELIVERY'].includes(status)) {
    return <div className="labelDelivery">{status.toLowerCase().replace(/_/g, ' ')}</div>;
  } else if (['REJECT', 'PAYMENT_FAILED', 'CANCELLED'].includes(status)) {
    return <div className="labelFailed">{status.toLowerCase().replace(/_/g, ' ')}</div>;
  } else if (['COMPLETE'].includes(status)) {
    return <div className="labelApprove">{status.toLowerCase().replace(/_/g, ' ')}</div>;
  } else {
    return null;
  }
};

function renderPaymentDetails(data, payment) {
  if (data && data.sales_order.status != 'WAITING_PAYMENT' && payment != null) {
    return (
      <Fragment>
        {payment && (
          <Row>
            <Col>
              <div className="d-flex justify-content-between">
                <p>Metode Pembayaran</p>
                <p>
                  {payment && payment.payment_method ? payment.payment_method.replace(/_/g, ' ').toUpperCase() : null}
                  {payment && payment.payment_channel ? <span>&ensp;-&ensp;{payment.payment_channel.toUpperCase()}</span> : null}
                </p>
              </div>
            </Col>
          </Row>
        )}
        {data && ['ON_PROCESSING_PAYMENT'].includes(data.sales_order.status) ? (
          <Fragment>
            {
              <Row>
                {payment && payment.payment_destination ? (
                  <Col>
                    <div className="d-flex justify-content-between">
                      <p>Virtual Account</p>
                      <p>{payment.payment_destination}</p>
                    </div>
                  </Col>
                ) : null}
              </Row>
            }
            {
              <Row>
                {payment && payment.bank_code ? (
                  <Col>
                    <div className="d-flex justify-content-between">
                      <p>Bank Code</p>
                      <p>{payment.bank_code.toUpperCase()}</p>
                    </div>
                  </Col>
                ) : null}
              </Row>
            }
          </Fragment>
        ) : null}
      </Fragment>
    );
  } else {
    return null;
  }
}

export default function TransactionDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [numberPrice, setnumberPrice] = useState(0);
  const [counter, setCounter] = useState(0);
  const [transaction, setTransaction] = useState();
  const [salesOrder, setSalesOrder] = useState();
  const [payment, setPayment] = useState();
  const [showRatingModal, setShowRatingModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const handleShowRatingModal = (value) => {
    setSelectedProduct(value);
    setShowRatingModal(true);
  };

  const handleCloseRatingModal = () => {
    setShowRatingModal(false);
  };

  const handleRatingSubmit = async (formData) => {
    const payload = {
      id,
      ...formData,
      ...selectedProduct
    };
    try {
      dispatch(loadingUpdate(true));
      let response = await Axios().post(`/api/client/review/store`, {
        sales_order_id: payload.id,
        product_id: payload.productId,
        user_id: user.id,
        content: payload.content,
        rating: payload.rating
      });
      toast.success(response.data.message);
      if (response && response.data.status == true) {
        handleCloseRatingModal();
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }
    } catch (error) {
      handleErrors(error);
    } finally {
      dispatch(loadingUpdate(false));
    }
  };

  const showTransactionId = (sales) => {
    if (sales) {
      return 'ORDER/' + sales.id.substring(0, sales.id.indexOf('-'));
    }
  };

  const onPayment = async (id) => {
    dispatch(loadingUpdate(true));
    try {
      const response = await Axios().get(`api/client/checkout/detail/${id}`);
      if (response.data.status) {
        window.location.href = response.data.data.invoice_url;
      }
    } catch (error) {
      handleErrors(error);
    } finally {
      dispatch(loadingUpdate(false));
    }
  };

  useEffect(() => {
    try {
      dispatch(loadingUpdate(true));
      const fetchData = async () => {
        const res = await Axios().get(`/api/client/transaction/history/detail/${id}`);
        setData(res.data.data);
        let price = 0;
        let total = 0;
        res.data.data &&
          res.data.data.products.map((value) => {
            total += value.stock;
            price += value.stock * value.price;
          });
        setCounter(total);
        setnumberPrice(price);
        const response = await Axios().get(`/api/client/transaction/history/detail/id/${id}`);
        setTransaction(response.data.data);
        setSalesOrder(response.data.data.sales_order);
        if (response && response.data.data.payment) {
          const payment = response.data.data.payment;
          setPayment(payment);
        }
        dispatch(loadingUpdate(false));
      };
      fetchData();
    } catch (e) {
      const error_message = e.response && e.response.data ? e.response.data.message : 'Unknown error';
      handleErrors(error_message);
    }
  }, [id]);

  return (
    <section className="transactionDetailSection">
      <div className="breadcrumbWrapper">
        <div className="container-fluid">
          <ul className="breadcrumb breadcrumb2 mb-0">
            <li>
              <Link to="/">Home</Link>{' '}
            </li>
            <li>Transaction Detail</li>
          </ul>
        </div>
      </div>
      <div className="container card my-5">
        <h1 className="mt-5">Detail Pemesanan</h1>
        <hr />
        <Row>
          <Col>
            <Container>
              <div className="d-flex justify-content-between align-items-center mt-4">
                {getTransactionStatusLabel(salesOrder)}
                {['ON_DELIVERY', 'COMPLETE'].includes(salesOrder && salesOrder.status) ? (
                  <button
                    className="detailLacak"
                    style={{ border: 'none', backgroundColor: 'transparent', cursor: 'pointer' }}
                    onClick={() => navigate(`/transaction/delivery/${id}`)}
                  >
                    Lacak Pengiriman
                  </button>
                ) : null}
              </div>
            </Container>
            <div className="transactionLine" />
            <Container className="my-4">
              <div className="d-flex justify-content-between">
                <p className="idTransaction">Nomor Transaksi :</p>
                <p className="idTransaction">{showTransactionId(salesOrder)}</p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="describeTransaction">Tanggal Pembayaran</p>
                <p className="describeTransaction">
                  {transaction ? moment(transaction.sales_order.created_at).format('D MMMM YYYY') : null}
                </p>
              </div>
            </Container>
            <div className="transactionLine" />
            <Container>
              <p className="titleTransaction" style={{ fontSize: '16px' }}>
                Produk Detail
              </p>
              {data
                ? data.products.map((value, index) => {
                    return (
                      <div key={index} className="d-flex justify-content-between  text-start">
                        <div
                          className="d-flex justify-content-between my-3 text-start"
                          onClick={() => navigate(`/product/detail/${value.productId}`)}
                          style={{ cursor: 'pointer' }}
                        >
                          <img
                            src={`${process.env.REACT_APP_BACKEND_URL}/storage/images/product/${value.imageName}`}
                            alt=""
                            width={71}
                            height={68}
                            style={{ marginRight: '16px' }}
                          />
                          <Col>
                            <div className="transactionTitle">{value.productName}</div>
                            <div className="transactionItem">{value.stock} Barang</div>
                            <div className="transactionPrice">Rp. {numeral(value.price).format('0,0')}</div>
                          </Col>
                        </div>
                        {data && ['COMPLETE'].includes(data.sales_order.status) && value.isReviewed == false ? (
                          <div className="align-self-center">
                            <Button size="small" disableelevation={true} onClick={() => handleShowRatingModal(value)}>
                              <IconHeartFill className="me-1" /> Give Rating
                            </Button>
                          </div>
                        ) : null}
                      </div>
                    );
                  })
                : null}
            </Container>
            <div className="transactionLine" />
            <Container className="rincianPembayaran">
              <h3 className="fs-5 my-4 fw-bold">Detail Pembayaran</h3>
            </Container>
            <div className="transactionLine" />
            {renderPaymentDetails(data, payment)}
            <Container className="rincianDeskripsi">
              <div className="d-flex justify-content-between">
                <p>Total Harga ({counter}) Barang</p>
                <p>Rp. {numeral(numberPrice).format('0,0')}</p>
              </div>
              {data && data.sales_order.delivery_price ? (
                <div className="d-flex justify-content-between">
                  <p>Total Ongkos Kirim {data.sales_delivery && data.sales_delivery.company}</p>
                  <p>Rp. {numeral(data ? data.sales_order.delivery_price : 0).format('0,0')}</p>
                </div>
              ) : null}
              {data && data.sales_order.service_fee ? (
                <div className="d-flex justify-content-between">
                  <p>Biaya Service</p>
                  <p>Rp. {numeral(data.sales_order.service_fee).format('0,0')}</p>
                </div>
              ) : null}
              {data && data.sales_order.admin_fee ? (
                <div className="d-flex justify-content-between">
                  <p>Biaya Admin</p>
                  <p>Rp. {numeral(data.sales_order.admin_fee).format('0,0')}</p>
                </div>
              ) : null}
            </Container>
            {data && data.sales_order.promo_price ? (
              <Fragment>
                <div className="transactionLine" />
                <Container className="rincianDeskripsi">
                  <div className="d-flex justify-content-between">
                    <p>Promo</p>
                    <p>Rp. {numeral(data.sales_order.promo_price).format('0,0')}</p>
                  </div>
                </Container>
              </Fragment>
            ) : null}
            <div className="transactionLine" />
            <Container className="rincianPembayaran mb-5 pb-5">
              <div className="d-flex justify-content-between">
                <h3>Total Belanja</h3>
                <h3>Rp. {numeral(data ? data.sales_order.grand_total : 0).format('0,0')}</h3>
              </div>
            </Container>
          </Col>
          <Col>
            <Container className="infoPengiriman infoDeliveryCard px-3 py-4">
              <h3 className="fs-5 my-4 fw-bold">Informasi Pengiriman</h3>
              {data && data.sales_delivery ? (
                <Fragment>
                  <Row>
                    <Col>
                      <p>Kurir</p>
                    </Col>
                    <Col>
                      <p>{data.sales_delivery.company}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p>No Resi</p>
                    </Col>
                    <Col>
                      <p>{data.sales_delivery.waybill_id}</p>
                    </Col>
                  </Row>
                </Fragment>
              ) : null}
              <Row>
                <Col>
                  <p>Nama Pelanggan</p>
                </Col>
                <Col>
                  <p>{data && data.customer.name}</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>Email</p>
                </Col>
                <Col>
                  <p>{data && data.customer.user.email}</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>Nomor Telepon</p>
                </Col>
                <Col>
                  <p>{data && data.customer.user.phone}</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>Alamat</p>
                </Col>
                <Col>
                  <p>{data && data.address.address}</p>
                  <p>{data && data.address.description}</p>
                  <p>{data && data.address.full_path}</p>
                </Col>
              </Row>
              {data && ['REFUNDED'].includes(data.sales_order.status) && (
                <>
                  <Row>
                    <Col>
                      <p>Bukti Pengiriman</p>
                    </Col>
                  </Row>
                  <Row>
                    <img
                      src={
                        data?.sales_order?.proof_image
                          ? `${process.env.REACT_APP_IMAGEKIT_ENDPOINT_URL}/${data?.sales_order?.proof_image}`
                          : `${process.env.REACT_APP_BACKEND_URL}/storage/images/no_img.png`
                      }
                      className="mt-3 w-100"
                      alt="Proof Refund Image"
                    />
                  </Row>
                </>
              )}
            </Container>
            <div className="pb-4" />
            {data && ['WAITING_PAYMENT', 'ON_PROCESSING_PAYMENT'].includes(data.sales_order.status) ? (
              <button
                onClick={(e) => {
                  e.target.disabled = true;
                  onPayment(data.sales_order.id);
                  e.target.disabled = false;
                }}
                className="PaymentButton p-3"
                style={{ width: 'auto', fontSize: '24px' }}
              >
                Lanjutkan Pembayaran
              </button>
            ) : null}
          </Col>
        </Row>
      </div>
      <RatingModal show={showRatingModal} handleClose={handleCloseRatingModal} handleSubmit={handleRatingSubmit} />
    </section>
  );
}
