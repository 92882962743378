/* eslint-disable react/prop-types */
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { withRouter } from '../../lib/withRouter';

import numeral from 'numeral';
import { Card, Col, Row } from 'react-bootstrap';
import Axios from '../../lib/axios';
import { handleErrors } from '../../lib/handleErrors';
import { loadingUpdate } from '../../redux/loadingSlice';
import { Link } from 'react-router-dom';
import { setAddress } from '../../redux/addressSlice';

class CheckoutView extends Component {
  constructor() {
    super();
    this.state = {
      formData: {},
      isSubmitting: false,
      customerAddress: null,
      companyCart: null,
      couriers: null,
      selectedCourier: null,
      promo: null,
      courierPrice: 0,
      promoPrice: 0,
      active: false,
      additionalFees: [],
      adminFee: 0,
      serviceFee: 0,
      totalAdditionalFees: 0,
      validation: ''
    };
  }

  componentDidMount() {
    this.initializeData();
  }

  async initializeData() {
    this.props.loadingUpdate({ isNotCircular: false, loading: true });
    try {
      const responseDefaultCustomerAddress = await Axios().get('/api/client/profile/default/address');
      const stateCompanyCart = this.props.router.location.state.companyCart;
      this.setState({
        customerAddress: this.props.address !== null ? this.props.address : responseDefaultCustomerAddress.data.data,
        companyCart: stateCompanyCart
      });
      const responseAdditionalFees = await Axios().get('/api/client/additional/fee/index');
      let totalAddFees = 0;
      responseAdditionalFees &&
        responseAdditionalFees?.data?.data?.forEach((value) => {
          const calculate = Math.ceil(value?.type === 'percent' ? (value?.value / 100) * stateCompanyCart.grandTotal : value?.value);
          totalAddFees += calculate;
        });
      this.setState({
        totalAdditionalFees: Math.ceil(totalAddFees)
      });
      this.setState({
        additionalFees: responseAdditionalFees.data.data
      });
      const fees = await Axios().get(`/api/client/cost/detail/${stateCompanyCart.companyId}`);
      this.setState({
        adminFee: fees?.data?.data?.admin_fee ?? 0,
        serviceFee: fees?.data?.data?.service_fee ?? 0
      });
      const company = await Axios().get(`/api/client/company/profile/default/address/${stateCompanyCart.companyId}`);
      const client = await Axios().get('/api/client/profile/default/address');
      if (company.data.data == null || client.data.data == null) {
        let error_message = 'Pastikan alamat sudah menjadi alamat default';
        toast.error(error_message);
      } else {
        const response = await Axios().post(`/api/client/biteship/rates/coordinate`, {
          origin_latitude: company.data.data.origin_latitude,
          origin_longitude: company.data.data.origin_longitude,
          destination_latitude: this.props.address ? this.props.address.destination_latitude : client.data.data.destination_latitude,
          destination_longitude: this.props.address ? this.props.address.destination_longitude : client.data.data.destination_longitude,
          items: stateCompanyCart.cartItems
        });
        this.setState({
          couriers: response.data.data
        });
      }
    } catch (e) {
      if (e.response && e.response.data.code) {
        let error_message = 'No courier available for requested location currently';
        toast.error(error_message);
      } else {
        handleErrors(e);
      }
    } finally {
      this.props.loadingUpdate(false);
    }
  }

  handleCourierClick = (service, serviceIndex) => {
    this.setState({
      selectedCourier: { service, serviceIndex },
      courierPrice: service.price
    });
  };

  checkPromoPrice = (promo, price) => {
    let result = (price * promo.rate) / 100;
    if (promo.rate == null) {
      return promo.price;
    } else {
      if (result > promo.max) {
        return promo.max;
      } else if (price >= promo.min && result <= promo.max) {
        return result;
      }
    }
  };

  handleSubmit = async (event) => {
    try {
      if (!this.state.formData?.coupon) {
        return;
      }
      event.target.disabled = true;
      this.setState({ isSubmitting: true });
      const res = await Axios().post(`/api/client/promo/status`, {
        company_id: this.state.companyCart && this.state.companyCart.companyId,
        promo_code: this.state.formData?.coupon.toUpperCase()
      });
      if (res.status == 200 && res.data.data && Object.keys(res.data.data).length != 0) {
        let totalPrice = this.state.companyCart && this.state.companyCart.grandTotal;
        let value = this.checkPromoPrice(res.data.data, totalPrice);
        if (value >= totalPrice) {
          const error_message = 'Promo tidak bisa terpakai untuk transaksi ini!';
          handleErrors(error_message);
        } else {
          this.setState({
            promo: res.data.data,
            promoPrice: value,
            active: true,
            validation: 'Promo Berhasil Terpasang!'
          });
          this.props.loadingUpdate(false);
        }
      }
    } catch (error) {
      if (!error?.response?.data?.code) {
        const error_message = error?.response?.data?.errors ?? 'Terjadi Kesalahan, Silahkan Coba Kembali Nanti!';
        handleErrors(error_message);
      } else {
        const error_message = error.response.data.errors[0].message;
        toast.error(error_message);
      }
    } finally {
      event.target.disabled = false;
      this.setState({ isSubmitting: false });
    }
  };

  handleChangePromoCode = (event) => {
    const { value } = event.target;
    this.setState(() => ({
      formData: {
        coupon: value
      }
    }));
  };

  handleReset = () => {
    this.setState({
      promo: null,
      promoPrice: 0,
      active: false,
      validation: '',
      formData: {
        coupon: ''
      }
    });
  };

  onPayment = async () => {
    this.props.loadingUpdate({ isNotCircular: false, loading: true });
    try {
      const ids = [];
      for (let i = 0; i < this.state.companyCart.cartItems.length; i++) {
        ids.push(this.state.companyCart.cartItems[i].cartId);
      }
      if (this.state.selectedCourier) {
        const response = await Axios().post(`/api/client/checkout/store`, {
          company_id: this.state.companyCart.companyId,
          detail: this.state.companyCart,
          courier_code: this.state.selectedCourier.service.courier_code,
          courier_service_code: this.state.selectedCourier.service.courier_service_code,
          promo_id: this.state.promo != undefined ? this.state.promo.id : null,
          type: this.state.promo != undefined ? this.state.promo.type : null
        });
        if (response.data.status) {
          this.props.setAddress(null);
          const resDestroy = await Axios().post('/api/client/cart/destroy', {
            id: ids
          });
          if (resDestroy.data.status) {
            // window.location.href = response.data.data.invoice_url;
            // this.props.router.navigate(response.data.data.invoice_url, { replace: true });
            window.location.replace(response.data.data.invoice_url);
          } else {
            toast.error('Terjadi Kesalahan, Silahkan Coba Kembali Nanti!');
          }
        } else {
          toast.error('Terjadi Kesalahan, Silahkan Coba Kembali Nanti!');
        }
      } else {
        const error_message = 'Mohon memilih kurir terlebih dahulu';
        toast.error(error_message);
      }
    } catch (errors) {
      handleErrors(errors);
    } finally {
      this.props.loadingUpdate(false);
    }
  };

  render() {
    return (
      <React.Fragment>
        <section className="cartSection mb-5 checkoutPage">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <div className="form w-100 my-4 mb-5 pb-5 shadow">
                  <h3>Contact Info</h3>
                  <Row>
                    <div className="col-md-6">
                      <div className="form-group mb-3 mt-4">
                        <TextField
                          id="outlined-basic"
                          label="Enter Full Name"
                          variant="outlined"
                          className="w-100"
                          value={this.state.customerAddress ? this.state.customerAddress.customer.name : ''}
                          disabled={true}
                          name="name"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-3 mt-4">
                        <TextField
                          id="outlined-basic"
                          label="Enter Username"
                          variant="outlined"
                          className="w-100"
                          value={this.state.customerAddress ? this.state.customerAddress.customer.user.email : ''}
                          disabled={true}
                          name="username"
                        />
                      </div>
                    </div>
                  </Row>
                  <Row>
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <TextField
                          id="outlined-basic"
                          label="Enter Email"
                          variant="outlined"
                          className="w-100"
                          value={this.state.customerAddress ? this.state.customerAddress.customer.user.email : ''}
                          disabled={true}
                          name="email"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <TextField
                          id="outlined-basic"
                          label="Enter Phone Number"
                          variant="outlined"
                          className="w-100"
                          multiline
                          value={this.state.customerAddress ? this.state.customerAddress.customer.user.phone : ''}
                          disabled={true}
                          name="phone"
                        />
                      </div>
                    </div>
                  </Row>
                </div>

                <div className="form w-100 my-4 mb-5 pb-5 shadow">
                  <div className="d-flex justify-content-between">
                    <h3>Shipping Infomation</h3>
                    <Link to="/account/list-address">
                      <Button className="btn-g btn-lg">Change Address</Button>
                    </Link>
                  </div>
                  <Row>
                    <div className="col-md-12">
                      <div className="form-group mb-3 mt-4">
                        <TextField
                          id="outlined-basic"
                          label="Enter Full Name"
                          variant="outlined"
                          className="w-100"
                          value={this.state.customerAddress ? this.state.customerAddress.customer.name : ''}
                          disabled={true}
                          name="name"
                        />
                      </div>
                    </div>
                  </Row>
                  <Row>
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <TextField
                          id="outlined-basic"
                          label="Enter Address"
                          variant="outlined"
                          className="w-100"
                          value={this.state.customerAddress ? this.state.customerAddress.destination_area_name : ''}
                          disabled={true}
                          name="address1"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <TextField
                          id="outlined-basic"
                          label="Enter Address 2 (Optional)"
                          variant="outlined"
                          className="w-100"
                          value={this.state.customerAddress ? this.state.customerAddress.address : ''}
                          disabled={true}
                          name="address2"
                        />
                      </div>
                    </div>
                  </Row>
                  <Row>
                    <div className="col-md-12">
                      <div className="form-group">
                        <TextField
                          id="outlined-basic"
                          label="Enter Address Description (Note)"
                          variant="outlined"
                          className="w-100"
                          multiline
                          value={this.state.customerAddress ? this.state.customerAddress.description : ''}
                          disabled={true}
                          name="address_description"
                        />
                      </div>
                    </div>
                  </Row>
                </div>

                <div className="form w-100 my-4 mb-5 pb-5 shadow">
                  <h3 className="mb-5">Courier Selection Information</h3>
                  {this.state.couriers ? (
                    Object.keys(this.state.couriers).map((courierName, index) => (
                      <Card key={index} className="mb-3">
                        <Card.Header>{courierName}</Card.Header>
                        <Card.Body>
                          {this.state.couriers[courierName].map((service, serviceIndex) => {
                            return (
                              <div
                                key={serviceIndex}
                                role="button"
                                tabIndex="0"
                                className={`mb-3 courierBox ${
                                  this.state.selectedCourier &&
                                  this.state.selectedCourier.serviceIndex === serviceIndex &&
                                  this.state.selectedCourier.service.courier_code === service.courier_code &&
                                  this.state.selectedCourier.service.courier_service_code === service.courier_service_code
                                    ? 'active'
                                    : ''
                                }`}
                                onClick={() => this.handleCourierClick(service, serviceIndex)}
                                onKeyPress={() => {}}
                              >
                                <Row className="g-3">
                                  <Col md={4} className="d-flex justify-content-center align-items-center">
                                    <img
                                      src={`/images/couriers/${service.courier_code}.png`}
                                      className="img-fluid"
                                      width={200}
                                      height={100}
                                      alt={`${service.courier_name} - ${service.courier_service_name}`}
                                    />
                                  </Col>
                                  <Col md={8}>
                                    <p className="courierTitle">{service.courier_service_name}</p>
                                    <p>{`Delivery in ${service.duration}`}</p>
                                    <p>{`Rp ${numeral(service.price).format('0,0')}`}</p>
                                  </Col>
                                </Row>
                              </div>
                            );
                          })}
                        </Card.Body>
                      </Card>
                    ))
                  ) : (
                    <p className="text-center">No courier available for requested location currently</p>
                  )}
                </div>
              </div>

              <div className="col-md-4 cartRightBox pt-4">
                <div className="card p-4 mb-5 mt-0">
                  <div className="card-header d-flex justify-content-between">
                    <div>Cart</div>
                    <span className="badge bg-success px-3 text-white d-flex align-items-center  justify-content-center">
                      {this.state.companyCart && this.state.companyCart.cartItems.length}
                    </span>
                  </div>
                  <ul className="list-group list-group-flush">
                    {this.state.companyCart &&
                      this.state.companyCart.cartItems &&
                      this.state.companyCart.cartItems.length > 0 &&
                      this.state.companyCart.cartItems.map((value, index) => {
                        let total = parseInt(value.quantity) * parseInt(value.price);
                        return (
                          <li key={index} className="list-group-item d-flex justify-content-between lh-sm">
                            <div>
                              <h6 className="my-0">
                                {value.productName} - ({value.code})
                              </h6>
                              <small className="text-muted">
                                ({value.quantity} * {value.price})
                              </small>
                            </div>
                            <span className="text-muted">Rp. {numeral(total).format('0,0')}</span>
                          </li>
                        );
                      })}
                    <li className="list-group-item d-flex justify-content-between">
                      <span>Total Rupiah</span>
                      <strong>Rp. {numeral(this.state.companyCart && this.state.companyCart.grandTotal).format('0,0')}</strong>
                    </li>
                  </ul>
                </div>

                <div className="card p-4 my-5">
                  <div className="card-body">
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        this.handleSubmit(e);
                      }}
                      noValidate
                    >
                      <h3>Have Promo?</h3>
                      <TextField
                        id="outlined-basic"
                        label="Coupon code"
                        variant="outlined"
                        className="w-100 my-2"
                        value={this.state.formData.coupon}
                        name="coupon"
                        type="text"
                        required={true}
                        onChange={this.handleChangePromoCode}
                        disabled={this.state.promo != null || this.state.isSubmitting}
                      />
                      {this.state?.promo?.name && <span className="text-success">Promo berhasil Terpasang!</span>}
                      <div className="d-flex align-items-end justify-content-end">
                        {this.state.promo == null ? (
                          <button
                            type="submit"
                            style={{ width: '100px', height: '50px', fontSize: '18px' }}
                            className="btn btn-g mt-3 float-end"
                            disabled={this.state.isSubmitting}
                          >
                            Apply
                          </button>
                        ) : (
                          <button
                            type="button"
                            style={{ width: '100px', height: '50px', fontSize: '18px' }}
                            onClick={() => this.handleReset()}
                            className="btn btn-sm btn-danger mt-3 float-end"
                            disabled={this.state.isSubmitting}
                          >
                            Remove
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                </div>

                <div className="card p-4">
                  <div className="d-flex align-items-center mb-4">
                    <h5 className="mb-0 text-light">Seller:</h5>
                    <h3 className="ml-auto mb-0 font-weight-bold">
                      <span className="text-g">{this.state?.companyCart?.companyName ?? 'Loading...'}</span>
                    </h3>
                  </div>

                  <div className="d-flex align-items-center mb-4">
                    <h5 className="mb-0 text-light">Total items price:</h5>
                    <h3 className="ml-auto mb-0 font-weight-bold">
                      <span>Rp. {numeral(this.state.companyCart && this.state.companyCart.grandTotal).format('0,0')}</span>
                    </h3>
                  </div>

                  <div className="d-flex align-items-center mb-4">
                    <h5 className="mb-0 text-light">Admin Fee:</h5>
                    <h3 className="ml-auto mb-0 font-weight-bold">
                      <span>Rp. {numeral(this.state.adminFee).format('0,0')}</span>
                    </h3>
                  </div>

                  <div className="d-flex align-items-center mb-4">
                    <h5 className="mb-0 text-light">Service Fee:</h5>
                    <h3 className="ml-auto mb-0 font-weight-bold">
                      <span>Rp. {numeral(this.state.serviceFee).format('0,0')}</span>
                    </h3>
                  </div>

                  {this.state.companyCart &&
                    this.state.companyCart.grandTotal &&
                    this.state.additionalFees &&
                    this.state.additionalFees.map((value, index) => {
                      const calculate = Math.ceil(
                        value?.type === 'percent' ? (value?.value / 100) * this.state.companyCart.grandTotal : value?.value
                      );
                      return (
                        <div key={index} className="d-flex align-items-center mb-4">
                          <h5 className="mb-0 text-light">{value?.name}</h5>
                          <h3 className="ml-auto mb-0 font-weight-bold">
                            <span>Rp. {numeral(calculate).format('0,0')}</span>
                          </h3>
                        </div>
                      );
                    })}

                  <div className="d-flex align-items-center mb-4">
                    <h5 className="mb-0 text-light">Biaya Pengiriman:</h5>
                    <h3 className="ml-auto mb-0 font-weight-bold">
                      <span>Rp. {numeral(this.state.courierPrice).format('0,0')}</span>
                    </h3>
                  </div>

                  {this.state.promo && this.state.promo.name && (
                    <>
                      <div className="d-flex align-items-center mb-4">
                        <h5 className="mb-0 text-success">Penggunaan Promo:</h5>
                        <h3 className="ml-auto mb-0 font-weight-bold text-success">
                          <span>-Rp. {numeral(Math.ceil(this.state.promoPrice)).format('0,0')}</span>
                        </h3>
                      </div>
                    </>
                  )}

                  <div className="d-flex align-items-center mb-4">
                    <h5 className="mb-0 text-light">Grand Total:</h5>
                    <h3 className="ml-auto mb-0 font-weight-bold">
                      <span className="text-g">
                        Rp.
                        {numeral(
                          this.state.companyCart &&
                            this.state.companyCart.grandTotal +
                              this.state.courierPrice +
                              Math.ceil(this.state.totalAdditionalFees) +
                              this.state.adminFee +
                              this.state.serviceFee -
                              Math.ceil(this.state.promoPrice)
                        ).format('0,0')}
                      </span>
                    </h3>
                  </div>
                  <p className="text-center">
                    <img src="../../images/payment/payments.webp" alt="..." height={26} />
                  </p>
                  <br />
                  <Button className="btn-g btn-lg" onClick={this.onPayment}>
                    Pay Now
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.loading.loading,
  address: state.address,
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  loadingUpdate: (isLoading) => dispatch(loadingUpdate(isLoading)),
  setAddress: (address) => dispatch(setAddress(address))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CheckoutView));
