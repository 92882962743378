import { Alert, Checkbox, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Axios from '../../lib/axios';
import { handleErrors } from '../../lib/handleErrors';
import LoadingSpinner from '../../components/LoadingSpinner';
import { useDispatch } from 'react-redux';
import { loadingUpdate } from '../../redux/loadingSlice';

const Contact = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting }
  } = useForm({ mode: 'onChange' });
  const dispatch = useDispatch();
  const [isCheck, setIsCheck] = useState(false);
  const [loadCircle, setLoadCircle] = useState(false);

  const onSubmit = async (data) => {
    try {
      setLoadCircle(true);
      await Axios().post('/api/client/contact', data);
      toast.success('Message sent successfully');
      reset();
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoadCircle(false);
    }
  };

  useEffect(() => {
    dispatch(loadingUpdate(false));
  });

  return loadCircle == true ? (
    <LoadingSpinner isLoading={loadCircle} />
  ) : (
    <section className="signIn mb-5">
      <div className="breadcrumbWrapper">
        <div className="container-fluid">
          <ul className="breadcrumb breadcrumb2 mb-0">
            <li>
              <Link to="/">Home</Link>{' '}
            </li>
            <li>Contact</li>
          </ul>
        </div>
      </div>

      <section id="contact">
        <div className="container-fluid my-5">
          <div className="row">
            <div className="col-xl-10 col-lg-12 m-auto">
              <section className="row align-items-end mb-50">
                <div className="col-lg-4 mb-lg-0 mb-md-5 mb-sm-5">
                  <h4 className="mb-20 text-brand font-weight-bold" style={{ fontSize: '36px' }}>
                    How can we help you?
                  </h4>
                  <h1 className="mb-30 font-weight-bold mb-5" style={{ fontSize: '64px' }}>
                    Let us know how
                    <br />
                    we can assist you
                  </h1>
                  <p className="mb-20">Please fill out the form below to get in touch with us.</p>
                </div>
                <div className="col-lg-8">
                  <div className="row">
                    <div className="col-lg-6 mb-4">
                      <h5 className="mb-20 font-weight-bold" style={{ fontSize: '36px' }}>
                        01. Visit Feedback
                      </h5>
                      <p>Provide feedback about your recent visit.</p>
                    </div>
                    <div className="col-lg-6 mb-4">
                      <h5 className="mb-20 font-weight-bold" style={{ fontSize: '36px' }}>
                        02. Complaint Services
                      </h5>
                      <p>Contact us with any complaints or issues you have encountered.</p>
                    </div>
                    <div className="col-lg-6 mb-lg-0 mb-4">
                      <h5 className="mb-20 font-weight-bold text-brand" style={{ fontSize: '36px' }}>
                        03. Billing Inquiries
                      </h5>
                      <p>For questions regarding billing and payments.</p>
                    </div>
                    <div className="col-lg-6">
                      <h5 className="mb-20 font-weight-bold" style={{ fontSize: '36px' }}>
                        04. General Inquiries
                      </h5>
                      <p>Any other general questions or requests.</p>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <div className="container my-5">
            <h4 className="mb-20 text-brand font-weight-bold text-center" style={{ fontSize: '36px' }}>
              Have another question?
            </h4>
            <p className="text-center">Complete the form below</p>
            <div className="row d-flex align-items-center justify-content-center">
              <div className="col-md-12">
                <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
                  <div className="d-flex">
                    <div className="form-group mb-4 w-100 col-md-6">
                      <TextField
                        id="email"
                        type="email"
                        label="Email"
                        className="w-100"
                        {...register('email', {
                          required: 'Email is required',
                          pattern: {
                            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                            message: 'Invalid email address'
                          }
                        })}
                      />
                      {errors.email && (
                        <Alert severity="error" className="mt-2">
                          {errors.email.message}
                        </Alert>
                      )}
                    </div>
                    <div className="form-group mb-4 w-100 col-md-6">
                      <TextField
                        id="full_name"
                        type="text"
                        label="Full Name"
                        className="w-100"
                        {...register('full_name', {
                          required: { value: true, message: 'Full Name is required' },
                          pattern: {
                            value: /^[A-Za-z\s]+$/,
                            message: 'Please enter a valid full name (letters and spaces only)'
                          }
                        })}
                      />
                      {errors.full_name && (
                        <Alert severity="error" className="mt-2">
                          {errors.full_name.message}
                        </Alert>
                      )}
                    </div>
                  </div>
                  <div className="form-group mb-4 w-100 col-md-12">
                    <TextField
                      id="subject"
                      type="text"
                      label="Subject"
                      className="w-100"
                      {...register('subject', {
                        required: { value: true, message: 'Subject is required' }
                      })}
                    />
                    {errors.subject && (
                      <Alert severity="error" className="mt-2">
                        {errors.subject.message}
                      </Alert>
                    )}
                  </div>
                  <div className="form-group mb-4 w-100 col-md-12">
                    <TextField
                      id="description"
                      type="text"
                      multiline
                      rows={4}
                      label="Description"
                      className="w-100"
                      {...register('description', {
                        required: { value: true, message: 'Description is required' }
                      })}
                    />
                    {errors.description && (
                      <Alert severity="error" className="mt-2">
                        {errors.description.message}
                      </Alert>
                    )}
                  </div>
                  <div className="flex items-center col-md-12">
                    <Checkbox
                      onChange={(e) => setIsCheck(e.target.checked)}
                      checked={isCheck}
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                    />
                    <label htmlFor="checkbox">
                      I have read and agree with{' '}
                      <a href="https://client.simasco.live/support" className="text-violet-900">
                        terms &amp; conditions
                      </a>
                    </label>
                  </div>
                  <div className="text-center d-flex justify-content-center mt-4 mb-5">
                    <Button type="submit" className="btn btn-g btn-lg border-0" disabled={!isCheck || isSubmitting}>
                      Submit
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default Contact;
