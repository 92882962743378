import { TextField } from '@mui/material';
import { ReactComponent as IconPersonSquareFill } from 'bootstrap-icons/icons/person-lines-fill.svg';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
// import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
// import { formValueSelector } from 'redux-form';
import { axiosFormDataConfig } from '../../config/axiosFormDataConfig';
import { useAuth } from '../../hooks/useAuth';
import Axios from '../../lib/axios';
import { handleErrors } from '../../lib/handleErrors';
import ChangePasswordForm from './ChangePasswordForm';
import LoadingSpinner from '../LoadingSpinner';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import moment from 'moment';
import { format } from 'timeago.js';

const ProfileForm = () => {
  const {
    register,
    reset,
    watch,
    handleSubmit,
    formState: { errors, isSubmitting, isValid }
  } = useForm({ mode: 'onChange' });
  const navigate = useNavigate();
  const [imageURLs, setImageURLs] = useState([]);
  const [url, setUrl] = useState('');
  const [loadCircle, setLoadCircle] = useState(false);
  const [lastSeen, setLastSeen] = useState();
  const today = new Date();
  today.setFullYear(today.getFullYear() - 20);
  const minDate = today.toISOString().split('T')[0];

  // const user = useSelector((state) => state.user);
  const { user, isLoading, logout } = useAuth({
    middleware: 'auth'
  });
  // const selector = formValueSelector('profile');
  // const imageValue = useSelector((state) => selector(state, 'image'));

  const onSubmit = async (data) => {
    try {
      setLoadCircle(true);
      let response = await Axios().post(`/api/client/profile/update/${user.id}`, data);
      if (response.data.status) {
        toast.success(response.data.message);
      }
    } catch (error) {
      handleErrors(error);
      setLoadCircle(false);
    } finally {
      setLoadCircle(false);
    }
  };

  const onImageChange = async (obj) => {
    if (obj) {
      try {
        setLoadCircle(true);
        let response = await Axios().post(
          `/api/client/profile/update/${user.id}`,
          {
            image: obj
          },
          axiosFormDataConfig
        );
        if (response.data.status) {
          const val = await getBase64(obj);
          setImageURLs(val);
          toast.success(response.data.message);
        }
        // setTimeout(function () {
        //   location.reload();
        // }, 3000);
      } catch (error) {
        handleErrors(error);
        setLoadCircle(false);
      } finally {
        setLoadCircle(false);
      }
    } else {
      setImageURLs('');
    }
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(file);
      reader.onerror = (error) => reject(error);
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      if (user && user.id) {
        try {
          // dispatch(loadingUpdate(true));
          setLoadCircle(true);
          const response = await Axios().get(`api/client/profile/detail/${user && user.id}`);
          const data = response.data.data;
          reset({
            name: data.name,
            phone: data.user.phone,
            email: data.user.email,
            location: data.location,
            date_of_birth: data.date_of_birth,
            image: data.image
          });
          setLastSeen(data?.last_seen);
          if (data?.image_url) {
            setUrl(data?.image_url ?? '');
          }
          // dispatch(loadingUpdate(false));
        } catch (error) {
          handleErrors(error);
          setLoadCircle(false);
        } finally {
          setLoadCircle(false);
        }
      }
    };

    if (!isLoading && !user) {
      navigate('/account/email/signin');
    } else {
      fetchData();
    }
  }, [isLoading, navigate, reset, user]);

  return loadCircle == true ? (
    <LoadingSpinner isLoading={loadCircle} />
  ) : (
    <div className="row">
      <div className="col-md-6">
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="card">
            <h6 className="card-header">
              <IconPersonSquareFill /> Profile Detail
            </h6>
            <img
              src={
                imageURLs.length != 0
                  ? imageURLs
                  : url !== null || url !== ''
                    ? `${url}`
                    : // ? `${process.env.REACT_APP_BACKEND_URL}/storage/images/profile/${imageValue}`
                      `images/NO_IMG.png`
              }
              width={140}
              height={140}
              alt=""
              className="card-img-top rounded-0 img-fluid bg-secondary"
            />
            <div className="card-body">
              <div className="d-flex flex-column">
                <input type="file" name="formFile" accept="image/*" onChange={(e) => onImageChange(e.target.files[0])} />
                <p className="card-text mt-3">You don&apos;t allow uploading a photo more than 5MB</p>
              </div>
            </div>
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <TextField
                  margin="dense"
                  id="name"
                  label="Full Name"
                  type="text"
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: !!watch('name')
                  }}
                  {...register('name', {
                    required: { value: true, message: 'Name is required' },
                    pattern: {
                      value: /^[A-Za-z\s]+$/,
                      message: 'Please enter a valid name (letters and spaces only)'
                    }
                  })}
                  error={Boolean(errors.name)}
                  helperText={errors.name && errors.name.message}
                />
              </li>
              <li className="list-group-item">
                <TextField
                  margin="dense"
                  id="phone"
                  label="Phone Number"
                  type="tel"
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: !!watch('phone')
                  }}
                  {...register('phone', {
                    required: 'Phone is required',
                    pattern: {
                      value: /^08\d{8,13}$/,
                      message: 'Invalid phone number format. Format Number: 08xxxxx (cth: 08151xxx,085735xxx) With 10-15 digits'
                    }
                  })}
                  error={Boolean(errors.phone)}
                  helperText={errors.phone && errors.phone.message}
                />
                <div className="form-text mb-3 font-italic" style={{ fontStyle: 'italic' }}>
                  *Format Number: 08xxxxx (cth: 08151xxx,085735xxx) With 10-15 digits
                </div>
              </li>
              <li className="list-group-item">
                <TextField
                  margin="dense"
                  id="email"
                  label="Email"
                  type="email"
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: !!watch('email')
                  }}
                  {...register('email', {
                    required: 'Email is required',
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: 'Invalid email address'
                    }
                  })}
                  error={Boolean(errors.email)}
                  helperText={errors.email && errors.email.message}
                />
              </li>
              <li className="list-group-item">
                <TextField
                  margin="dense"
                  id="location"
                  label="Location"
                  type="text"
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: !!watch('location')
                  }}
                  {...register('location', {
                    required: { value: true, message: 'Location is required' }
                  })}
                  error={Boolean(errors.location)}
                  helperText={errors.location && errors.location.message}
                />
              </li>
              <li className="list-group-item">
                <TextField
                  margin="dense"
                  id="date_of_birth"
                  label="Your birthdate"
                  type="date"
                  fullWidth
                  variant="outlined"
                  inputProps={{ max: minDate }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  {...register('date_of_birth', {
                    required: { value: true, message: 'Birth Date is required' }
                  })}
                  error={Boolean(errors.date_of_birth)}
                  helperText={errors.date_of_birth && errors.date_of_birth.message}
                />
              </li>
            </ul>
            <div className="card-body d-flex justify-content-end align-items-end">
              <button type="submit" className="btn btn-success" disabled={isSubmitting || isValid}>
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="col-md-6">
        <div className="card py-3 mb-3">
          <div className="col d-flex flex-column flex-sm-row justify-content-between">
            <div className="text-center text-sm-left mb-2 mb-sm-0">
              <h4 className="pt-sm-2 pb-1 mb-0 text-nowrap">{user && user?.email}</h4>
              <p className="mb-0">@{user && user?.username}</p>
            </div>
            <div className="text-center text-sm-right">
              <span className="badge badge-primary">Client</span>
              <div className="text-muted">
                <small>Joined {moment(user && user?.created_at).format('DD MMMM YYYY')}</small>
              </div>
            </div>
          </div>
          <div className="col d-flex flex-column flex-sm-row justify-content-between">
            <div className="text-muted">
              <small>Last seen {lastSeen !== null ? format(lastSeen) : '-'}</small>
            </div>
            <div>
              <button
                className="btn btn-block btn-secondary"
                onClick={() => {
                  Swal.fire({
                    title: 'Do you want to logout from this account?',
                    showCancelButton: true,
                    cancelButtonText: 'No',
                    confirmButtonText: 'Yes'
                  }).then((result) => {
                    if (result.isConfirmed) {
                      logout();
                    }
                  });
                }}
              >
                <i className="fa fa-sign-out"></i>
                <span>Logout</span>
              </button>
            </div>
          </div>
        </div>
        <ChangePasswordForm />
        <div className="mb-3" />
        <div className="card mb-4">
          <div className="card-header">Delete Account</div>
          <div className="card-body">
            {/* <p>Deleting your account is a permanent action and cannot be undone. If you are sure you want to delete your account, select the
            button below.</p> */}
            <p style={{ marginBottom: '5px' }}>Deleting your account is a permanent action and cannot be undone.</p>
            <p>If you are sure you want to delete your account, select the button below.</p>
            <button
              onClick={() => {
                Swal.fire({
                  title: 'Are you sure?',
                  text: "You won't be able to revert this! Contact admin if want to recover this account later",
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Yes, delete it!'
                }).then(async (result) => {
                  if (result.isConfirmed) {
                    try {
                      setLoadCircle(true);
                      let response = await Axios().delete(`/api/client/profile/disable/${user.id}`);
                      if (response.data.status) {
                        toast.success(response.data.message);
                        logout();
                      }
                    } catch (error) {
                      handleErrors(error);
                      setLoadCircle(false);
                    } finally {
                      setLoadCircle(false);
                    }
                  }
                });
              }}
              className="btn btn-danger-soft text-danger"
              style={{ backgroundColor: '#f1e0e3' }}
              type="button"
            >
              I understand, delete my account
            </button>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <h6 className="card-title font-weight-bold" style={{ fontSize: '24px' }}>
              Support
            </h6>
            <p className="card-text">Get fast, free help from our friendly assistants.</p>{' '}
            <div className="d-flex w-100 align-items-end justify-content-end">
              <button
                className="btn btn-success"
                onClick={() => {
                  navigate('/contact');
                }}
              >
                Contact Us
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileForm;
