/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import HeadphonesOutlinedIcon from '@mui/icons-material/HeadphonesOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../hooks/useAuth';
import Axios from '../../../lib/axios';
import { handleErrors } from '../../../lib/handleErrors';
import './nav.css';

const Nav = (props) => {
  const { user, isLoading } = useAuth({
    middleware: 'guest'
  });
  const navigate = useNavigate();
  const [isOpenNav, setIsOpenNav] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [category, setCategory] = useState();

  const [openMegaMenu, setOpenMegaMenu] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      const responseCategory = await Axios().get(`/api/client/category/random`);
      setCategory(responseCategory.data.data);
    } catch (error) {
      handleErrors(error);
    }
  };

  useEffect(() => {
    setIsOpenNav(props.openNav);
  }, [props.openNav]);

  const closeNav = () => {
    props.closeNav();
  };

  return (
    <>
      {isOpenNav === true && <div className="navbarOverlay" onClick={props.closeNav}></div>}
      <div className={`nav d-flex align-items-center ${isOpenNav === true && 'click'}`}>
        <div className="container-fluid">
          <div className="row position-relative">
            {/* col-sm-8 */}
            <div className="col-sm-10 part2 position-static">
              <nav className={isOpenNav === true ? 'open' : ''}>
                <ul className="list list-inline mb-0">
                  <li className="list-inline-item">
                    <Button>
                      <Link to={'/'} onClick={props.closeNav}>
                        Home
                      </Link>
                    </Button>
                  </li>

                  <li className="list-inline-item">
                    <Button
                      onClick={() => {
                        navigate('/products');
                      }}
                    >
                      <Link>Products</Link>
                    </Button>
                  </li>

                  <li className="list-inline-item">
                    <Button
                      onClick={() => {
                        navigate('/promos');
                      }}
                    >
                      <Link>Promo</Link>
                    </Button>
                  </li>

                  <li className="list-inline-item position-static">
                    <Button onClick={() => setOpenMegaMenu(!openMegaMenu)}>
                      <Link>
                        Category <KeyboardArrowDownIcon className={`${openMegaMenu === true && 'rotateIcon'}`} />
                      </Link>
                    </Button>
                    <div style={{ left: '12%' }} className={`dropdown_menu megaMenu ${openMegaMenu === true && 'open'}`}>
                      {category &&
                        category.length !== 0 &&
                        category.map((item, index) => {
                          return (
                            <div
                              key={index}
                              onClick={() => {
                                navigate(`/category/${item.name.replace(/\s/g, '-').toLowerCase()}`);
                                window.location.reload();
                              }}
                              className="col my-1"
                              style={{ cursor: 'pointer' }}
                            >
                              {item.name}
                            </div>
                          );
                        })}

                      {/* <div className="col">
                          <img
                            alt="Banner Menu"
                            src="https://wp.alithemes.com/html/nest/demo/assets/imgs/banner/banner-menu.png"
                            className="w-100"
                          />
                        </div> */}
                    </div>
                  </li>
                  <li className="list-inline-item">
                    <Button
                      onClick={() => {
                        navigate('/contact');
                      }}
                    >
                      <Link>Contact</Link>
                    </Button>
                  </li>
                </ul>

                {windowWidth < 992 && (
                  <>
                    {!isLoading && !user && (
                      <div className="pl-3 pr-3">
                        <br />
                        <Link to={'/account/email/signin'}>
                          <Button className="btn btn-g btn-lg w-100" onClick={closeNav}>
                            Sign In
                          </Button>
                        </Link>
                      </div>
                    )}
                  </>
                )}
              </nav>
            </div>

            <div className="col-sm-2 part3 d-flex align-items-center">
              <div className="phNo d-flex align-items-center ml-auto">
                <span>
                  <HeadphonesOutlinedIcon />
                </span>
                <div className="info ml-3">
                  <h3 className="text-g mb-0">(061) 4148287</h3>
                  <p className="mb-0">24/7 Support Center</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Nav;
