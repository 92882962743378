/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Button } from '@mui/material';
import Rating from '@mui/material/Rating';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { MyContext } from '../../App';
import './style.css';

import { useNavigate } from 'react-router-dom';

import numeral from 'numeral';
import LoadingSpinner from '../../components/LoadingSpinner';
import { useAuth } from '../../hooks/useAuth';
import Axios from '../../lib/axios';
import { handleErrors } from '../../lib/handleErrors';
import { loadingUpdate } from '../../redux/loadingSlice';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

const Cart = () => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [cart, setCart] = useState();
  const [dataStock, setDataStock] = useState();
  const [loadCircle, setLoadCircle] = useState(false);
  const context = useContext(MyContext);
  const dispatch = useDispatch();
  const { user, isLoading } = useAuth({
    middleware: 'guest'
  });
  const navigate = useNavigate();

  const initializeData = async () => {
    setLoadCircle(true);
    try {
      const responseDetailCart = await Axios().get(`/api/client/cart/${user.id}`);
      if (responseDetailCart && responseDetailCart.data.data) {
        setCart(responseDetailCart.data.data);
        setDataStock(responseDetailCart.data.data.map((item) => item.quantity));
      }
    } catch (e) {
      handleErrors(e);
    } finally {
      setLoadCircle(false);
    }
  };

  useEffect(() => {
    dispatch(loadingUpdate(false));
    if (!isLoading && !user) {
      navigate('/account/email/signin');
    } else {
      initializeData();
    }

    window.scrollTo(0, 0);
  }, [user]);

  const onDelete = async (data) => {
    let isExecuted = confirm(`Do you want to delete this cart ${data.productName}?`);
    if (isExecuted == true) {
      try {
        await Axios().post(`/api/client/cart/delete/${data.cartId}`);
        // location.reload();
        await initializeData();
      } catch (error) {
        const error_message = error.response.data.errors;
        handleErrors(error_message);
      }
    }
  };

  const increment = async (id, index) => {
    try {
      setIsDisabled(true);
      setLoadCircle(true);
      const response = await Axios().post(`/api/client/cart/increment/${id}`);
      const newDataStock = { ...dataStock };
      newDataStock[index] = response.data.data;
      await setDataStock(newDataStock);
      await initializeData();
      // window.location.reload();
    } catch (error) {
      setIsDisabled(false);
      setLoadCircle(false);
      if (error?.response?.data?.code == '020') {
        const error_message = error?.response?.data?.errors[0]?.message;
        handleErrors(error_message);
      }
      if (error?.response?.data?.code == '101') {
        const error_message = error?.response?.data?.errors[0]?.message;
        handleErrors(error_message);
      }
      if (error?.response?.data?.code == '022') {
        const error_message = error?.response?.data?.errors[0]?.message;
        handleErrors(error_message);
      }
    } finally {
      setIsDisabled(false);
      setLoadCircle(false);
    }
  };

  const decrement = async (id, index) => {
    try {
      setIsDisabled(true);
      setLoadCircle(true);
      const response = await Axios().post(`/api/client/cart/decrement/${id}`);
      const newDataStock = { ...dataStock };
      newDataStock[index] = response.data.data;
      await setDataStock(newDataStock);
      await initializeData();
      // window.location.reload();
    } catch (error) {
      setIsDisabled(false);
      setLoadCircle(false);
      if (error?.response?.data?.code == '020') {
        const error_message = error?.response?.data?.errors[0]?.message;
        handleErrors(error_message);
      }
      if (error?.response?.data?.code == '101') {
        const error_message = error?.response?.data?.errors[0]?.message;
        handleErrors(error_message);
      }
      if (error?.response?.data?.code == '022') {
        const error_message = error?.response?.data?.errors[0]?.message;
        handleErrors(error_message);
      }
    } finally {
      setIsDisabled(false);
      setLoadCircle(false);
    }
  };

  return loadCircle == true ? (
    <LoadingSpinner isLoading={loadCircle} />
  ) : (
    <>
      {context.windowWidth > 992 && (
        <div className="breadcrumbWrapper mb-4">
          <div className="container-fluid">
            <ul className="breadcrumb breadcrumb2 mb-0">
              <li>
                <Link to={'/'}>Home</Link>
              </li>
              <li>Shop</li>
              <li>Cart</li>
            </ul>
          </div>
        </div>
      )}

      <section className="cartSection mb-5">
        <div className="container-fluid">
          {cart && cart.length > 0 ? (
            cart.map((companyCart, index) => (
              <div key={index} className="row mb-5">
                <div className="col-md-12">
                  <div className="card p-4">
                    <div className="d-flex align-items-center w-100">
                      <div className="left">
                        <h1 className="hd mb-0">{companyCart.companyName} PRODUCTS</h1>
                        <p>
                          There are <span className="text-g">{companyCart.cartItems.length}</span> products in {companyCart.companyName}{' '}
                          cart
                        </p>
                      </div>
                    </div>

                    <div className="cartWrapper mt-4">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Product</th>
                              <th>Unit Price</th>
                              <th>Stock</th>
                              <th>Quantity</th>
                              <th>Subtotal</th>
                              <th style={{ textAlign: 'center' }}>Remove</th>
                            </tr>
                          </thead>

                          <tbody>
                            {companyCart.cartItems.length !== 0 &&
                              companyCart.cartItems.map((cartItem, index) => {
                                return (
                                  <tr key={index}>
                                    <td width={'50%'}>
                                      <div className="d-flex align-items-center">
                                        <div className="img">
                                          <Link to={`/product/${cartItem.productId}`}>
                                            <img
                                              src={
                                                `${process.env.REACT_APP_BACKEND_URL}/storage/images/product/${cartItem.imageName}` +
                                                '?im=Resize=(100,100)'
                                              }
                                              className="w-100"
                                            />
                                          </Link>
                                        </div>

                                        <div className="info pl-4">
                                          <Link to={`/product/${cartItem.productId}`}>
                                            <h4>{cartItem.productName}</h4>
                                          </Link>
                                          <Rating name="half-rating-read" value={5} precision={0.5} readOnly />{' '}
                                          <span className="text-light">(5)</span>
                                        </div>
                                      </div>
                                    </td>

                                    <td width="10%">
                                      <span>Rp. {numeral(cartItem?.price).format('0,0')}</span>
                                    </td>

                                    <td width="10%">
                                      <span>{numeral(cartItem?.stock).format('0,0')}</span>
                                    </td>

                                    <td>
                                      <div className="addCartSection pt-4 pb-4 d-flex align-items-center ">
                                        <div className="counterSec mr-3">
                                          <input type="number" defaultValue={cartItem.quantity} value={dataStock[index]} />
                                          <span
                                            className={`arrow plus ${isDisabled ? 'disabled' : ''}`}
                                            onClick={() => {
                                              if (cartItem?.quantity >= cartItem?.stock) {
                                                toast.error("Quantity value can't be more than stock value!");
                                              } else {
                                                increment(cartItem.cartId, index);
                                              }
                                            }}
                                          >
                                            <KeyboardArrowUpIcon />
                                          </span>
                                          <span
                                            className={`arrow minus ${(dataStock[index] && dataStock[index] <= '1') || isDisabled ? 'disabled' : ''}`}
                                            onClick={() => {
                                              if (cartItem?.quantity > cartItem?.stock) {
                                                toast.error("Quantity value can't be more than stock value!");
                                              } else {
                                                decrement(cartItem.cartId, index);
                                              }
                                            }}
                                          >
                                            <KeyboardArrowDownIcon />
                                          </span>
                                        </div>
                                      </div>
                                    </td>

                                    <td>
                                      <span className="text-g">
                                        Rp.
                                        {numeral(parseInt(cartItem.price.split(',').join('')) * parseInt(cartItem.quantity)).format('0,0')}
                                      </span>
                                    </td>

                                    <td align="center">
                                      <span
                                        className="cursor"
                                        style={{ fontSize: '48px' }}
                                        onClick={() => context.removeItemsFromCart(cartItem.productId)}
                                      >
                                        <DeleteOutlineOutlinedIcon onClick={() => onDelete(cartItem)} fontSize="48" />
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                        <div className="d-flex align-items-end justify-content-end mt-5 mb-0">
                          <Button className="btn-g btn-lg" onClick={() => navigate('/checkout', { state: { companyCart } })}>
                            Proceed To CheckOut
                          </Button>
                        </div>
                      </div>
                    </div>

                    <br />
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="card p-4">
              <div className="d-flex align-items-center w-100">
                <div className="left">
                  <h1 className="hd mb-0">No products founded in cart!</h1>
                </div>
              </div>
            </div>
          )}
          <div className="d-flex align-items-center mt-5 ml-3">
            <Link to="/">
              <Button className="btn-g">
                <KeyboardBackspaceIcon /> Continue Shopping
              </Button>
            </Link>
            {/* <Button className='btn-g ml-auto' onClick={updateCartData}>
                      <RefreshIcon /> Update Cart</Button> */}
          </div>

          {/* <div className="col-md-4 cartRightBox">
                <div className="card p-4 ">
                  <div className="d-flex align-items-center mb-4">
                    <h5 className="mb-0 text-light">Subtotal</h5>
                    <h3 className="ml-auto mb-0 font-weight-bold">
                      <span className="text-g">
                        {cartItems.length !== 0 &&
                          cartItems
                            .map((item) => parseInt(item.price.split(',').join('')) * item.quantity)
                            .reduce((total, value) => total + value, 0)}
                      </span>
                    </h3>
                  </div>
  
                  <div className="d-flex align-items-center mb-4">
                    <h5 className="mb-0 text-light">Shipping</h5>
                    <h3 className="ml-auto mb-0 font-weight-bold">
                      <span>Free</span>
                    </h3>
                  </div>
  
                  <div className="d-flex align-items-center mb-4">
                    <h5 className="mb-0 text-light">Estimate for</h5>
                    <h3 className="ml-auto mb-0 font-weight-bold">United Kingdom</h3>
                  </div>
  
                  <div className="d-flex align-items-center mb-4">
                    <h5 className="mb-0 text-light">Total</h5>
                    <h3 className="ml-auto mb-0 font-weight-bold">
                      <span className="text-g">
                        {cartItems.length !== 0 &&
                          cartItems
                            .map((item) => parseInt(item.price.split(',').join('')) * item.quantity)
                            .reduce((total, value) => total + value, 0)}
                      </span>
                    </h3>
                  </div>
  
                  <br />
                  <Link to={'/checkout'}>
                    <Button
                      className="btn-g btn-lg"
                      onClick={() => {
                        context.setCartTotalAmount(
                          cartItems.length !== 0 &&
                            cartItems
                              .map((item) => parseInt(item.price.split(',').join('')) * item.quantity)
                              .reduce((total, value) => total + value, 0)
                        );
                      }}
                    >
                      Proceed To CheckOut
                    </Button>
                  </Link>
                </div>
              </div> */}
        </div>
      </section>
    </>
  );
};

export default Cart;
