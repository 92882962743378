/* eslint-disable react-hooks/exhaustive-deps */
import { Backdrop, Button, CircularProgress, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import GoogleImg from '../../assets/images/google.png';
import firebase from '../../firebase/config';
import Axios from '../../lib/axios';
import { handleErrors } from '../../lib/handleErrors';
import { loadingUpdate } from '../../redux/loadingSlice';

const SignInPhone = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid }
  } = useForm({ mode: 'onChange' });

  const replacePhoneNumber = (num) => {
    if (num.charAt(0) == 0) {
      return `+62${num.substring(1, num.length)}`;
    } else if (num.substring(0, 2) == 62) {
      return `+62${num.substring(2, num.length)}`;
    } else if (num.charAt(0) == 8) {
      return `+62${num}`;
    } else {
      return `${num}`;
    }
  };

  const onSubmit = async (data) => {
    try {
      dispatch(loadingUpdate(true));
      const phoneNumber = replacePhoneNumber(data.phone);
      await Axios().post('/api/client/user/status', { phoneNumber: phoneNumber });

      if (!window.recaptchaVerifier) {
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('captcha', {
          size: 'invisible'
        });
      }

      window.recaptchaVerifier.render();

      await Axios().post('/api/client/user/status', { phoneNumber: phoneNumber });
      const response = await firebase.auth().signInWithPhoneNumber(phoneNumber, window.recaptchaVerifier);
      window.confirmationResult = response;
      navigate('/account/phone/verify-otp', { state: { phone: phoneNumber } });

      dispatch(loadingUpdate(false));
    } catch (e) {
      let error_message;
      if (e && e?.response) {
        error_message = e;
      } else {
        if (e && e?.message?.includes('too-many-requests')) {
          error_message =
            'Terdeteksi aktifitas anomali pada akun sehingga dapat menggirimkan kode, silakan lakukan kembali dalam waktu 1x24 jam.';
          await Axios().post('/api/client/firebase/banned', { phoneNumber: data.phone });
        } else if (e && e?.message('quota-exceeded')) {
          error_message = 'Kuota melebihi batas waktu.';
        } else if (e && e?.message?.includes('client element has been removed')) {
          error_message = 'Tidak dapat mengirimkan OTP, silakan lakukan kembali.';
          window.location.reload();
        }
      }

      handleErrors(error_message);
      dispatch(loadingUpdate(false));
    }
  };

  const googleHandler = async () => {
    window.location.href = `${process.env.REACT_APP_BACKEND_URL}/client/auth/google/redirect`;
  };

  useEffect(() => {
    return () => {
      window.recaptchaVerifier = null;
    };
  }, []);

  useEffect(() => {
    dispatch(loadingUpdate(false));
  }, [dispatch]);

  useEffect(() => {
    const captchaElement = document.getElementById('captcha');

    if (captchaElement) {
      captchaElement.style.display = 'none';
    }
  }, [dispatch]);

  const urlSearchParams = new URLSearchParams(window.location.search);
  const error = urlSearchParams.get('error');

  useEffect(() => {
    if (error) {
      toast.error(decodeURIComponent(error));

      urlSearchParams.delete('error');

      window.history.replaceState({}, document.title, `${window.location.pathname}`);
    }
  }, [error, urlSearchParams]);

  return (
    <section className="signIn mb-5">
      <div className="breadcrumbWrapper">
        <div className="container-fluid">
          <ul className="breadcrumb breadcrumb2 mb-0">
            <li>
              <Link to="/">Home</Link>{' '}
            </li>
            <li>Sign In Phone</li>
          </ul>
        </div>
      </div>

      <div className="loginWrapper">
        <div className="card shadow">
          <Backdrop sx={{ color: '#000', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isSubmitting} className="formLoader">
            <CircularProgress color="inherit" />
          </Backdrop>

          <h3>Sign In</h3>
          <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
            <div className="form-group mb-4 w-100">
              <TextField
                margin="dense"
                id="phone"
                label="Phone Number"
                type="tel"
                fullWidth
                variant="outlined"
                {...register('phone', {
                  required: 'Phone is required',
                  pattern: {
                    value: /^08\d{8,13}$/,
                    message: 'Invalid phone number format. Format Number: 08xxxxx (cth: 08151xxx,085735xxx) With 10-15 digits'
                  }
                })}
                error={Boolean(errors.phone)}
                helperText={errors.phone && errors.phone?.message}
              />
              <div className="form-text mb-3 font-italic" style={{ fontStyle: 'italic' }}>
                *Format Number: 08xxxxx (cth: 08151xxx,085735xxx) With 10-15 digits
              </div>
            </div>

            <div className="form-group mt-5 mb-4 w-100">
              <div id="captcha" />
              <Button className="btn btn-g btn-lg w-100" disabled={!isValid || isSubmitting} type="submit">
                Sign In
              </Button>
            </div>

            <div className="form-group mt-5 mb-4 w-100 signInOr">
              <p className="text-center">OR</p>
              <Button className="w-100" variant="outlined" onClick={googleHandler}>
                <img alt="GoogleImg" src={GoogleImg} />
                Sign In with Google
              </Button>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <p className="text-left">
                <Link to="/account/email/signup">Create your account</Link>
              </p>
              <p className="text-center">
                <Link to="/account/email/signin">Login With Email</Link>
              </p>
              <p className="text-right">
                <Link to="/account/email/forgotpassword">Forgot password?</Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default SignInPhone;
