function replacePhoneNumber(num) {
  if (num.charAt(0) == 0) {
    return `+62${num.substring(1, num.length)}`;
  } else if (num.substring(0, 2) == 62) {
    return `+62${num.substring(2, num.length)}`;
  } else if (num.charAt(0) == 8) {
    return `+62${num}`;
  } else {
    return `${num}`;
  }
}

export default replacePhoneNumber;
