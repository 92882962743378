/* eslint-disable react-hooks/exhaustive-deps */
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Backdrop, Button, CircularProgress, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import GoogleImg from '../../assets/images/google.png';
import Axios from '../../lib/axios';
import { handleErrors } from '../../lib/handleErrors';
import { useEffect } from 'react';
import LoadingSpinner from '../../components/LoadingSpinner';
import { useDispatch } from 'react-redux';
import { loadingUpdate } from '../../redux/loadingSlice';

const SignInEmail = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [loadCircle, setLoadCircle] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid }
  } = useForm({ mode: 'onChange' });

  const onSubmit = async (data) => {
    try {
      setLoadCircle(true);
      let response = await Axios().post(`/client/login/email`, data);
      if (response.data.status) {
        toast.success(response.data.message);
        navigate('/account/email/verify-otp', {
          state: {
            email: data.email
          }
        });
      }
    } catch (error) {
      handleErrors(error);
      setLoadCircle(false);
    } finally {
      setLoadCircle(false);
    }
  };

  const googleHandler = async () => {
    window.location.href = `${process.env.REACT_APP_BACKEND_URL}/client/auth/google/redirect`;
  };

  const urlSearchParams = new URLSearchParams(window.location.search);
  const error = urlSearchParams.get('error');

  useEffect(() => {
    dispatch(loadingUpdate(false));
    setLoadCircle(true);
    if (error) {
      toast.error(decodeURIComponent(error));

      urlSearchParams.delete('error');

      window.history.replaceState({}, document.title, `${window.location.pathname}`);
    }
    setLoadCircle(false);
  }, [error, urlSearchParams]);

  return loadCircle == true ? (
    <LoadingSpinner isLoading={loadCircle} />
  ) : (
    <section className="signIn mb-5">
      <div className="breadcrumbWrapper">
        <div className="container-fluid">
          <ul className="breadcrumb breadcrumb2 mb-0">
            <li>
              <Link to="/">Home</Link>{' '}
            </li>
            <li>Sign In Email</li>
          </ul>
        </div>
      </div>

      <div className="loginWrapper">
        <div className="card shadow">
          <Backdrop sx={{ color: '#000', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isSubmitting} className="formLoader">
            <CircularProgress color="inherit" />
          </Backdrop>

          <h3>Sign In Email</h3>
          <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
            <div className="form-group mb-4 w-100">
              <TextField
                margin="dense"
                id="email"
                label="Email"
                type="email"
                fullWidth
                variant="outlined"
                {...register('email', {
                  required: 'Email is required',
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: 'Invalid email address'
                  }
                })}
                error={Boolean(errors.email)}
                helperText={errors.email && errors.email.message}
              />
            </div>
            <div className="form-group mb-4 w-100">
              <div className="position-relative">
                <TextField
                  margin="dense"
                  id="password"
                  label="Password"
                  type={showPassword === false ? 'password' : 'text'}
                  fullWidth
                  variant="outlined"
                  {...register('password', {
                    required: { value: true, message: 'Password is required' }
                  })}
                  error={Boolean(errors.password)}
                  helperText={errors.password && errors.password.message}
                />
                <Button className="icon mt-2" onClick={() => setShowPassword(!showPassword)}>
                  {showPassword === false ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                </Button>
              </div>
            </div>

            <div className="form-group mt-5 mb-4 w-100">
              <Button className="btn btn-g btn-lg w-100" disabled={!isValid || isSubmitting} type="submit">
                Sign In
              </Button>
            </div>

            <div className="form-group mt-5 mb-4 w-100 signInOr">
              <p className="text-center">OR</p>
              <Button className="w-100" variant="outlined" onClick={googleHandler}>
                <img alt="GoogleImg" src={GoogleImg} />
                Sign In with Google
              </Button>
            </div>
            <div className="d-flex align-items-center">
              <p className="text-left">
                <Link to="/account/email/signup">Create your account</Link>
              </p>
              <p className="text-center">
                <Link to="/account/phone/signin">Login With Phone Number</Link>
              </p>
              <p className="text-right">
                <Link to="/account/email/forgotpassword">Forgot password?</Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default SignInEmail;
