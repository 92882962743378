/* eslint-disable jsx-a11y/alt-text */
import React, { Fragment, useEffect, useState } from 'react';
import './footer.css';

import Icon1 from '../../assets/images/icon-1.svg';
import Icon2 from '../../assets/images/icon-2.svg';
import Icon3 from '../../assets/images/icon-3.svg';
import Icon4 from '../../assets/images/icon-4.svg';
import Icon5 from '../../assets/images/icon-5.svg';
import Logo from '../../assets/images/logo.png';
import { Link } from 'react-router-dom';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import HeadphonesOutlinedIcon from '@mui/icons-material/HeadphonesOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import paymentImage from '../../assets/images/payment-method.png';

// import appStore from '../../assets/images/app-store.jpg';
// import googlePlay from '../../assets/images/google-play.jpg';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import Newsletter from '../../components/newsletter/index';
import NewsletterImg from '../../assets/images/newsletter.png';
import promoteAppImg from '../../assets/images/promoteApp.png';
import { handleErrors } from '../../lib/handleErrors';
import Axios from '../../lib/axios';
// import { useDispatch } from 'react-redux';
// import { loadingUpdate } from '../../redux/loadingSlice';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Footer = () => {
  const [platformUrl, setPlatformUrl] = useState();
  const [loading, setLoading] = useState(false);
  const yearNow = new Date().getFullYear();
  // const dispatch = useDispatch();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      setLoading(true);
      const responsePlatformUrl = await Axios().get(`/api/client/platform/url`);
      setPlatformUrl(responsePlatformUrl.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleErrors(error);
    }
  };
  return (
    <>
      <section className="newsLetterSection">
        <div className="container-fluid">
          <div className="box d-flex align-items-center">
            <div className="info">
              <h2>
                Stay home & get your daily <br />
                needs from our shop
              </h2>
              <p>Start Your Daily Shopping with Simasco</p>
              <br />
              <br className="res-hide" />
              <Newsletter />
            </div>

            <div className="img">
              <img src={NewsletterImg} className="w-100" />
            </div>
          </div>
        </div>
      </section>

      <div className="footerWrapper">
        <div className="footerBoxes">
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <div className="box d-flex align-items-center w-100">
                  <span>
                    <img src={Icon1} />
                  </span>
                  <div className="info">
                    <h4>Best prices & offers</h4>
                    <p>Exclusive deals for members</p>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="box d-flex align-items-center w-100">
                  <span>
                    <img src={Icon2} />
                  </span>
                  <div className="info">
                    <h4>Free delivery</h4>
                    <p>On orders over min Rp. 40.000</p>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="box d-flex align-items-center w-100">
                  <span>
                    <img src={Icon3} />
                  </span>
                  <div className="info">
                    <h4>Great daily deal</h4>
                    <p>Discounts for loyal customers</p>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="box d-flex align-items-center w-100">
                  <span>
                    <img src={Icon4} />
                  </span>
                  <div className="info">
                    <h4>Wide assortment</h4>
                    <p>Find everything you need</p>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="box d-flex align-items-center w-100">
                  <span>
                    <img src={Icon5} />
                  </span>
                  <div className="info">
                    <h4>Easy returns</h4>
                    <p>No-hassle returns policy</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-3 part1">
                <Link to="/">
                  <img src={Logo} />
                </Link>
                <br />
                <br />
                <p>
                  Elevate your online shopping experience with our platform, where quality meets convenience. Discover a world of wholesale
                  wonders tailored for Grocery and Related Product Merchant Wholesalers and Merchant Wholesalers.
                </p>
                <br />

                <p>
                  <LocationOnOutlinedIcon /> <strong>Address</strong>: JL. Palangkaraya, No. 145 DE, Ps. Baru, Kec. Medan Kota, Kota Medan,
                  Sumatera Utara 20212
                </p>
                <p>
                  <HeadphonesOutlinedIcon /> <strong>Call Us:</strong> (061) 4148287{' '}
                </p>
                <p>
                  <EmailOutlinedIcon /> <strong>Email:</strong> support@simasco.live
                </p>
                <p>
                  <WatchLaterOutlinedIcon /> <strong>Hours:</strong> 24 Hours
                </p>
              </div>

              <div className="col-md-6 part2">
                <div className="row">
                  <div className="col">
                    <h3>Company</h3>
                    <ul className="footer-list mb-sm-5 mb-md-0">
                      <li>
                        <Link target="_blank" to="https://client.simasco.live/support">
                          Return Policy
                        </Link>
                      </li>
                      <li>
                        <Link target="_blank" to="https://client.simasco.live/support">
                          Terms Of Use
                        </Link>
                      </li>
                      <li>
                        <Link target="_blank" to="https://client.simasco.live/support">
                          Privacy Policy
                        </Link>
                      </li>
                    </ul>
                  </div>

                  <div className="col">
                    <h3>Company</h3>
                    <ul className="footer-list mb-sm-5 mb-md-0">
                      <li>
                        <Link target="_blank" to="https://client.simasco.live/support">
                          Return Policy
                        </Link>
                      </li>
                      <li>
                        <Link target="_blank" to="https://client.simasco.live/support">
                          Terms Of Use
                        </Link>
                      </li>
                      <li>
                        <Link target="_blank" to="https://client.simasco.live/support">
                          Privacy Policy
                        </Link>
                      </li>
                    </ul>
                  </div>

                  <div className="col">
                    <h3>Corporate</h3>
                    <ul className="footer-list mb-sm-5 mb-md-0">
                      <li>
                        <Link target="_blank" to="https://client.simasco.live/support">
                          Return Policy
                        </Link>
                      </li>
                      <li>
                        <Link target="_blank" to="https://client.simasco.live/support">
                          Terms Of Use
                        </Link>
                      </li>
                      <li>
                        <Link target="_blank" to="https://client.simasco.live/support">
                          Privacy Policy
                        </Link>
                      </li>
                    </ul>
                  </div>

                  <div className="col">
                    <h3>Popular</h3>
                    <ul className="footer-list mb-sm-5 mb-md-0">
                      <li>
                        <Link target="_blank" to="https://client.simasco.live/support">
                          Return Policy
                        </Link>
                      </li>
                      <li>
                        <Link target="_blank" to="https://client.simasco.live/support">
                          Terms Of Use
                        </Link>
                      </li>
                      <li>
                        <Link target="_blank" to="https://client.simasco.live/support">
                          Privacy Policy
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-md-3 part3">
                <h3>Install App</h3>
                <br className="res-hide" />
                <p>From Huawei App Store or Uptodown</p>
                <a target="_blank" href="https://url.cloud.huawei.com/r3LfriiNwc?shareTo=qrcode" rel="noreferrer">
                  <img src={promoteAppImg} alt="" style={{ width: '30%', objectFit: 'cover' }} />
                </a>
                <a href="https://simasco-delivery-app-mobile.en.uptodown.com/android" title="Download Simasco Delivery App Mobile">
                  <img src="https://stc.utdstc.com/img/mediakit/download-aao-big-b.png" alt="Download Simasco Delivery App Mobile" />
                </a>

                {/* <div className="d-flex">
                  <Link to={''}>
                    <img src={appStore} width={150} />
                  </Link>
                  <Link to={''}>
                    <img src={googlePlay} className="mx-2" width={150} />
                  </Link>
                </div> */}

                <br />

                <p>Secured Payment Gateways</p>
                <img src={paymentImage} />
              </div>
            </div>

            <hr />

            <div className="row lastStrip">
              <div className="col-md-3 part_1">
                <p>© {yearNow} Simasco - Shop Simasco.live All rights reserved</p>
              </div>

              <div className="col-md-6 d-flex part_2">
                {/* <div className='m-auto d-flex align-items-center phWrap'>
                                    <div className='phNo d-flex align-items-center mx-5'>
                                        <span><HeadphonesOutlinedIcon /></span>
                                        <div className='info ml-3'>
                                            <h3 className='text-g mb-0'>(061) 4148287</h3>
                                            <p className='mb-0'>24/7 Support Center</p>
                                        </div>
                                    </div>

                                    <div className='phNo d-flex align-items-center  mx-5'>
                                        <span><HeadphonesOutlinedIcon /></span>
                                        <div className='info ml-3'>
                                            <h3 className='text-g mb-0'>(061) 4148287</h3>
                                            <p className='mb-0'>24/7 Support Center</p>
                                        </div>
                                    </div>
                                </div> */}
              </div>

              <div className="col-md-3 part3  part_3">
                <div className="d-flex align-items-center">
                  <h5>Follow Us</h5>
                  <ul className="list list-inline">
                    {platformUrl && !loading ? (
                      platformUrl.map((value, index) => {
                        return (
                          <Fragment key={index}>
                            {value.platform_name == 'Twitter' && (
                              <li className="list-inline-item">
                                <Link target={'_blank'} to={value.url} title="Twitter">
                                  <TwitterIcon />
                                </Link>
                              </li>
                            )}
                            {value.platform_name == 'Facebook' && (
                              <li className="list-inline-item">
                                <Link target={'_blank'} to={value.url} title="Facebook">
                                  <FacebookOutlinedIcon />
                                </Link>
                              </li>
                            )}
                            {value.platform_name == 'Instagram' && (
                              <li className="list-inline-item">
                                <Link target={'_blank'} to={value.url} title="Instagram">
                                  <InstagramIcon />
                                </Link>
                              </li>
                            )}
                            {value.platform_name == 'YouTube' && (
                              <li className="list-inline-item">
                                <Link target={'_blank'} to={value.url} title="YouTube">
                                  <YouTubeIcon />
                                </Link>
                              </li>
                            )}
                          </Fragment>
                        );
                      })
                    ) : (
                      <p>Loading...</p>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Footer;
