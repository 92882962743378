import React, { useEffect, useState } from 'react';
import { Badge, Button, Card, Col, ListGroup, Row } from 'react-bootstrap';
import ContentLoader from 'react-content-loader';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Axios from '../../../lib/axios';
import { handleErrors } from '../../../lib/handleErrors';
import { setAddress } from '../../../redux/addressSlice';
import { loadingUpdate } from '../../../redux/loadingSlice';

const MyListAddress = () => {
  const [addresses, setAddresses] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.loading.loading);
  const address = useSelector((state) => state.address);

  const handleDefault = async (id) => {
    dispatch(loadingUpdate(true));
    try {
      const response = await Axios().post(`/api/client/profile/address/customer/${id}`);
      setAddresses(response.data.data);
      navigate('/account/list-address');
    } catch (error) {
      const error_message = error.response.data.errors;
      toast.error(error_message);
    } finally {
      dispatch(loadingUpdate(false));
    }
  };

  const handleEdit = (id) => {
    navigate(`/account/edit-address/${id}`);
  };

  const handleDelete = async (id, name) => {
    let isExecuted = confirm(`Do you want to delete this address ${name}?`);
    if (isExecuted == true) {
      try {
        dispatch(loadingUpdate(true));
        await Axios().post(`/api/client/profile/address/disable/${id}`);
        const response = await Axios().get(`/api/client/profile/address`);
        if (response.data.data.length == 0) {
          setAddresses([]);
        } else {
          setAddresses(response.data.data.filter((x) => x.id != id));
        }
      } catch (error) {
        const error_message = error.response.data.errors;
        toast.error(error_message);
      } finally {
        dispatch(loadingUpdate(false));
      }
    }
  };

  const fetchData = async () => {
    dispatch(loadingUpdate(true));
    try {
      const responseAddresses = await Axios().get(`/api/client/profile/address`);
      setAddresses(responseAddresses.data.data);
    } catch (e) {
      handleErrors(e);
    } finally {
      dispatch(loadingUpdate(false));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="breadcrumbWrapper mb-4">
        <div className="container-fluid">
          <ul className="breadcrumb breadcrumb2 mb-0">
            <li>
              <Link to={'/'}>Home</Link>
            </li>
            <li>My Address</li>
          </ul>
        </div>
      </div>
      <div className="container-fluid my-5">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h3>My Address</h3>
          <div className="d-flex gap-2">
            <Link to="/">
              <Button variant="secondary">Back</Button>
            </Link>
            <div className="mx-2" />
            <Link to="/account/add-address">
              <Button style={{ backgroundColor: '#3BB77E', color: 'white', border: 'none' }}>Add Address</Button>
            </Link>
          </div>
        </div>

        <Row>
          {addresses && addresses.length > 0 ? (
            addresses.map((value, index) => (
              <Col xs={6} lg={3} key={index} className="mb-3">
                <Card data-aos="fade-up" data-aos-duration="400" data-aos-delay="100" data-aos-once="true">
                  <Card.Header className="d-flex justify-content-between">
                    {value.destination_area_name}
                    <span>
                      {/* <Badge className="text-white p-2" bg={value.status == true ? 'success' : 'danger'}>
                        {value.status == true ? 'Active' : 'Inactive'}
                      </Badge> */}
                      <Badge className="text-white p-2" bg={(address && address.id) === value.id ? 'success' : 'danger'}>
                        {(address && address.id) === value.id ? 'Active' : 'Inactive'}
                      </Badge>
                    </span>
                  </Card.Header>
                  <Card.Body>
                    <ListGroup variant="flush">
                      <ListGroup.Item>
                        <p className="mb-0">
                          <strong>{value.customer.name} </strong>
                        </p>
                        <p className="mb-0">
                          <strong>{value.customer.user.email} </strong>
                        </p>
                        <p className="mb-0">
                          <strong>{value.customer.user.phone}</strong>
                        </p>
                        <p className="mb-0">Address: {value.address}</p>
                        <p className="mb-0">Description: {value.description} </p>
                      </ListGroup.Item>
                    </ListGroup>
                  </Card.Body>
                  <Card.Footer className="d-flex gap-2 text-end align-items-center justify-content-between">
                    {value.status == true ? (
                      <p>Default</p>
                    ) : (
                      <Button
                        variant="success"
                        onClick={async (e) => {
                          e.target.disabled = true;
                          await handleDefault(value.id);
                          e.target.disabled = false;
                        }}
                      >
                        Set as default
                      </Button>
                    )}
                    <div className="d-flex">
                      {(address && address.id) !== value.id && (
                        <>
                          <div className="mx-2" />
                          <Button
                            variant="primary"
                            onClick={(e) => {
                              e.target.disabled = true;
                              dispatch(setAddress(value));
                              navigate('/account/list-address');
                              e.target.disabled = false;
                            }}
                          >
                            Make active
                          </Button>
                        </>
                      )}

                      <div className="mx-2" />
                      <Button style={{ backgroundColor: '#3BB77E', color: 'white', border: 'none' }} onClick={() => handleEdit(value.id)}>
                        Edit
                      </Button>
                      <div className="mx-2" />
                      <Button
                        variant="danger"
                        onClick={(e) => {
                          e.target.disabled = true;
                          handleDelete(value.id, value.destination_area_name);
                          e.target.disabled = false;
                        }}
                      >
                        Delete
                      </Button>
                    </div>
                  </Card.Footer>
                </Card>
              </Col>
            ))
          ) : !loading ? (
            <p>No addresses have been made yet!</p>
          ) : (
            <ContentLoader />
          )}
        </Row>
      </div>
    </>
  );
};

export default MyListAddress;
