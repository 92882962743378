import { toast } from 'react-toastify';

function ErrorListToast({ messages }) {
  return (
    <div>
      <ul>
        {messages.map((message, index) => (
          <li key={index}>{message}</li>
        ))}
      </ul>
    </div>
  );
}

export const handleErrors = (error) => {
  if (error?.response && error?.response?.status === 403) {
    toast.error(error?.response?.data?.message);
  } else if (error?.response && error?.response?.data && error?.response?.data?.errors) {
    const errorData = error?.response?.data?.errors;
    if (typeof errorData === 'object') {
      Object.keys(errorData).forEach((key) => {
        if (Array.isArray(errorData[key]) && errorData[key].length > 0) {
          const messages = errorData[key].map((message) => message);
          toast.error(<ErrorListToast messages={messages} />);
        } else {
          toast.error(errorData[key].message);
        }
      });
    } else {
      const messages = errorData.map((error) => error?.message);
      toast.error(<ErrorListToast messages={messages} />);
    }
  } else if (error?.response && error?.response?.data && error?.response?.data?.message) {
    toast.error(error?.response?.data?.message);
  } else if (typeof error == 'string') {
    toast.error(error);
  } else {
    toast.error('Terjadi Kesalahan, Silahkan Coba Kembali Nanti!');
  }
};
