/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

export default function CarouselImageProduct({ imageProduct }) {
  return (
    <div className="card detailsPageTabs">
      <Carousel showStatus={false} showIndicators={false} showArrows={true}>
        {imageProduct &&
          imageProduct.map((image, index) => (
            <div key={index}>
              <img src={`${process.env.REACT_APP_BACKEND_URL}/storage/images/product/${image}`} alt="Product Image" className="rounded" />
            </div>
          ))}
      </Carousel>
    </div>
  );
}
