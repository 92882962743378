import React, { Component, useEffect, useState } from 'react';
import { GoogleMap, LoadScript, Marker, useLoadScript } from '@react-google-maps/api';
import Autocomplete from 'react-google-autocomplete';
import Geocode from 'react-geocode';
import SearchMap from './SearchMap';
Geocode.setApiKey(process.env.REACT_APP_GOOGLEMAPSAPI_KEY);
Geocode.enableDebug();

const libraries = ['drawing', 'places'];
const containerStyle = {
  width: '100%',
  height: '50vh'
};

const Map = (props) => {
  const [center, setCenter] = useState();
  const [isShowMarker, setIsShowMarker] = useState(false);

  const [fullpath, setFullpath] = useState('');
  const [location, setLocation] = useState({
    address: '',
    city: '',
    area: '',
    state: ''
  });
  const [mapPosition, setMapPosition] = useState({
    lat: props.center.lat,
    lng: props.center.lng
  });
  const [markerPosition, setMarkerPosition] = useState({
    lat: '',
    lng: ''
  });

  useEffect(() => {
    Geocode.fromLatLng(mapPosition.lat, mapPosition.lng).then(
      (response) => {
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components,
          city = getCity(addressArray),
          area = getArea(addressArray),
          state = getState(addressArray);

        setLocation({
          address: address ? address : '',
          city: city ? city : '',
          area: area ? area : '',
          state: state ? state : ''
        });
      },
      (error) => {
        console.error(error);
      }
    );
  }, [mapPosition, markerPosition]);

  const onMarkerDragEnd = (event) => {
    let lat = event.latLng.lat(),
      lng = event.latLng.lng();
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components,
          city = getCity(addressArray),
          area = getArea(addressArray),
          state = getState(addressArray);

        setLocation({
          address: address ? address : '',
          city: city ? city : '',
          area: area ? area : '',
          state: state ? state : ''
        });

        setMarkerPosition({
          lat: lat,
          lng: lng
        });

        setMapPosition({
          lat: lat,
          lng: lng
        });

        props.onMarkerChange({ fullpath, lat, lng });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const getCity = (addressArray) => {
    let city = '';
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0] && 'administrative_area_level_2' === addressArray[i].types[0]) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };

  const getArea = (addressArray) => {
    let area = '';
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if ('sublocality_level_1' === addressArray[i].types[j] || 'locality' === addressArray[i].types[j]) {
            area = addressArray[i].long_name;
            return area;
          }
        }
      }
    }
  };

  const getState = (addressArray) => {
    let state = '';
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (addressArray[i].types[0] && 'administrative_area_level_1' === addressArray[i].types[0]) {
          state = addressArray[i].long_name;
          return state;
        }
      }
    }
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLEMAPSAPI_KEY,
    libraries
  });

  const mapRef = React.useRef();
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  const panTo = React.useCallback(({ lat, lng, fullpath }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(18);

    setFullpath(fullpath);
    setMarkerPosition({
      lat: lat,
      lng: lng
    });

    setMapPosition({
      lat: lat,
      lng: lng
    });

    props.onMarkerChange({ fullpath, lat, lng });
  }, []);

  return isLoaded ? (
    <div>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={
          markerPosition.lat && markerPosition.lng
            ? {
                lat: markerPosition.lat,
                lng: markerPosition.lng
              }
            : { lat: props.center.lat, lng: props.center.lng }
        }
        zoom={18}
        onLoad={onMapLoad}
        id="map"
      >
        {/* Marker */}
        <Marker
          draggable={true}
          onDragEnd={onMarkerDragEnd}
          position={
            markerPosition.lat && markerPosition.lng
              ? {
                  lat: markerPosition.lat,
                  lng: markerPosition.lng
                }
              : { lat: props.center.lat, lng: props.center.lng }
          }
        />
      </GoogleMap>
      <SearchMap panTo={panTo} />
    </div>
  ) : (
    <></>
  );
};

export default Map;
