/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { MyContext } from '../../App';
// import Sidebar from '../../components/Sidebar';
import Skeleton from 'react-loading-skeleton';
import PaginatedItem from '../../components/PaginatedItem/index.tsx';
import Promo from '../../components/promo/index';
import SidebarPromo from '../../components/SidebarPromo';
import { useAuth } from '../../hooks/useAuth';
import Axios from '../../lib/axios';
import { handleErrors } from '../../lib/handleErrors';

const PromoListing = () => {
  const context = useContext(MyContext);
  const [loadAllPromos, setLoadAllPromos] = useState(true);
  const [promos, setPromos] = useState(null);
  const [initialPromos, setInitialPromos] = useState([]);
  const { user } = useAuth({
    middleware: 'auth'
  });
  const loading = useSelector((state) => state.loading.loading);
  // Pagination
  let itemsPerPage = 12;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const pageCount = Math.ceil(promos?.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % promos?.length;
    setItemOffset(newOffset);
    window.scrollTo(0, 0);
  };

  const filterByViewType = (keyword) => {
    let filteredPromos = initialPromos.filter((promo) => {
      return promo.view_type === keyword;
    });
    setPromos(filteredPromos);
    window.scrollTo(0, 0);
  };

  const filterByType = (keyword) => {
    let filteredPromos = initialPromos.filter((promo) => {
      return promo.type === keyword;
    });

    setPromos(filteredPromos);
    window.scrollTo(0, 0);
  };

  // useEffect(() => {
  //   const loadData = async () => {
  //     try {
  //       const responsePromos = await Axios().get(`/api/client/promos`);
  //       const publicPromos = responsePromos.data.data.map((promo) => ({
  //         ...promo,
  //         view_type: 'public'
  //       }));

  //       setPromos(publicPromos);
  //       setInitialPromos(publicPromos);
  //       if (user) {
  //         const responseUserPromos = await Axios().get(`/api/client/promos/user`);
  //         const userPromos = responseUserPromos.data.data.map((promo) => ({
  //           ...promo,
  //           view_type: 'user'
  //         }));
  //         setPromos((prevPromos) => [...prevPromos, ...userPromos]);
  //         setInitialPromos((prevPromos) => [...prevPromos, ...userPromos]);
  //       }
  //       setLoadAllPromos(false);
  //     } catch (error) {
  //       const error_message = error.response.data.errors;
  //       handleErrors(error_message);
  //       setLoadAllPromos(false);
  //     }
  //   };
  //   loadData();
  // }, [user]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const responsePromos = await Axios().get(`/api/client/promos`);
        let publicPromos = [];

        if (responsePromos && responsePromos?.data?.data && responsePromos?.data?.data?.length > 0) {
          publicPromos = responsePromos.data.data.map((promo) => ({
            ...promo,
            view_type: 'public'
          }));
        }

        let combinedPromos = publicPromos;

        if (user) {
          const responseUserPromos = await Axios().get(`/api/client/promos/user`);
          let userPromos = [];

          if (responseUserPromos && responseUserPromos?.data?.data && responseUserPromos?.data?.data?.length > 0) {
            userPromos = responseUserPromos.data.data.map((promo) => ({
              ...promo,
              view_type: 'user'
            }));
          }

          // Remove duplicates by ID before setting the state
          const uniquePromos = userPromos.filter((userPromo) => !publicPromos.some((publicPromo) => publicPromo.id === userPromo.id));

          combinedPromos = [...publicPromos, ...uniquePromos];
        }

        setPromos(combinedPromos);
        setInitialPromos(combinedPromos);
        setLoadAllPromos(false);
      } catch (error) {
        const error_message = error.response.data.errors;
        handleErrors(error_message);
        setLoadAllPromos(false);
      }
    };
    loadData();
  }, [user]);

  return (
    <>
      {context.windowWidth < 992 && (
        <>
          {context.isopenNavigation === false && (
            <Button className="btn-g btn-lg w-100 filterBtn" onClick={() => context.openFilters()}>
              Filters
            </Button>
          )}
        </>
      )}

      <section className="listingPage">
        <div className="container-fluid">
          <div className="breadcrumb flex-column">
            <h1 className="text-capitalize">Promos</h1>
            <ul className="list list-inline mb-0">
              <li className="list-inline-item">
                <Link to={'/'}>Home </Link>
              </li>
            </ul>
          </div>

          <div className="listingData">
            <div className="row">
              <div
                data-aos="fade-right"
                data-aos-duration="400"
                data-aos-delay="100"
                data-aos-once="true"
                className={`col-md-3 sidebarWrapper ${context.isOpenFilters === true && 'click'}`}
              >
                <SidebarPromo filterByViewType={filterByViewType} filterByType={filterByType} />
              </div>

              <div className="col-md-9 rightContent homeProducts pt-0">
                <div className="topStrip d-flex align-items-center">
                  {loadAllPromos === false && promos?.length !== 0 && (
                    <p className="mb-0">
                      We found <span className="text-success">{promos && promos?.length}</span> items for you!
                    </p>
                  )}
                </div>

                <div className="productRow pl-4 pr-3">
                  {loading ? (
                    <div className="d-flex justify-content-center align-items-center w-100">
                      <div className="loading-spinner"></div>
                    </div>
                  ) : loadAllPromos === false && promos?.length !== 0 ? (
                    promos?.slice(itemOffset, endOffset).map((item, index) => {
                      return (
                        <div
                          className="item"
                          data-aos="fade-up"
                          data-aos-duration="400"
                          data-aos-delay="100"
                          data-aos-once="true"
                          key={index}
                        >
                          <Promo tag={item.view_type} item={item} />
                        </div>
                      );
                    })
                  ) : !loadAllPromos && promos && promos?.length === 0 ? (
                    <div className="d-flex justify-content-center align-items-center w-100">
                      <p>No promos have been founded at this page yet!</p>
                    </div>
                  ) : (
                    <div className="d-flex flex-wrap gap-5 m-5 gx-5" style={{ width: '100%', marginRight: '15px' }}>
                      {Array.from({ length: 12 }).map((_, index) => (
                        <div
                          key={index}
                          className="productThumb sm-loading"
                          style={{ width: 'calc(25% - 16px)', marginRight: '15px', marginBottom: '20px' }}
                        >
                          <div className="imgWrapper">
                            <div className="p-4 wrapper mb-3 sm-item-primary">
                              <Skeleton height={200} />
                            </div>
                          </div>

                          <div className="info">
                            <span className="d-block mt-3 catName sm-item-secondary">
                              <Skeleton height={20} />
                            </span>
                            <hr />
                            <span className="d-block mt-3 catName sm-item-secondary">
                              <Skeleton height={20} />
                            </span>
                            <span className="d-block mt-3 catName sm-item-secondary">
                              <Skeleton height={20} />
                            </span>
                            <span className="d-block mt-3 catName sm-item-secondary">
                              <Skeleton height={20} />
                            </span>
                            <div className="d-flex align-items-center mt-3"></div>
                            <span className="d-block p-3 my-3 catName sm-item-secondary">
                              <Skeleton height={20} width={'100%'} />
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>

                {!loading && (
                  <div className="pagination justify-end">
                    <PaginatedItem pageCount={pageCount} handlePageClick={handlePageClick}></PaginatedItem>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PromoListing;
