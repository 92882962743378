/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useEffect, useRef, useState } from 'react';
import SliderBanner from './slider/index';

import Banners from '../../components/banners';

import Banner4 from '../../assets/images/banner4.jpg';
import Product from '../../components/product';
import './style.css';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import { MyContext } from '../../App';
import shuffleItems from '../../functions/shuffleItems';
import Axios from '../../lib/axios';
import { handleErrors } from '../../lib/handleErrors';
import { loadingUpdate } from '../../redux/loadingSlice';
import TopProducts from './TopProducts';
import Skeleton from 'react-loading-skeleton';
import PaginatedItem from '../../components/PaginatedItem/index.tsx';

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [banners, setBanners] = useState([]);
  const [products, setProducts] = useState(null);

  const [bestSells, setBestSells] = useState([]);
  const [isLoadingProducts, setIsLoadingProducts] = useState(true);

  // Pagination
  let itemsPerPage = 15;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const pageCount = Math.ceil(products?.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % products?.length;
    setItemOffset(newOffset);
    window.scrollTo(0, 0);
  };

  const productRow = useRef();
  const context = useContext(MyContext);

  var settings = {
    dots: false,
    infinite: context.windowWidth < 992 ? false : true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    fade: false,
    arrows: context.windowWidth < 992 ? false : true
  };

  useEffect(() => {
    try {
      const fetchData = async () => {
        try {
          setIsLoadingProducts(true);
          dispatch(loadingUpdate(true));
          const responseProduct = await Axios().get('/api/client/product');
          const responseBanner = await Axios().get('/api/client/banner/index');
          setProducts(responseProduct.data.data);
          setBanners(responseBanner.data.data);
          setBestSells(shuffleItems(responseProduct.data.data).slice(0, 9));
          dispatch(loadingUpdate(false));
          setIsLoadingProducts(false);
        } catch (e) {
          dispatch(loadingUpdate(false));
          setIsLoadingProducts(false);
          handleErrors(e);
        }
      };

      fetchData();
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <div style={{ display: 'block' }}>
      <SliderBanner banners={banners} />
      <Banners />
      <section className="homeProducts homeProductWrapper">
        <div className="container-fluid">
          <div className="d-flex align-items-center homeProductsTitleWrap">
            <h2 className="hd mb-0 mt-0 res-full">Popular Products</h2>
          </div>

          <div className={`productRow ${isLoadingProducts === true && 'loading'}`} ref={productRow}>
            {products && products?.length > 0 ? (
              products?.slice(itemOffset, endOffset).map((item, index) => {
                return (
                  <div data-aos="fade-up" data-aos-duration="400" data-aos-once="true" className="item" key={index}>
                    <Product tag={item.type} item={item} />
                  </div>
                );
              })
            ) : !isLoadingProducts && products && products?.length === 0 ? (
              <div className="d-flex justify-content-center align-items-center w-100">
                <p>No products have been founded at this page yet!</p>
              </div>
            ) : (
              <div className="d-flex flex-wrap gap-5 m-5 gx-5" style={{ marginRight: '15px' }}>
                {Array.from({ length: 15 }).map((_, index) => (
                  <div
                    key={index}
                    className="productThumb sm-loading"
                    style={{ width: 'calc(20% - 16px)', marginRight: '15px', marginBottom: '20px' }}
                  >
                    <div className="imgWrapper">
                      <div className="p-4 wrapper mb-3 sm-item-primary">
                        <Skeleton height={200} />
                      </div>
                    </div>

                    <div className="info">
                      <span className="d-block mt-3 catName sm-item-secondary">
                        <Skeleton height={20} />
                      </span>
                      <span className="d-block mt-3 catName sm-item-secondary">
                        <Skeleton height={20} />
                      </span>
                      <div className="d-flex align-items-center mt-3">
                        <Skeleton width={150} height={20} />
                      </div>
                      <div className="d-flex align-items-center mt-3">
                        <Skeleton width={300} height={20} />
                      </div>
                      <div className="d-flex align-items-center mt-3">
                        <Skeleton width={180} height={20} />
                      </div>
                      <span className="d-block p-3 my-3 catName sm-item-secondary">
                        <Skeleton height={20} />
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>

          {!isLoadingProducts && (
            <div className="pagination justify-end">
              <PaginatedItem pageCount={pageCount} handlePageClick={handlePageClick}></PaginatedItem>
            </div>
          )}
        </div>
      </section>

      <section className="homeProducts homeProductsRow2 pt-0">
        <div className="container-fluid">
          <div className="d-flex align-items-center">
            <h2 className="hd mb-0 mt-0">Daily Best Sells</h2>
          </div>

          <br className="res-hide" />
          <br className="res-hide" />
          <div className="row">
            <div className="col-md-3 pr-5 res-hide">
              <img
                style={{ cursor: 'pointer' }}
                onClick={() => navigate('/products')}
                src={Banner4}
                data-aos="fade-right"
                data-aos-duration="400"
                data-aos-delay="600"
                data-aos-once="true"
                className="w-100"
              />
            </div>

            <div className="col-md-9">
              <Slider {...settings} className="prodSlider">
                {bestSells?.length !== 0 &&
                  bestSells.map((item, index) => {
                    return (
                      <div className="item" key={index}>
                        <Product tag={item.type} item={item} />
                      </div>
                    );
                  })}
              </Slider>
            </div>
          </div>
        </div>
      </section>

      <section className="topProductsSection">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <TopProducts item={products && shuffleItems(products).slice(0, 3)} title="Top Selling" loading={isLoadingProducts} />
            </div>

            <div className="col">
              <TopProducts item={products && shuffleItems(products).slice(0, 3)} title="Trending Products" loading={isLoadingProducts} />
            </div>

            <div className="col">
              <TopProducts item={products && shuffleItems(products).slice(0, 3)} title="Recently added" loading={isLoadingProducts} />
            </div>

            <div className="col">
              <TopProducts item={products && shuffleItems(products).slice(0, 3)} title="Top Rated" loading={isLoadingProducts} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
