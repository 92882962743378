import firebase from 'firebase/compat/app';
import { getAnalytics } from 'firebase/analytics';
import 'firebase/compat/auth';
// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyBAMMhlqdJM2rFr5S5mkgAKHwYUTXhvNrM',
  authDomain: 'simasco-firebase.firebaseapp.com',
  projectId: 'simasco-firebase',
  storageBucket: 'simasco-firebase.appspot.com',
  messagingSenderId: '128128309313',
  appId: '1:128128309313:web:0c724c4540491251368ec0',
  measurementId: 'G-MZH8XBM20P'
};

// Initialize Firebase
export const app = firebase.initializeApp(firebaseConfig);
getAnalytics(app);

export default firebase;
