/* eslint-disable no-unused-vars */
import { faEye, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from 'jquery';
import moment from 'moment';
import numeral from 'numeral';
import { Component, Fragment } from 'react';
import { Alert, Button, Card, Form, Row, Table } from 'react-bootstrap';
import { createRoot } from 'react-dom/client';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Axios from '../../lib/axios';
import { withRouter } from '../../lib/withRouter';

class MyTransaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      start_time: this.getTimeNow(),
      end_time: this.getTimeNow(),
      validation: []
    };
  }

  componentDidMount() {
    $(this.table).DataTable({
      processing: true,
      serverside: true,
      responsive: true,
      bDestroy: true,
      stateSave: true,
      stateSaveCallback: function (settings, data) {
        localStorage.setItem('DataTables_' + window.location.pathname, JSON.stringify(data));
      },
      stateLoadCallback: function (settings) {
        return JSON.parse(localStorage.getItem('DataTables_' + window.location.pathname));
      },
      ajax: {
        url: `${process.env.REACT_APP_BACKEND_URL}/api/client/transaction/history`,
        type: 'GET',
        headers: {
          'X-Requested-With': 'XMLHttpRequest'
        },
        xhrFields: {
          withCredentials: true
        }
      },
      columns: [
        {
          data: null,
          render: function (data, type, row, meta) {
            return meta.row + meta.settings._iDisplayStart + 1;
          },
          orderable: false,
          searchable: false
        },

        {
          data: 'id',
          searchable: true,
          orderable: false
        },
        {
          data: 'grand_total',
          searchable: true,
          orderable: false,
          render: function (data) {
            return 'Rp. ' + numeral(data).format('0,0');
          }
        },
        {
          data: 'delivery_price',
          searchable: true,
          orderable: false,
          render: function (data) {
            return 'Rp. ' + numeral(data).format('0,0');
          }
        },
        {
          data: 'created_at',
          searchable: true,
          orderable: false,
          width: '20%',
          render: function (data) {
            return moment(data).format('DD MMMM YYYY h:mm A');
          }
        },
        {
          data: 'status',
          searchable: true,
          orderable: false,
          width: '20%'
        },
        {
          data: null,
          searchable: false,
          orderable: false,
          width: '10%'
        }
      ],
      columnDefs: [
        {
          targets: 1,
          createdCell: (td, cellData, rowData) => {
            const root = createRoot(td);
            root.render(<p title={rowData.id}>{rowData.id}</p>);
          }
        },
        {
          targets: 5,
          createdCell: (td, cellData, rowData) => {
            const root = createRoot(td);
            root.render(this.getTransactionStatus(rowData.status));
          }
        },
        {
          targets: 6,
          createdCell: (td, cellData, rowData) => {
            const root = createRoot(td);
            root.render(
              <Button
                style={{ backgroundColor: '#3BB77E', border: 'none' }}
                className="btn ms-2 btn-primary mx-auto"
                onClick={() => this.handleDetail(rowData.id)}
              >
                <FontAwesomeIcon icon={faEye} style={{ height: '15px', color: '#ffffff' }} />
              </Button>
            );
          }
        }
      ]
    });
  }

  getTransactionStatus = (status) => {
    if (!status) {
      return null;
    }

    if (status == 'WAITING_PAYMENT') {
      return (
        <div className="text-center py-1 px-2 text-nowrap rounded bg-light" style={{ fontSize: '14px' }}>
          {status.replace(/_/g, ' ').toUpperCase()}
        </div>
      );
    } else if (['ON_PROCESSING_PAYMENT', 'ON_HOLD'].includes(status)) {
      return (
        <div className="text-center py-1 px-2 text-nowrap rounded bg-secondary text-white" style={{ fontSize: '14px' }}>
          {status.replace(/_/g, ' ').toUpperCase()}
        </div>
      );
    } else if (status == 'WAITING_CONFIRMATION') {
      return (
        <div className="text-center py-1 px-2 text-nowrap rounded bg-info text-white" style={{ fontSize: '14px' }}>
          {status.replace(/_/g, ' ').toUpperCase()}
        </div>
      );
    } else if (status == 'ON_PROCESS') {
      return (
        <div className="text-center py-1 px-2 text-nowrap rounded bg-warning text-white" style={{ fontSize: '14px' }}>
          {status.replace(/_/g, ' ').toUpperCase()}
        </div>
      );
    } else if (status == 'REJECT') {
      return (
        <div className="text-center py-1 px-2 text-nowrap rounded bg-dark text-white" style={{ fontSize: '14px' }}>
          {status.replace(/_/g, ' ').toUpperCase()}
        </div>
      );
    } else if (status == 'ON_DELIVERY') {
      return (
        <div className="text-center py-1 px-2 text-nowrap rounded bg-primary text-white" style={{ fontSize: '14px' }}>
          {status.replace(/_/g, ' ').toUpperCase()}
        </div>
      );
    } else if (['REFUNDED', 'COMPLETE'].includes(status)) {
      return (
        <div className="text-center py-1 px-2 text-nowrap rounded bg-success text-white" style={{ fontSize: '14px' }}>
          {status.replace(/_/g, ' ').toUpperCase()}
        </div>
      );
    } else if (['PAYMENT_FAILED', 'CANCELLED'].includes(status)) {
      return (
        <div className="text-center py-1 px-2 text-nowrap rounded bg-danger text-white" style={{ fontSize: '14px' }}>
          {status.replace(/_/g, ' ').toUpperCase()}
        </div>
      );
    } else {
      return null;
    }
  };

  handleDetail(id) {
    this.props.router.navigate(`detail/${id}`);
  }

  async onFilter(e) {
    e.target.disabled = true;
    try {
      const res = await Axios().post(`/api/client/transaction/filter`, {
        start_time: this.state.start_time,
        end_time: this.state.end_time
      });
      $(this.table).DataTable().clear();
      $(this.table).DataTable().rows.add(res.data.data).draw();
    } catch (error) {
      this.setState({
        validation: error.response.data.errors
      });
    } finally {
      e.target.disabled = false;
    }
  }

  getTimeNow() {
    let now = new Date();
    now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
    return now.toISOString().slice(0, 16);
  }

  async handleChange(event) {
    $(this.table).DataTable().columns().search('').draw(true);
    if (event.target.value != 'ALL') {
      $(this.table).DataTable().columns(5).search(event.target.value).draw(true);
    }
  }

  render() {
    return (
      <Fragment>
        <div className="breadcrumbWrapper mb-4">
          <div className="container-fluid">
            <ul className="breadcrumb breadcrumb2 mb-0">
              <li>
                <Link to={'/'}>Home</Link>
              </li>
              <li>My Transaction</li>
            </ul>
          </div>
        </div>
        <div className="d-flex justify-content-center container-fluid my-5">
          <Card style={{ maxWidth: '100%' }}>
            <Card.Header>
              <div className="d-flex justify-content-between align-items-center">
                <p className="mb-0">Riwayat Pemesanan</p>
                <button
                  onClick={() => {
                    $(this.table).DataTable().ajax.reload(null, false);
                  }}
                  style={{ border: 'none', background: 'transparent' }}
                >
                  <FontAwesomeIcon icon={faRefresh} className="me-2" />
                  <p className="d-inline">Refresh</p>
                </button>
              </div>
            </Card.Header>
            <Card.Body>
              <Row
                className="d-flex justify-content-between flex-row mb-4"
                style={{ flexDirection: 'row', marginLeft: '10px', marginRight: '10px' }}
              >
                <div className="d-flex flex-row">
                  <Form.Group controlId="dob">
                    <Form.Label>Start Time</Form.Label>
                    <Form.Control
                      type="datetime-local"
                      name="dob"
                      onChange={(e) => {
                        this.setState({
                          start_time: e.target.value
                        });
                      }}
                      defaultValue={this.getTimeNow()}
                    />
                  </Form.Group>
                  <div className="mx-3" />
                  <Form.Group controlId="dob">
                    <Form.Label>End Time</Form.Label>
                    <Form.Control
                      type="datetime-local"
                      name="dob"
                      onChange={(e) =>
                        this.setState({
                          end_time: e.target.value
                        })
                      }
                      defaultValue={this.getTimeNow()}
                    />
                  </Form.Group>
                  <Button
                    className="btn mx-3 mt-auto mb-2 btn-success"
                    style={{ height: '40px', color: 'white' }}
                    onClick={(e) => this.onFilter(e)}
                  >
                    Filter
                  </Button>
                </div>
                <Form.Group
                  controlId="status"
                  className="d-flex flex-column justify-self-end text-right"
                  style={{ justifySelf: 'end', marginLeft: 'auto' }}
                >
                  <Form.Label>Filter By Status</Form.Label>
                  <Form.Select
                    className="form-select"
                    style={{
                      border: '1px solid #ced4da',
                      borderRadius: '4px',
                      padding: '8px 12px',
                      cursor: 'pointer',
                      outline: 'none',
                      float: 'right'
                    }}
                    onChange={(e) => this.handleChange(e)}
                    aria-label="Default select example"
                  >
                    <option value="ALL">SEMUA</option>
                    <option value="WAITING_PAYMENT">MENUNGGU PEMBAYARAN</option>
                    <option value="ON_PROCESSING_PAYMENT">DALAM PROSES PEMBAYARAN</option>
                    <option value="WAITING_CONFIRMATION">MENUNGGU KONFIRMASI</option>
                    <option value="ON_PROCESS">PENGIRIMAN SEDANG DIPROSES</option>
                    <option value="REFUNDED">PENGEMBALIAN DANA BERHASIL</option>
                    <option value="REJECT">DITOLAK</option>
                    <option value="ON_DELIVERY">DIKIRIMKAN</option>
                    <option value="COMPLETE">SELESAI</option>
                    <option value="PAYMENT_FAILED">PEMBAYARAN GAGAL</option>
                  </Form.Select>
                </Form.Group>
                <div className="my-2">
                  {this.state.validation.start_time && <Alert variant="danger">{this.state.validation.start_time}</Alert>}
                  {this.state.validation.end_time && <Alert variant="danger">{this.state.validation.end_time}</Alert>}
                </div>
              </Row>
              <div className="border-bottom mt-3 mb-4 w-100" />
              <Table style={{ whiteSpace: 'nowrap' }} responsive hover ref={(table) => (this.table = table)}>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>ID</th>
                    <th>Price</th>
                    <th>Delivery Price</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
              </Table>
            </Card.Body>
          </Card>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.loading.loading
});

const mapDispatchToProps = (dispatch) => ({
  loadingUpdate: (isLoading) => dispatch(loadingUpdate(isLoading))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MyTransaction));
