/* eslint-disable react-hooks/exhaustive-deps */
import { Backdrop, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import OtpInput from '../../components/OtpInput';
import { useAuth } from '../../hooks/useAuth';
import Axios from '../../lib/axios';
import { handleErrors } from '../../lib/handleErrors';
import { loadingUpdate } from '../../redux/loadingSlice';
import LoadingSpinner from '../../components/LoadingSpinner';

const VerifyOtpEmail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [loadCircle, setLoadCircle] = useState(false);

  const [otp, setOtp] = useState('');
  const [show, setShow] = useState(false);

  const [timeResend, setTimeResend] = useState(0);
  const [statusResendOTP, setStatusResendOTP] = useState(false);

  const { email } = location.state || {};

  let myInterval;

  const { emailVerifyOtp } = useAuth({
    middleware: 'guest',
    redirectIfAuthenticated: '/'
  });

  useEffect(() => {
    dispatch(loadingUpdate(false));
    setLoadCircle(true);
    if (email == null) {
      navigate('/account/email/signin');
    }
    window.confirmationResult && setShow(true);
    setTimeResend(30);
    setLoadCircle(false);
  }, []);

  useEffect(() => {
    myInterval =
      timeResend &&
      setInterval(() => {
        setTimeResend((e) => e - 1);
        if (timeResend <= 1) {
          clearInterval(myInterval);
          setStatusResendOTP(true);
        }
      }, 1000);

    return () => clearInterval(myInterval);
  }, [timeResend]);

  const onSubmit = async (e) => {
    try {
      e.target.disabled = true;
      await emailVerifyOtp(
        {
          data: {
            email: email,
            otp: otp
          }
        },
        async (response) => {
          await toast.success(response.data.message);
        },
        (error) => {
          handleErrors(error);
        }
      ).finally(() => {
        e.target.disabled = false;
      });
    } catch (e) {
      handleErrors(e);
    }
  };

  const resendOTP = async () => {
    dispatch(loadingUpdate(true));

    if (statusResendOTP) {
      try {
        setTimeResend(30);
        setStatusResendOTP(false);

        const response = await Axios().post(`/client/email/request_otp`, {
          email: email
        });
        toast.success(response.data.message);
        dispatch(loadingUpdate(false));
      } catch (e) {
        handleErrors(e);
        setStatusResendOTP(true);
        setTimeResend(0);
      }
    }
  };

  const seconds = String(timeResend % 60).padStart(2, 0);
  const minutes = String(Math.floor(timeResend / 60)).padStart(2, 0);

  return loadCircle == true ? (
    <LoadingSpinner isLoading={loadCircle} />
  ) : (
    <section className="signIn mb-5">
      <div className="breadcrumbWrapper">
        <div className="container-fluid">
          <ul className="breadcrumb breadcrumb2 mb-0">
            <li>
              <Link to="/">Home</Link>{' '}
            </li>
            <li>Verify Otp Email</li>
          </ul>
        </div>
      </div>

      <div className="loginWrapper">
        <div className="card shadow">
          <Backdrop sx={{ color: '#000', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={show} className="formLoader">
            <CircularProgress color="inherit" />
          </Backdrop>

          <h3>Verify Otp Email</h3>
          <div className="text-center mt-5">
            <h4 className="Title">
              Masukkan kode verifikasi yang dikirim ke
              <br />
              Email anda.
            </h4>
          </div>
          <div className="text-center">
            <OtpInput value={otp} valueLength={6} onChange={(e) => setOtp(e)} />
            <div className="d-flex">
              <button onClick={() => navigate('/account/email/signin')} className="backButton">
                Kembali
              </button>
              <button type="submit" onClick={(e) => onSubmit(e)} className="w-100 btn btn-g btn-lg">
                Kirim
              </button>
            </div>
            <div id=" captcha" style={{ display: 'none' }}></div>
            <button className="mt-5 btn btn-g btn-lg w-100" disabled={!statusResendOTP} onClick={() => resendOTP()}>
              Kirim Ulang{' '}
              {timeResend ? (
                <span>
                  {' '}
                  &nbsp; ({minutes} : {seconds})
                </span>
              ) : (
                ''
              )}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VerifyOtpEmail;
