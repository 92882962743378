/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/no-unknown-property */
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { Button } from '@mui/material';
import Rating from '@mui/material/Rating';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './style.css';

import { Favorite } from '@mui/icons-material';
import numeral from 'numeral';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Axios from '../../lib/axios';
import { handleErrors } from '../../lib/handleErrors';
import { loadingUpdate } from '../../redux/loadingSlice';

const Product = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [productData, setProductData] = useState();

  useEffect(() => {
    setProductData(props.item);
  }, [props.item]);

  const onCartStore = async (productId) => {
    try {
      dispatch(loadingUpdate(true));
      if (!user) {
        navigate('/account/email/signin');
      } else {
        let response = await Axios().post(`/api/client/cart/store`, {
          user_id: user.id,
          product_id: productId,
          stock: 1
        });
        if (response.data.errors.length != 0) {
          const error_message = response.data.errors;
          toast.error(error_message);
        } else {
          navigate('/cart');
        }
      }
    } catch (error) {
      handleErrors(error);
    } finally {
      dispatch(loadingUpdate(false));
    }
  };

  return (
    <div className="productThumb">
      {props.tag == null && props.tag == undefined ? (
        <span className={`badge Umum`}>Umum</span>
      ) : (
        <span className={`badge ${props.tag}`}>{props.tag}</span>
      )}

      {productData !== undefined && (
        <>
          <Link to={`/product/${productData.productId}`}>
            <div className="imgWrapper">
              <div className="p-4 wrapper mb-3">
                <img
                  src={
                    `${process.env.REACT_APP_BACKEND_URL}/storage/images/product/${productData && productData.imageName}` +
                    '?im=Resize=(420,420)'
                  }
                  className="w-100"
                />
              </div>

              <div className="overlay transition">
                <ul className="list list-inline mb-0">
                  {productData && !productData.is_wishlist ? (
                    <li className="list-inline-item">
                      <a className="cursor" tooltip="Add to Wishlist">
                        <FavoriteBorderOutlinedIcon />
                      </a>
                    </li>
                  ) : (
                    <li className="list-inline-item">
                      <a className="cursor" tooltip="UnWishlist">
                        <Favorite />
                      </a>
                    </li>
                  )}
                  <li className="list-inline-item">
                    <a className="cursor" tooltip="Quick View">
                      <RemoveRedEyeOutlinedIcon />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </Link>

          <div className="info">
            <span className="d-block catName">{productData.companyName}</span>
            <h4 className="title">
              <Link to={`/product/${productData.productId}`}>{productData.productName.substr(0, 50) + '...'}</Link>
            </h4>
            <Rating
              name="half-rating-read"
              value={parseFloat(productData.averageRating !== null ? productData.averageRating : 5)}
              precision={0.5}
              readOnly
            />
            <span className="brand d-block text-g">
              By{' '}
              <Link to={`/product/${productData.productId}`} className="text-g">
                {productData.companyName}
              </Link>
            </span>

            <div className="d-flex align-items-center mt-3">
              <div className="d-flex align-items-center w-100">
                <span className="price text-g font-weight-bold">Rp {numeral(productData.price).format('0,0')}</span>{' '}
                {/* <span className="oldPrice ml-auto">Rp {productData.oldPrice}</span> */}
              </div>
            </div>

            <Button
              className="w-100 transition mt-3"
              onClick={() => {
                if (productData && !productData.is_added) {
                  onCartStore(productData.productId);
                } else {
                  navigate('/cart');
                }
              }}
            >
              <ShoppingCartOutlinedIcon />
              {productData && productData.is_added ? 'Added' : 'Add'}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default Product;
