/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import './style.css';

import Rating from '@mui/material/Rating';
import numeral from 'numeral';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

const TopProducts = (props) => {
  return (
    <>
      <div data-aos="fade-up" data-aos-duration="400" data-aos-delay="600" data-aos-once="true" className="topSelling_box">
        <h3>{props.title}</h3>
        {(props.item && props?.item?.length === 0) || props?.loading === true
          ? Array.from({ length: 3 }).map((_, index) => (
              <div key={index} className="items d-flex align-items-center">
                <div className="sm-item-primary">
                  <Skeleton height={250} width={200} />
                </div>
                <div className="info px-3">
                  <Skeleton height={20} width={180} />
                  <Skeleton width={100} height={20} />
                  <div className="d-flex align-items-center">
                    <span className="price text-g font-weight-bold">
                      <Skeleton width={100} height={20} />
                    </span>
                  </div>
                </div>
              </div>
            ))
          : props?.item?.map((value, index) => {
              return (
                <div key={index}>
                  <Link style={{ textDecoration: 'none' }} to={`/product/${value.productId}`}>
                    <div className="items d-flex align-items-center">
                      <div className="img">
                        <Link to={`/product/${value.productId}`}>
                          <img
                            src={`${process.env.REACT_APP_BACKEND_URL}/storage/images/product/${value.imageName}` + '?im=Resize=(420,420)'}
                            className="w-100"
                          />
                        </Link>
                      </div>
                      <div className="info px-3">
                        <Link to={`/product/${value.productId}`}>
                          <h4>{value?.productName}</h4>
                        </Link>
                        <Rating
                          name="half-rating-read"
                          defaultValue={value.averageRating !== null ? value.averageRating : 5}
                          precision={0.5}
                          readOnly
                        />
                        <Link to={`/product/${value.productId}`}>
                          <h4>{value?.companyName}</h4>
                        </Link>
                        <div className="d-flex align-items-center">
                          <span className="price text-g font-weight-bold">Rp. {numeral(value.price).format('0,0')}</span>
                          {/* <span className="oldPrice">$32.8</span> */}
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
      </div>
    </>
  );
};

export default TopProducts;
