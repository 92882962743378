/* eslint-disable react-hooks/exhaustive-deps */
import { Backdrop, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import OtpInput from '../../components/OtpInput';
import Axios from '../../lib/axios';
import { handleErrors } from '../../lib/handleErrors';
import firebase from '../../firebase/config';
import { loadingUpdate } from '../../redux/loadingSlice';

const VerifyOtpPhone = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [otp, setOtp] = useState('');
  const [show, setShow] = useState(false);

  const [timeResend, setTimeResend] = useState(0);
  const [statusResendOTP, setStatusResendOTP] = useState(false);

  let phoneNumber = location.state?.phone;
  let myInterval;

  useEffect(() => {
    window.confirmationResult && setShow(true);
    !window.confirmationResult && navigate('/account/phone/signin');

    if (window.confirmationResult) {
      Axios()
        .post('/api/client/firebase/check_resend', { phoneNumber: phoneNumber })
        .then((res) => {
          if (res.data.data.status) {
            setStatusResendOTP(true);
          } else {
            setTimeResend(res.data.data.timeout);
          }
        });
    }
  }, [navigate, phoneNumber]);

  useEffect(() => {
    myInterval =
      timeResend &&
      setInterval(() => {
        setTimeResend((e) => e - 1);
        if (timeResend <= 1) {
          clearInterval(myInterval);
          setStatusResendOTP(true);
        }
      }, 1000);

    return () => clearInterval(myInterval);
  }, [timeResend]);

  useEffect(() => {
    const captchaElement = document.getElementById('captcha');

    if (captchaElement) {
      captchaElement.style.display = 'none';
    }
  }, [dispatch]);

  const onSubmit = async (e) => {
    dispatch(loadingUpdate(true));
    let confirmationResult = window.confirmationResult;

    try {
      e.target.disabled = true;
      const result = await confirmationResult.confirm(otp);
      await Axios().post('/api/client/firebase/user/login', result.user);
      const response = await Axios().get('/api/client/user');
      dispatch(setUser(response.data));
      await Axios().post('/api/client/firebase/update_otp_time', result.user);
      navigate('/');
      e.target.disabled = false;
    } catch (error) {
      e.target.disabled = false;
      let error_message;
      if (error.code) {
        error_message = error.message.replace('Firebase: ', '');
      } else if (error.message) {
        if (error.message.includes('too-many-requests')) {
          error_message =
            'Terdeteksi aktivitas anomali pada akun sehingga tidak dapat mengirimkan kode. Silakan coba lagi dalam waktu 1x24 jam.';
          await Axios().post('/api/client/firebase/banned', { phoneNumber: data.phone });
        } else if (error.message.includes('quota-exceeded')) {
          error_message = 'Kuota melebihi batas waktu.';
        } else if (error.message.includes('client element has been removed')) {
          error_message = 'Tidak dapat mengirimkan OTP. Silakan coba lagi.';
          window.location.reload();
        }
      }

      if (error.response && error.response.status === 401) {
        navigate('/account/phone/signin');
      }

      toast.error(error_message);
      dispatch(loadingUpdate(false));
    }
  };

  const resendOTP = async () => {
    try {
      dispatch(loadingUpdate(true));

      if (statusResendOTP) {
        let recaptcha = new firebase.auth.RecaptchaVerifier('captcha', {
          size: 'invisible'
        });
        const delay = await Axios().post('/api/client/firebase/resend', { phoneNumber: phoneNumber });
        setTimeResend(delay.data.data.timeout);
        setStatusResendOTP(false);

        const response = await firebase.auth().signInWithPhoneNumber(phoneNumber, recaptcha);
        window.confirmationResult = response;
        dispatch(loadingUpdate(false));
      }
    } catch (e) {
      let error_message;

      if (e.message.includes('too-many-requests')) {
        error_message =
          'Terdeteksi aktifitas anomali pada akun sehingga dapat menggirimkan kode, silakan lakukan kembali dalam waktu 1x24 jam.';
        await Axios().post('/api/client/firebase/banned', { phoneNumber: data.phone });
      } else if (e.message('quota-exceeded')) {
        error_message = 'Kuota melebihi batas waktu.';
      } else if (e.message.includes('client element has been removed')) {
        error_message = 'Tidak dapat mengirimkan OTP, silakan lakukan kembali.';
        window.location.reload();
      }
      handleErrors(error_message);
      setStatusResendOTP(true);
      setTimeResend(0);
      dispatch(loadingUpdate(false));
    }
  };

  const seconds = String(timeResend % 60).padStart(2, 0);
  const minutes = String(Math.floor(timeResend / 60)).padStart(2, 0);

  return (
    <section className="signIn mb-5">
      <div className="breadcrumbWrapper">
        <div className="container-fluid">
          <ul className="breadcrumb breadcrumb2 mb-0">
            <li>
              <Link to="/">Home</Link>{' '}
            </li>
            <li>Verify Otp Phone Number</li>
          </ul>
        </div>
      </div>

      <div className="loginWrapper">
        <div className="card shadow">
          <Backdrop sx={{ color: '#000', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={!show} className="formLoader">
            <CircularProgress color="inherit" />
          </Backdrop>

          <h3>Verify Otp Phone Number</h3>
          <div className="text-center">
            <h4 className="Title">
              Masukkan kode verifikasi yang dikirim ke
              <br />
              Nomor anda yaitu {phoneNumber}
            </h4>
          </div>
          <div className="text-center">
            <OtpInput value={otp} valueLength={6} onChange={(e) => setOtp(e)} />
            <div className="d-flex">
              <button onClick={() => navigate('/account/phone/signin')} className="backButton">
                Kembali
              </button>
              <button type="submit" onClick={(e) => onSubmit(e)} className="w-100 btn btn-g btn-lg">
                Kirim
              </button>
            </div>
            <div id=" captcha" style={{ display: 'none' }}></div>
            <button className="mt-5 btn btn-g btn-lg w-100" disabled={!statusResendOTP} onClick={() => resendOTP()}>
              Kirim Ulang{' '}
              {timeResend ? (
                <span>
                  {' '}
                  &nbsp; ({minutes} : {seconds})
                </span>
              ) : (
                ''
              )}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VerifyOtpPhone;
