import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from '../../lib/withRouter';
import ProfileForm from '../../components/account/ProfileForm';
import { loadingUpdate } from '../../redux/loadingSlice';

class MyProfileView extends Component {
  constructor() {
    super();
    this.state = {
      imagePreview: '',
      isDeleting: false
    };
  }

  onImageChange = async (obj) => {
    if (obj) {
      const val = await this.getBase64(obj);
      this.setState({ imagePreview: val });
    } else {
      this.setState({ imagePreview: '' });
    }
  };
  getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(file);
      reader.onerror = (error) => reject(error);
    });
  };
  render() {
    return (
      <>
        <div className="breadcrumbWrapper mb-4">
          <div className="container-fluid">
            <ul className="breadcrumb breadcrumb2 mb-0">
              <li>
                <Link to={'/'}>Home</Link>
              </li>
              <li>My Profile</li>
            </ul>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center container-fluid my-5">
          <div className="row">
            <ProfileForm />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.loading.loading,
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  loadingUpdate: (isLoading) => dispatch(loadingUpdate(isLoading))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MyProfileView));
