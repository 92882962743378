/* eslint-disable react-hooks/exhaustive-deps */
import { Backdrop, CircularProgress, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Button } from '@mui/material';
import Axios from '../../lib/axios';
import { toast } from 'react-toastify';
import { handleErrors } from '../../lib/handleErrors';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

const ResetPasswordEmail = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid }
  } = useForm();

  const onSubmit = async (data) => {
    try {
      const requestData = { ...data, token: searchParams.get('token'), email: searchParams.get('email') };
      await Axios().post(`/client/reset-password`, requestData);
      toast.success('Password successfully changed');
      navigate('/account/email/signin');
    } catch (error) {
      handleErrors(error);
    }
  };

  useEffect(() => {
    if (searchParams.get('email') == null || searchParams.get('token') == null) {
      window.history.back();
    }
  }, []);

  return (
    <section className="signIn mb-5">
      <div className="breadcrumbWrapper">
        <div className="container-fluid">
          <ul className="breadcrumb breadcrumb2 mb-0">
            <li>
              <Link to="/">Home</Link>{' '}
            </li>
            <li>Reset Password</li>
          </ul>
        </div>
      </div>

      <div className="loginWrapper">
        <div className="card shadow">
          <Backdrop sx={{ color: '#000', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isSubmitting} className="formLoader">
            <CircularProgress color="inherit" />
          </Backdrop>

          <h3>Reset Password</h3>
          <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
            <div className="form-group mb-4 w-100">
              <div className="position-relative">
                <TextField
                  margin="dense"
                  id="password"
                  label="New Password"
                  type={showPassword === false ? 'password' : 'text'}
                  fullWidth
                  variant="outlined"
                  {...register('password', {
                    required: { value: true, message: 'New Password is required' }
                  })}
                  error={Boolean(errors.password)}
                  helperText={errors.password && errors.password.message}
                />
                <Button className="icon mt-2" onClick={() => setShowPassword(!showPassword)}>
                  {showPassword === false ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                </Button>
              </div>
            </div>
            <div className="form-group mb-4 w-100">
              <div className="position-relative">
                <TextField
                  margin="dense"
                  id="password_confirmation"
                  label="Confirm New Password"
                  type={showConfirmPassword === false ? 'password' : 'text'}
                  fullWidth
                  variant="outlined"
                  {...register('password_confirmation', {
                    required: { value: true, message: 'Confirm New Password is required' }
                  })}
                  error={Boolean(errors.password_confirmation)}
                  helperText={errors.password_confirmation && errors.password_confirmation.message}
                />
                <Button className="icon mt-2" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                  {showConfirmPassword === false ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                </Button>
              </div>
            </div>

            <div className="form-group  mb-4 w-100">
              <Button type="submit" disabled={!isValid || isSubmitting} className="btn btn-g btn-lg w-100">
                Submit
              </Button>
            </div>

            <div className="d-flex justify-content-between">
              <Link className="float-start" to="/account/email/signup" title="Sign Up">
                Create your account
              </Link>
              <Link className="float-end" to="/account/email/signin" title="Sign In">
                Sign In
              </Link>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ResetPasswordEmail;
