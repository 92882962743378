import React, { useState } from 'react';
import Pagination from '../Pagination/index.tsx';

const items = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50];

interface ItemsProps {
    currentItems: number[];
}

const Items: React.FC<ItemsProps> = ({ currentItems }) => {
    return (
        <>
            {currentItems &&
                currentItems.map((item) => (
                    <div key={item}>
                        <h3>Item #{item}</h3>
                    </div>
                ))}
        </>
    );
};

interface PaginatedItemProps {
    // itemsPerPage: number;
    pageCount: number;
    handlePageClick: any;
}

const PaginatedItem: React.FC<PaginatedItemProps> = ({ pageCount, handlePageClick }) => {
    // const [itemOffset, setItemOffset] = useState(0);
    // const endOffset = itemOffset + itemsPerPage;
    // const currentItems = items.slice(itemOffset, endOffset);
    // const pageCount = Math.ceil(items.length / itemsPerPage);

    // const handlePageClick = (event: { selected: number }) => {
    //     const newOffset = (event.selected * itemsPerPage) % items.length;
    //     setItemOffset(newOffset);
    // };

    return (
        <>
            {/* <Items currentItems={currentItems} /> */}
            <Pagination pageCount={pageCount} handlePageClick={handlePageClick} />
        </>
    );
};

export default PaginatedItem;