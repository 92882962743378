import { handleErrors } from '../lib/handleErrors';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => {
      handleErrors(error);
    }
  })
});

const Providers = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {process.env.REACT_APP_ENV !== 'production' && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  );
};

export default Providers;
