/* eslint-disable react-hooks/exhaustive-deps */
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import { Button } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import Product from '../../components/product';

import { useSelector } from 'react-redux';
import { MyContext } from '../../App';
import Axios from '../../lib/axios';
import { handleErrors } from '../../lib/handleErrors';
import PaginatedItem from '../../components/PaginatedItem/index.tsx';
import Skeleton from 'react-loading-skeleton';

const ProductCategory = () => {
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [company, setCompany] = useState();
  const [products, setProducts] = useState(null);
  const [initialProducts, setInitialProducts] = useState([]);
  const [isOpenDropDown2, setisOpenDropDown2] = useState(false);
  const [loadProducts, setLoadProducts] = useState(true);
  const context = useContext(MyContext);
  const loading = useSelector((state) => state.loading.loading);
  let { id } = useParams();

  // Pagination
  let itemsPerPage = 12;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const pageCount = Math.ceil(products?.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % products?.length;
    setItemOffset(newOffset);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    try {
      const fetchData = async () => {
        try {
          const responseCompany = await Axios().get(`/api/client/company`);
          setCompany(responseCompany.data.data);
          const responseProduct = await Axios().get(`/api/client/product/${id}`);
          setProducts(responseProduct.data.data);
          setInitialProducts(responseProduct.data.data);

          let minPrice = 0;
          let maxPrice = 0;

          if (responseProduct.data.data.length !== 0) {
            responseProduct.data.data.forEach((item) => {
              let prodPrice = parseInt(item.price.toString().replace(/,/g, ''));
              if (prodPrice < minPrice) {
                minPrice = prodPrice;
              }
              if (prodPrice > maxPrice) {
                maxPrice = prodPrice;
              }
            });

            setMinPrice(minPrice);
            setMaxPrice(maxPrice);
          }
          setLoadProducts(false);
        } catch (e) {
          handleErrors(e);
          setLoadProducts(false);
        }
      };

      fetchData();
      window.scrollTo(0, 0);
    } catch (error) {
      handleErrors(error);
    }
  }, []);

  const filterByBrand = (keyword) => {
    let filteredProducts = initialProducts.filter((product) => {
      return product.companyName === keyword;
    });
    setProducts(filteredProducts);
    window.scrollTo(0, 0);
  };

  const filterByPrice = (minValue, maxValue) => {
    let filteredProducts = initialProducts.filter((product) => {
      let price = parseInt(product.price.toString().replace(/,/g, ''));
      return minValue <= price && price <= maxValue;
    });

    setProducts(filteredProducts);
    window.scrollTo(0, 0);
  };

  const filterByRating = (keyword) => {
    const rating = parseFloat(keyword);
    let filteredProducts = initialProducts.filter((product) => {
      const productRating = parseFloat(product.averageRating);

      return productRating === rating;
    });
    setProducts(filteredProducts);
    window.scrollTo(0, 0);
  };

  const sortByPriceLowToHigh = () => {
    const sortedProducts = [...initialProducts].sort((a, b) => {
      let priceA = parseInt(a.price.toString().replace(/,/g, ''));
      let priceB = parseInt(b.price.toString().replace(/,/g, ''));
      return priceA - priceB;
    });

    setProducts(sortedProducts);
    setisOpenDropDown2(false);
  };

  const sortByPriceHighToLow = () => {
    const sortedProducts = [...initialProducts].sort((a, b) => {
      let priceA = parseInt(a.price.toString().replace(/,/g, ''));
      let priceB = parseInt(b.price.toString().replace(/,/g, ''));
      return priceB - priceA;
    });

    setProducts(sortedProducts);
    setisOpenDropDown2(false);
  };

  return (
    <>
      {context.windowWidth < 992 && (
        <>
          {context.isopenNavigation === false && (
            <Button className="btn-g btn-lg w-100 filterBtn" onClick={() => context.openFilters()}>
              Filters
            </Button>
          )}
        </>
      )}

      <section className="listingPage">
        <div className="container-fluid">
          <div className="breadcrumb flex-column">
            <h1 className="text-capitalize">Products With Category {id}</h1>
            <ul className="list list-inline mb-0">
              <li className="list-inline-item">
                <Link to={'/'}>Home </Link>
              </li>
            </ul>
          </div>

          <div className="listingData">
            <div className="row">
              <div
                data-aos="fade-right"
                data-aos-duration="400"
                data-aos-delay="100"
                data-aos-once="true"
                className={`col-md-3 sidebarWrapper ${context.isOpenFilters === true && 'click'}`}
              >
                <Sidebar
                  data={products}
                  company={company}
                  minPrice={minPrice}
                  maxPrice={maxPrice}
                  filterByBrand={filterByBrand}
                  filterByPrice={filterByPrice}
                  filterByRating={filterByRating}
                />
              </div>

              <div className="col-md-9 rightContent homeProducts pt-0">
                <div className="topStrip d-flex align-items-center">
                  <div className="ml-auto d-flex align-items-center">
                    <div className="tab_ ml-3 position-relative" data-aos="fade-left" data-aos-duration="400" data-aos-delay="100">
                      <Button className="btn_" onClick={() => setisOpenDropDown2(!isOpenDropDown2)}>
                        <FilterListOutlinedIcon /> Sort by: Featured
                      </Button>
                      {isOpenDropDown2 !== false && (
                        <ul className="dropdownMenu">
                          <li>
                            <Button className="align-items-center" onClick={sortByPriceLowToHigh}>
                              Price: Low to High
                            </Button>
                          </li>
                          <li>
                            <Button className="align-items-center" onClick={sortByPriceHighToLow}>
                              Price: High to Low
                            </Button>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                </div>

                <div className="productRow pl-4 pr-3">
                  {loading ? (
                    <div className="d-flex justify-content-center align-items-center w-100">
                      <div className="loading-spinner"></div>
                    </div>
                  ) : products?.length !== 0 ? (
                    products?.slice(itemOffset, endOffset).map((item, index) => {
                      return (
                        <div
                          className="item"
                          data-aos="fade-up"
                          data-aos-duration="400"
                          data-aos-delay="100"
                          data-aos-once="true"
                          key={index}
                        >
                          <Product tag={item.category} item={item} />
                        </div>
                      );
                    })
                  ) : !loadProducts && products && products?.length === 0 ? (
                    <div className="d-flex justify-content-center align-items-center w-100">
                      <p>No products have been founded at this page yet!</p>
                    </div>
                  ) : (
                    <div className="d-flex flex-wrap gap-5 m-5 gx-5" style={{ marginRight: '15px' }}>
                      {Array.from({ length: 12 }).map((_, index) => (
                        <div
                          key={index}
                          className="productThumb sm-loading"
                          style={{ width: 'calc(25% - 16px)', marginRight: '15px', marginBottom: '20px' }}
                        >
                          <div className="imgWrapper">
                            <div className="p-4 wrapper mb-3 sm-item-primary">
                              <Skeleton height={200} />
                            </div>
                          </div>

                          <div className="info">
                            <span className="d-block mt-3 catName sm-item-secondary">
                              <Skeleton height={20} />
                            </span>
                            <span className="d-block mt-3 catName sm-item-secondary">
                              <Skeleton height={20} />
                            </span>
                            <div className="d-flex align-items-center mt-3">
                              <Skeleton width={150} height={20} />
                            </div>
                            <div className="d-flex align-items-center mt-3">
                              <Skeleton width={300} height={20} />
                            </div>
                            {/* <span className="d-block mt-3 catName sm-item-secondary">
                          <Skeleton height={20} />
                        </span> */}
                            {/* <span className="d-block mt-4 py-2 catName sm-item-secondary">
                          <Skeleton height={20} />
                        </span> */}
                            <div className="d-flex align-items-center mt-3">
                              <Skeleton width={180} height={20} />
                            </div>
                            <span className="d-block p-3 my-3 catName sm-item-secondary">
                              <Skeleton height={20} />
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>

                {!loading && (
                  <div className="pagination justify-end">
                    <PaginatedItem pageCount={pageCount} handlePageClick={handlePageClick}></PaginatedItem>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductCategory;
