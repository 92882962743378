import { createSlice } from '@reduxjs/toolkit';

const loadingSlice = createSlice({
  name: 'loading',
  initialState: {
    error_message: '',
    loading: true,
    isNotCircular: true
  },
  reducers: {
    loadingUpdate: (state, action) => {
      state.error_message = action.payload;
      state.loading = action.payload;
      state.isNotCircular = action.payload;
    }
  }
});

export const { loadingUpdate } = loadingSlice.actions;

export default loadingSlice.reducer;
