import React from 'react';
import Loader from '../assets/images/loading.gif';

const ImageLoader = ({ isLoading }) => {
  if (!isLoading) {
    return null;
  }

  return (
    <div className="loader">
      <img alt="Loading..." src={Loader} />
    </div>
  );
};

export default ImageLoader;
