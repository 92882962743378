/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Button, Rating } from '@mui/material';
import numeral from 'numeral';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Axios from '../../../lib/axios';
import { handleErrors } from '../../../lib/handleErrors';
import { withRouter } from '../../../lib/withRouter';
import { loadingUpdate } from '../../../redux/loadingSlice';
import { toast } from 'react-toastify';

class Wishlist extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    if (!this.props.loading && !this.props.user) {
      this.props.router.navigate('/account/email/signin');
    } else {
      this.initializeData();
    }
  }

  async initializeData() {
    this.props.loadingUpdate(true);
    try {
      const responseWishlists = await Axios().get(`/api/client/wishlist/user/${this.props.user.id}`);
      this.setState({
        wishlists: responseWishlists.data.data
      });
    } catch (e) {
      handleErrors(e);
    } finally {
      this.props.loadingUpdate(false);
    }
  }

  onCartStore = async (productId) => {
    try {
      this.props.loadingUpdate(true);
      if (!this.props.user.id) {
        this.props.router.navigate('/account/email/signin');
      } else {
        let response = await Axios().post(`/api/client/cart/store`, {
          user_id: this.props.user.id,
          product_id: productId,
          stock: 1
        });
        if (response && response?.data?.errors?.length != 0) {
          const error_message = response?.data?.errors;
          toast.error(error_message);
        } else {
          this.props.router.navigate('/cart');
        }
      }
    } catch (error) {
      handleErrors(error);
    } finally {
      this.props.loadingUpdate(false);
    }
  };

  onWishlistRemove = async (wishId) => {
    try {
      this.props.loadingUpdate(true);
      if (!this.props.user.id) {
        this.props.router.navigate('/account/email/signin');
      } else {
        let response = await Axios().post(`/api/client/wishlist/delete/${wishId}`);
        if (response.data.errors.length != 0) {
          const error_message = response.data.errors;
          toast.error(error_message);
        } else {
          window.location.reload();
        }
      }
    } catch (error) {
      handleErrors(error);
    } finally {
      this.props.loadingUpdate(false);
    }
  };

  render() {
    return (
      <>
        <div className="breadcrumbWrapper mb-4">
          <div className="container-fluid">
            <ul className="breadcrumb breadcrumb2 mb-0">
              <li>
                <Link to={'/'}>Home</Link>
              </li>
              <li>Wishlist</li>
            </ul>
          </div>
        </div>
        <section className="wishlistSection mb-5">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card p-4">
                  <div className="d-flex align-items-center w-100">
                    <div className="left">
                      <h1 className="hd mb-0">Your Wishlists</h1>
                      <p>
                        There are <span className="text-g">{this.state?.wishlists?.length ?? 0}</span> products in Your Wishlists
                      </p>
                    </div>
                  </div>

                  <div className="cartWrapper mt-4">
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Product Name</th>
                            <th>Company Name</th>
                            <th>Unit Price</th>
                            <th>Stock Status</th>
                            <th>Actions</th>
                            <th style={{ textAlign: 'center' }}>Remove</th>
                          </tr>
                        </thead>
                        {this.state.wishlists && this.state?.wishlists?.length > 0 ? (
                          this.state.wishlists.map((value, index) => {
                            return (
                              <tbody key={index}>
                                <tr>
                                  <td width={'50%'}>
                                    <div className="d-flex align-items-center">
                                      <div className="img">
                                        <Link to={`/product/${value.product.id}`}>
                                          <img
                                            alt={`Wishlist ${index}`}
                                            src={
                                              `${value.product.product_image_product[0].product_image.image_url}` + '?im=Resize=(100,100)'
                                            }
                                            className="w-100"
                                          />
                                        </Link>
                                      </div>

                                      <div className="info pl-4">
                                        <Link to={`/product/${value.product.id}`}>
                                          <h4>{value.product.name}</h4>
                                        </Link>
                                        <Rating name="half-rating-read" value={5} precision={0.5} readOnly />{' '}
                                        <span className="text-light">(5)</span>
                                      </div>
                                    </div>
                                  </td>

                                  <td width="10%">
                                    <span className="text-g">{value?.product?.company?.name ?? '-'}</span>
                                  </td>

                                  <td width="20%">
                                    <span className="text-g">Rp. {numeral(value.product.price).format('0,0')}</span>
                                  </td>

                                  <td>
                                    {value.total_stock < 0 ? (
                                      <span className="text-danger">Out Of Stock</span>
                                    ) : (
                                      <span className="text-success">In Stock</span>
                                    )}
                                  </td>

                                  <td>
                                    {value.product.is_added ? (
                                      <Button className="btn-g btn-lg" onClick={() => alert('This product is already added!')}>
                                        Added
                                      </Button>
                                    ) : (
                                      <Button className="btn-g btn-lg" onClick={() => this.onCartStore(value.product.id)}>
                                        Add To Cart
                                      </Button>
                                    )}
                                  </td>

                                  <td align="center">
                                    <span className="cursor" onClick={() => this.onWishlistRemove(value.id)} style={{ fontSize: '48px' }}>
                                      <DeleteOutlineOutlinedIcon fontSize="48" />
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            );
                          })
                        ) : (
                          <div className="py-4 pl-0">
                            <div className="d-flex align-items-center w-100">
                              <div className="left">
                                <h1 className="hd mb-0">No products founded in wishlist yet!</h1>
                              </div>
                            </div>
                          </div>
                        )}
                      </table>
                    </div>
                  </div>

                  <br />
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-center mt-5">
                <Link to="/">
                  <Button className="btn-g">
                    <KeyboardBackspaceIcon /> <span className="ml-3">Continue Shopping</span>
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.loading.loading,
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  loadingUpdate: (isLoading) => dispatch(loadingUpdate(isLoading))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Wishlist));
