import { Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import { ReactComponent as IconKey } from 'bootstrap-icons/icons/key.svg';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import Axios from '../../lib/axios';
import { handleErrors } from '../../lib/handleErrors';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

const ChangePasswordForm = () => {
  const {
    register,
    reset,
    formState: { errors, isSubmitting, isValid },
    handleSubmit
  } = useForm({ mode: 'onChange' });
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

  const onSubmit = async (data) => {
    try {
      if ('oldPassword' in data && 'newPassword' in data && 'confirmNewPassword' in data) {
        const passwordChangeResponse = await Axios().post(`/api/client/user/change-password`, {
          oldPassword: data.oldPassword,
          newPassword: data.newPassword,
          confirmNewPassword: data.confirmNewPassword
        });

        toast.success(passwordChangeResponse.data.message);
      }
      reset();
      window.location.reload();
    } catch (error) {
      handleErrors(error);
    }
  };

  const handleClickShowOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleClickShowConfirmNewPassword = () => {
    setShowConfirmNewPassword(!showConfirmNewPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className="card border-info">
      <h6 className="card-header bg-info text-white">
        <IconKey /> Change Password
      </h6>
      <div className="card-body">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              id="oldPassword"
              label="Old Password"
              type={showOldPassword === false ? 'password' : 'text'}
              fullWidth
              variant="outlined"
              {...register('oldPassword', {
                required: { value: true, message: 'Old Password is required' }
              })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle new password visibility"
                      onClick={handleClickShowOldPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="large"
                    >
                      {showOldPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(errors.oldPassword)}
              helperText={errors.oldPassword && errors.oldPassword.message}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              id="newPassword"
              label="New Password"
              type={showNewPassword === false ? 'password' : 'text'}
              fullWidth
              variant="outlined"
              {...register('newPassword', {
                required: { value: true, message: 'New Password is required' }
              })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle new password visibility"
                      onClick={handleClickShowNewPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="large"
                    >
                      {showNewPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(errors.newPassword)}
              helperText={errors.newPassword && errors.newPassword.message}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              id="confirmNewPassword"
              label="Confirm New Password"
              type={showConfirmNewPassword === false ? 'password' : 'text'}
              fullWidth
              variant="outlined"
              {...register('confirmNewPassword', {
                required: { value: true, message: 'Confirm New Password is required' }
              })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle new password visibility"
                      onClick={handleClickShowConfirmNewPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="large"
                    >
                      {showConfirmNewPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(errors.confirmNewPassword)}
              helperText={errors.confirmNewPassword && errors.confirmNewPassword.message}
            />
          </Grid>
          <button type="submit" className="btn btn-info mt-3 d-flex" disabled={!isValid || isSubmitting}>
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default compose(
  reduxForm({
    form: 'changepassword'
  })
)(ChangePasswordForm);
