/* eslint-disable no-unused-vars */
import { faEye, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import 'dayjs/locale/id';
import relativeTime from 'dayjs/plugin/relativeTime';
import $ from 'jquery';
import Axios from '../../lib/axios';
import { Component, Fragment } from 'react';
import { Button, Card, Table } from 'react-bootstrap';
import { createRoot } from 'react-dom/client';
import { connect } from 'react-redux';
import { withRouter } from '../../lib/withRouter';
import { handleErrors } from '../../lib/handleErrors';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

class NotificationAll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0
    };
  }

  componentDidMount() {
    $(this.table).DataTable({
      processing: true,
      serverside: true,
      responsive: true,
      bDestroy: true,
      stateSave: true,
      stateSaveCallback: function (settings, data) {
        localStorage.setItem('DataTables_' + window.location.pathname, JSON.stringify(data));
      },
      stateLoadCallback: function (settings) {
        return JSON.parse(localStorage.getItem('DataTables_' + window.location.pathname));
      },
      ajax: {
        url: `${process.env.REACT_APP_BACKEND_URL}/api/client/notification`,
        type: 'GET',
        headers: {
          'X-Requested-With': 'XMLHttpRequest'
        },
        xhrFields: {
          withCredentials: true
        }
      },
      columns: [
        {
          data: null,
          render: function (data, type, row, meta) {
            return meta.row + meta.settings._iDisplayStart + 1;
          },
          orderable: false,
          searchable: false
        },
        {
          data: 'id',
          searchable: true,
          orderable: false
        },
        {
          data: 'data.data',
          searchable: true,
          orderable: false
        },
        {
          data: 'read_at',
          searchable: true,
          orderable: false,
          render: function (data) {
            return data ? 'YES' : 'NO';
          }
        },
        {
          data: 'created_at',
          searchable: true,
          orderable: false,
          render: function (data) {
            dayjs.extend(relativeTime);
            const timeAgo = dayjs(data).fromNow();
            return timeAgo;
          }
        },
        {
          data: null,
          orderable: false,
          searchable: false,
          width: '20%'
        }
      ],
      columnDefs: [
        {
          targets: 5,
          createdCell: (td, cellData, rowData) => {
            const root = createRoot(td);
            root.render(
              <Fragment>
                {!rowData['read_at'] && (
                  <Button
                    style={{
                      backgroundColor: '#3BB77E',
                      color: 'white',
                      borderColor: '#3BB77E'
                    }}
                    className="btn ms-2 mx-auto"
                    onClick={(e) => this.handleMarkAsRead(rowData['id'], e)}
                  >
                    <FontAwesomeIcon icon={faEye} style={{ height: '15px', color: '#ffffff' }} />
                  </Button>
                )}
              </Fragment>
            );
          }
        }
      ]
    });
  }

  handleMarkAsRead = async (id, e) => {
    try {
      e.target.disabled = true;
      const res = await Axios().post(`/api/client/notification/${id}/mark-as-read`);
      toast.success(res.data.message);
      $(this.table).DataTable().ajax.reload(null, false);
    } catch (error) {
      handleErrors(error);
    } finally {
      e.target.disabled = false;
    }
  };

  handleMarkAllAsRead = async (e) => {
    e.target.disabled = true;
    let isExecuted = confirm(`Are you sure to read all notifications?`);
    if (isExecuted == true) {
      try {
        const res = await Axios().post(`/api/client/notification/mark-all-as-read`);
        toast.success(res.data.message);
        $(this.table).DataTable().ajax.reload(null, false);
      } catch (error) {
        handleErrors(error);
      }
    }
    e.target.disabled = false;
  };

  render() {
    return (
      <Fragment>
        <div className="breadcrumbWrapper mb-4">
          <div className="container-fluid">
            <ul className="breadcrumb breadcrumb2 mb-0">
              <li>
                <Link to={'/'}>Home</Link>
              </li>
              <li>Notifications</li>
            </ul>
          </div>
        </div>
        <div className="d-flex justify-content-center my-5 mx-5 px-5">
          <Card style={{ maxWidth: '100%' }}>
            <Card.Header className="d-flex justify-content-between">
              <p className="mt-auto mb-auto">Riwayat Notifikasi</p>
              <div className="d-flex align-items-center">
                <Button
                  className="text-dark"
                  onClick={() => {
                    $(this.table).DataTable().ajax.reload(null, false);
                    toast.success('Notifikasi Sudah Diupdate Terbaru!');
                  }}
                  style={{ border: 'none', background: 'transparent' }}
                >
                  <FontAwesomeIcon icon={faRefresh} className="me-2" />
                  <p className="d-inline">Refresh</p>
                </Button>
                <Button className="btn ms-3 btn-success" onClick={(e) => this.handleMarkAllAsRead(e)} style={{ color: 'white' }}>
                  Mark All As Read
                </Button>
              </div>
            </Card.Header>
            <Card.Body>
              <Table style={{ whiteSpace: 'nowrap' }} responsive hover ref={(table) => (this.table = table)}>
                <thead>
                  <tr>
                    <td>No</td>
                    <td>ID Message</td>
                    <td>Body</td>
                    <td>Have Read</td>
                    <td>Detail Date</td>
                    <td>Action</td>
                  </tr>
                </thead>
              </Table>
            </Card.Body>
          </Card>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.loading.loading
});

const mapDispatchToProps = (dispatch) => ({
  loadingUpdate: (isLoading) => dispatch(loadingUpdate(isLoading))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NotificationAll));
