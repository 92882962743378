/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import Banner1 from '../../assets/images/banner1.jpg';
import Banner2 from '../../assets/images/banner2.jpg';
import Banner3 from '../../assets/images/banner3.jpg';

import './style.css';
import { useNavigate } from 'react-router-dom';

const Banners = () => {
  const navigate = useNavigate();
  return (
    <div className="bannerSection">
      <div className="container-fluid">
        <div className="row">
          <div data-aos="fade-up" data-aos-duration="400" data-aos-delay="100" data-aos-once="true" className="col">
            <div className="box">
              <img
                style={{ cursor: 'pointer' }}
                onClick={() => navigate('/products')}
                src={Banner1}
                alt="Banner1"
                className="w-100 transition"
              />
            </div>
          </div>

          <div data-aos="fade-up" data-aos-duration="400" data-aos-delay="200" data-aos-once="true" className="col">
            <div className="box">
              <img
                style={{ cursor: 'pointer' }}
                onClick={() => navigate('/products')}
                src={Banner2}
                alt="Banner2"
                className="w-100 transition"
              />
            </div>
          </div>

          <div data-aos="fade-up" data-aos-duration="400" data-aos-delay="300" data-aos-once="true" className="col">
            <div className="box">
              <img
                style={{ cursor: 'pointer' }}
                onClick={() => navigate('/products')}
                src={Banner3}
                alt="Banner3"
                className="w-100 transition"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banners;
