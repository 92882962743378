import React from 'react';
import { useMemo } from 'react';

export default function OtpInput({ value, valueLength, onChange }) {
  const ONLY_DIGIT = /^\d+$/;
  const valueItems = useMemo(() => {
    const valueArray = value.split('');
    const otp = [];

    for (let i = 0; i < valueLength; i++) {
      const digit = valueArray[i];

      if (ONLY_DIGIT.test(digit)) {
        otp.push(digit);
      } else {
        otp.push('');
      }
    }

    return otp;
  }, [value, valueLength, ONLY_DIGIT]);

  const inputDigit = (e, num) => {
    const data = e.target;
    let dataValue = data.value.trim();
    const isDigit = ONLY_DIGIT.test(dataValue);

    if (!isDigit && dataValue !== '') {
      return;
    }

    if (dataValue.length > 1) {
      return;
    }

    dataValue = isDigit ? dataValue : '';

    const newValue = value.substring(0, num) + dataValue + value.substring(num + 1);

    onChange(newValue);

    if (!isDigit) {
      return;
    }

    const nextDigit = data.nextElementSibling;

    if (nextDigit) {
      nextDigit.focus();
    }
  };

  const inputOnKey = (e) => {
    const data = e.target;

    if (e.key !== 'Backspace' || data.value !== '') {
      return;
    }

    const previousDigit = data.previousElementSibling;

    if (previousDigit) {
      previousDigit.focus();
    }
  };

  return (
    <div className="otp-container">
      {valueItems.map((data, index) => (
        <input
          key={index}
          type="text"
          inputMode="numeric"
          autoComplete="one-time-code"
          pattern="\d{1}"
          maxLength={valueLength}
          onKeyDown={inputOnKey}
          onChange={(e) => inputDigit(e, index)}
          className={'otp-input'}
          value={data}
        />
      ))}
    </div>
  );
}
