export const axiosFormDataConfig = {
  baseURL: process.env.REACT_APP_BACKEND_URL,
  withCredentials: true,
  withXSRFToken: true,
  // xsrfHeaderName: 'X-XSRF-TOKEN',
  headers: {
    'Content-Type': 'multipart/form-data',
    'X-Requested-With': 'XMLHttpRequest',
    Accept: 'application/json'
  }
};
