import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { ImPaste } from 'react-icons/im';
import { useDispatch } from 'react-redux';
import { useAuth } from '../../../hooks/useAuth';
import { loadingUpdate } from '../../../redux/loadingSlice';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { handleErrors } from '../../../lib/handleErrors';
import SweetAlert2 from 'react-sweetalert2';
import Axios from '../../../lib/axios';
import parse from 'html-react-parser';
import moment from 'moment';

const PromoDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [promo, setPromo] = useState();
  const { user } = useAuth({
    middleware: 'auth'
  });
  const dispatch = useDispatch();
  const location = useLocation();
  const [swalProps, setSwalProps] = useState({});
  const { type } = location.state;

  const copyFunction = () => {
    try {
      var copyText = document.getElementById('couponInput');
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(copyText.value);
      setSwalProps({
        show: true,
        title: 'Copy Promo Success',
        text: 'Success copied ' + copyText.value,
        icon: 'success'
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (!id) {
      return;
    }
    async function fetchData(id) {
      dispatch(loadingUpdate(true));
      try {
        const promoRes = await Axios().post(`/api/client/promo/${id}`, {
          type: type
        });
        setPromo(promoRes.data.data);
        dispatch(loadingUpdate(false));
      } catch (errors) {
        const error_message = errors.response.data.errors;
        handleErrors(error_message);
        navigate('/promo');
      }
    }
    fetchData(id);
  }, [id, dispatch, user, type]);

  return (
    <section className="promoDetail">
      <Container className="my-5">
        <h1 id="title">Promo</h1>
        <div className=" d-flex justify-content-center mb-5 mt-4">
          <img
            style={{ borderRadius: '10px' }}
            src={
              promo && promo.type == 'global'
                ? `${process.env.REACT_APP_BACKEND_URL}/storage/images/globalPromo/${promo && promo.image}`
                : `${process.env.REACT_APP_BACKEND_URL}/storage/images/promo/${promo && promo.image}`
            }
            alt="Promo"
          />
        </div>
        <div className="card detail-promo-content shadow">
          <h3 className="card-heading text-center fw-bold">Syarat Dan Ketentuan</h3>
          <span className="card-p fw-bold">{promo && parse(promo.description)}</span>
          <h1 className="codePromo">Kode Promo</h1>
          <div className="d-flex justify-content-between">
            <input
              style={{ border: 'none' }}
              disabled
              className="uniqueCode mb-2"
              type="text"
              value={promo ? promo.name : ''}
              id="couponInput"
            />
            <ImPaste onClick={() => copyFunction()} style={{ cursor: 'pointer', marginRight: '16px', color: '#9FA0A2' }} />
          </div>
        </div>
        <div className="footerDesc d-flex align-items-center">
          Berlaku Hingga : {promo && moment(promo.start_date).format('DD/MM/YYYY')} - {promo && moment(promo.end_date).format('DD/MM/YYYY')}
        </div>
      </Container>
      <SweetAlert2
        {...swalProps}
        didClose={() => {
          setSwalProps({
            show: false
          });
        }}
      />
    </section>
  );
};

export default PromoDetail;
