/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { Button } from '@mui/material';
import Rating from '@mui/material/Rating';
import parse from 'html-react-parser';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Slider from 'react-slick';

import { Favorite } from '@mui/icons-material';
// import axios from 'axios';
import numeral from 'numeral';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { MyContext } from '../../App';
import Product from '../../components/product';
import Axios from '../../lib/axios';
import { handleErrors } from '../../lib/handleErrors';
import { loadingUpdate } from '../../redux/loadingSlice';
import CarouselImageProduct from '../../components/product/carousel';
import LoadingSpinner from '../../components/LoadingSpinner';

const DetailsPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loadCircle, setLoadCircle] = useState(false);
  const user = useSelector((state) => state.user);
  const [products, setProducts] = useState([]);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [activeTabs, setActiveTabs] = useState(0);
  const context = useContext(MyContext);
  // const [rating, setRating] = useState(0.0);
  // const [reviewFields, setReviewFields] = useState({
  //   review: '',
  //   userName: '',
  //   rating: 0.0,
  //   productId: 0,
  //   date: ''
  // });

  var related = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    fade: false,
    arrows: context.windowWidth > 992 ? true : false
  };

  const onWishlistStore = async (productId) => {
    try {
      setLoadCircle(true);
      if (!user) {
        navigate('/account/email/signin');
      } else {
        let response = await Axios().post(`/api/client/wishlist/store`, {
          user_id: user.id,
          product_id: productId
        });
        if (response.data.errors.length != 0) {
          const error_message = response.data.errors;
          toast.error(error_message);
        } else {
          navigate('/account/wishlist');
        }
      }
    } catch (error) {
      handleErrors(error);
      setLoadCircle(false);
    } finally {
      setLoadCircle(false);
    }
  };

  const onCartStore = async (productId) => {
    try {
      setLoadCircle(true);
      if (!user) {
        navigate('/account/email/signin');
      } else {
        let response = await Axios().post(`/api/client/cart/store`, {
          user_id: user.id,
          product_id: productId,
          stock: 1
        });
        if (response.data.errors.length != 0) {
          const error_message = response.data.errors;
          toast.error(error_message);
        } else {
          navigate('/cart');
        }
      }
    } catch (error) {
      handleErrors(error);
      setLoadCircle(false);
    } finally {
      setLoadCircle(false);
    }
  };

  // const changeInput = (name, value) => {
  //   if (name === 'rating') {
  //     setRating(value);
  //   }
  //   setReviewFields(() => ({
  //     ...reviewFields,
  //     [name]: value,
  //     productId: id,
  //     date: new Date().toLocaleString()
  //   }));
  // };

  // const reviews_Arr = [];

  // const submitReview = async (e) => {
  //   e.preventDefault();

  //   try {
  //     await axios.post('http://localhost:5000/productReviews', reviewFields).then((response) => {
  //       reviews_Arr.push(response.data);
  //       setReviewFields(() => ({
  //         review: '',
  //         userName: '',
  //         rating: 0.0,
  //         productId: 0,
  //         date: ''
  //       }));
  //     });
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // };

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      try {
        dispatch(loadingUpdate(true));

        const responseDetailProduct = await Axios().get(`/api/client/product/detail/${id}`);
        const responseFeaturedProduct = await Axios().get(`/api/client/product/random/${id}`);

        setProducts(responseDetailProduct.data.data);
        setRelatedProducts(responseFeaturedProduct.data.data);
      } catch (e) {
        handleErrors(e);
      } finally {
        dispatch(loadingUpdate(false));
      }
    };

    fetchData();
  }, [id]);

  return loadCircle ? (
    <LoadingSpinner isLoading={loadCircle} />
  ) : (
    products && relatedProducts && (
      <>
        {context.windowWidth < 992 && (
          <Button
            className={`btn-g btn-lg w-100 filterBtn {isAlreadyAddedInCart===true && 'no-click'}`}
            onClick={() => {
              if (products.detail && products.detail.is_added === false) {
                onCartStore(products.detail.productId);
              } else {
                navigate('/account/cart');
              }
            }}
          >
            <ShoppingCartOutlinedIcon />
            {products.detail && products.detail.is_added === true ? 'Added' : 'Add To Cart'}
          </Button>
        )}

        <section className="detailsPage mb-5">
          {context.windowWidth > 992 && (
            <div className="breadcrumbWrapper mb-4">
              <div className="container-fluid">
                <ul className="breadcrumb breadcrumb2 mb-0">
                  <li>
                    <Link>Home</Link>{' '}
                  </li>
                  <li>{products.detail && products.detail.productName}</li>
                </ul>
              </div>
            </div>
          )}

          <div className="container detailsContainer pt-3 pb-3">
            <div className="row">
              {/* productZoom code start here */}
              <div className="col-md-5">
                <CarouselImageProduct imageProduct={products && products.productImage} />
              </div>
              {/* productZoom code ends here */}

              {/* product info code start here */}
              <div className="col-md-7 productInfo">
                <h1>{products.detail && products.detail.productName}</h1>
                <div className="d-flex align-items-center mb-4 mt-3">
                  <Rating
                    name="half-rating-read"
                    value={parseFloat(products.detail && products.detail.averageRating !== null ? products.detail.averageRating : 5)}
                    precision={0.5}
                    readOnly
                  />
                  <span className="text-light ml-2">({products.productReviews && products.productReviews.length} reviews)</span>
                </div>

                <div className="priceSec d-flex align-items-center mb-3">
                  <span className="text-g priceLarge">
                    Rp {numeral(products && products.detail && products.detail.price).format('0,0')}
                  </span>
                </div>

                {products.detail && products.detail.description && <p>{parse(products.detail.description)}</p>}

                <div className="d-flex align-items-center mt-5">
                  <div className="d-flex align-items-center">
                    {context.windowWidth > 992 && (
                      <Button
                        className={`btn-g btn-lg addtocartbtn ${products.detail && products.detail.is_added === true && 'no-click'}`}
                        onClick={() => {
                          if (products.detail && products.detail.is_added === false) {
                            onCartStore(products.detail.productId);
                          } else {
                            navigate('/account/cart');
                          }
                        }}
                      >
                        <ShoppingCartOutlinedIcon />
                        {products.detail && products.detail.is_added === true ? 'Added' : 'Add To Cart'}
                      </Button>
                    )}
                    <Button
                      onClick={() => {
                        if (products.detail && products.detail.is_wishlist === false) {
                          onWishlistStore(products.detail.productId);
                        } else {
                          navigate('/account/wishlist');
                        }
                      }}
                      className=" btn-lg addtocartbtn  ml-3  wishlist btn-border"
                    >
                      {products.detail && products.detail.is_wishlist === true ? (
                        <Favorite style={{ color: 'red' }} />
                      ) : (
                        <FavoriteBorderOutlinedIcon />
                      )}
                    </Button>
                  </div>
                </div>
              </div>
              {/* product info code ends here */}
            </div>

            <div className="card mt-5 p-5 detailsPageTabs">
              <div className="customTabs">
                <ul className="list list-inline">
                  <li className="list-inline-item">
                    <Button
                      className={`${activeTabs === 0 && 'active'}`}
                      onClick={() => {
                        setActiveTabs(0);
                      }}
                    >
                      Description
                    </Button>
                  </li>
                  <li className="list-inline-item">
                    <Button
                      className={`${activeTabs === 1 && 'active'}`}
                      onClick={() => {
                        setActiveTabs(1);
                      }}
                    >
                      Additional info
                    </Button>
                  </li>
                  <li className="list-inline-item">
                    <Button
                      className={`${activeTabs === 2 && 'active'}`}
                      onClick={() => {
                        setActiveTabs(2);
                      }}
                    >
                      Reviews ({products?.productReviews && products?.productReviews?.length})
                    </Button>
                  </li>
                </ul>

                <br />

                {activeTabs === 0 && (
                  <div className="tabContent">
                    {products.detail && products.detail.description && <p>{parse(products.detail.description)}</p>}
                  </div>
                )}

                {activeTabs === 1 && (
                  <div className="tabContent">
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <tbody>
                          <tr>
                            <th>Width</th>
                            <td>
                              <p>{products && products.detail.width} mm</p>
                            </td>
                          </tr>
                          <tr>
                            <th>Height</th>
                            <td>
                              <p>{products && products.detail.height} mm</p>
                            </td>
                          </tr>
                          <tr>
                            <th>Weight</th>
                            <td>
                              <p>{products && products.detail.weight} gr</p>
                            </td>
                          </tr>
                          <tr>
                            <th>Length</th>
                            <td>
                              <p>{products && products.detail.length} mm</p>
                            </td>
                          </tr>
                          <tr className="frame">
                            <th>Stock</th>
                            <td>
                              <p>{products && products.detail.stock}</p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}

                {activeTabs === 2 && (
                  <div className="tabContent">
                    <div className="row">
                      <div className="col-md-8">
                        <h3>Customer questions & answers</h3>
                        <br />

                        {products.productReviews &&
                          products.productReviews.length !== 0 &&
                          products.productReviews.map((item, index) => {
                            return (
                              <div className="card p-4 reviewsCard flex-row" key={index}>
                                <div className="image">
                                  <div className="rounded-circle">
                                    <img src={`https://ui-avatars.com/api/?name=${item.user.username}`} />
                                  </div>
                                  <span className="text-g d-block text-center font-weight-bold">{item.user.username}</span>
                                </div>

                                <div className="info pl-5">
                                  <div className="d-flex align-items-center w-100">
                                    <h5 className="text-light">{moment(item.created_at).format('DD MMMM YYYY h:mm A')}</h5>
                                    <div className="ml-auto">
                                      <Rating name="half-rating-read" value={parseFloat(item.rating)} precision={0.5} readOnly />
                                    </div>
                                  </div>

                                  <p>{item.content} </p>
                                </div>
                              </div>
                            );
                          })}

                        <br className="res-hide" />

                        <br className="res-hide" />

                        {/* <form className="reviewForm" onSubmit={submitReview}>
                          <h4>Add a review</h4> <br />
                          <div className="form-group">
                            <textarea
                              className="form-control"
                              placeholder="Write a Review"
                              name="review"
                              value={reviewFields.review}
                              onChange={(e) => changeInput(e.target.name, e.target.value)}
                            ></textarea>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <input
                                  type="text"
                                  value={reviewFields.userName}
                                  className="form-control"
                                  placeholder="Name"
                                  name="userName"
                                  onChange={(e) => changeInput(e.target.name, e.target.value)}
                                />
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <Rating
                                  name="rating"
                                  value={rating}
                                  precision={0.5}
                                  onChange={(e) => changeInput(e.target.name, e.target.value)}
                                />
                              </div>
                            </div>
                          </div>
                          <br />
                          <div className="form-group">
                            <Button type="submit" className="btn-g btn-lg">
                              Submit Review
                            </Button>
                          </div>
                        </form> */}
                      </div>

                      <div className="col-md-4 pl-5 reviewBox">
                        <h4>Customer reviews</h4>

                        {/* <div className="d-flex align-items-center mt-2">
                          <Rating
                            name="half-rating-read"
                            defaultValue={products && products?.detail?.averageRating == null ? 5 : products?.detail?.averageRating}
                            value={3}
                            precision={0.5}
                            readOnly
                          />
                          <strong className="ml-3">
                            {products && products?.detail?.averageRating == null ? 5 : products?.detail?.averageRating} out of 5
                          </strong>
                        </div> */}

                        <br />

                        {Object.keys(products.starRatings)
                          .reverse()
                          .map((star, index) => (
                            <div key={index} className="progressBarBox d-flex align-items-center">
                              <span className="mr-3">{star} star</span>
                              <div className="progress" style={{ width: '85%', height: '20px' }}>
                                <div
                                  className="progress-bar bg-success"
                                  style={{ width: `${products.starRatings[star]}%`, height: '20px' }}
                                >
                                  {`${products.starRatings[star]}%`}
                                </div>
                              </div>
                            </div>
                          ))}

                        {/* <div className="progressBarBox d-flex align-items-center">
                          <span className="mr-3">5 star</span>
                          <div className="progress" style={{ width: '85%', height: '20px' }}>
                            <div className="progress-bar bg-success" style={{ width: '75%', height: '20px' }}>
                              75%
                            </div>
                          </div>
                        </div>

                        <div className="progressBarBox d-flex align-items-center">
                          <span className="mr-3">4 star</span>
                          <div className="progress" style={{ width: '85%', height: '20px' }}>
                            <div className="progress-bar bg-success" style={{ width: '50%', height: '20px' }}>
                              50%
                            </div>
                          </div>
                        </div>

                        <div className="progressBarBox d-flex align-items-center">
                          <span className="mr-3">3 star</span>
                          <div className="progress" style={{ width: '85%', height: '20px' }}>
                            <div className="progress-bar bg-success" style={{ width: '55%', height: '20px' }}>
                              55%
                            </div>
                          </div>
                        </div>

                        <div className="progressBarBox d-flex align-items-center">
                          <span className="mr-3">2 star</span>
                          <div className="progress" style={{ width: '85%', height: '20px' }}>
                            <div className="progress-bar bg-success" style={{ width: '35%', height: '20px' }}>
                              35%
                            </div>
                          </div>
                        </div>

                        <div className="progressBarBox d-flex align-items-center">
                          <span className="mr-3">1 star</span>
                          <div className="progress" style={{ width: '85%', height: '20px' }}>
                            <div className="progress-bar bg-success" style={{ width: '25%', height: '20px' }}>
                              25%
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <br />

            <div className="relatedProducts homeProductsRow2  pt-5 pb-4">
              <h2 className="hd mb-0 mt-0">Related products</h2>
              <br className="res-hide" />
              <Slider {...related} className="prodSlider">
                {relatedProducts.length !== 0 &&
                  relatedProducts.map((product, index) => {
                    return (
                      <div data-aos="zoom-in-up" data-aos-duration="400" data-aos-once="true" className="item" key={index}>
                        <Product tag={product.type} item={product} />
                      </div>
                    );
                  })}
              </Slider>
            </div>
          </div>
        </section>
      </>
    )
  );
};

export default DetailsPage;
