/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../hooks/useAuth';
import { loadingUpdate } from '../redux/loadingSlice';
import Pusher from 'pusher-js';
import Axios from '../lib/axios';
import { handleErrors } from '../lib/handleErrors';
import { setUser } from '../redux/userSlice';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import { MyContext } from '../App';
import ImageLoader from '../components/ImageLoader';
import LoadingSpinner from '../components/LoadingSpinner';

const DefaultLayout = ({ children }) => {
  const loading = useSelector((state) => {
    return state.loading;
  });
  const { user, isLoading } = useAuth({
    middleware: 'guest'
  });
  const windowWidth = window.innerWidth;
  const [isopenNavigation, setIsopenNavigation] = useState(false);
  const [isOpenFilters, setIsopenFilters] = useState(false);
  const dispatch = useDispatch();
  const [count, setCount] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await Axios().get('/api/client/cart/get/total_cart');
        setCount(res.data.data);
        if (!res.data.status) {
          dispatch(loadingUpdate(true));
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          dispatch(setUser(null));
        } else {
          handleErrors(error);
        }
      }
    };
    const loadPusher = (customer_id) => {
      const pusher = new Pusher(process.env.REACT_APP_BACKEND_URL, {
        cluster: process.env.REACT_APP_PUSHER_CLUSTER,
        encrypted: true
      });

      const channelCart = pusher.subscribe(`channel-cart-${customer_id}`);
      channelCart.bind('event-cart', (data) => {
        setCount(data.cart);
      });
    };
    if (user) {
      fetchData();
      loadPusher(user.id);
    }
  }, [dispatch, user]);

  useEffect(() => {
    dispatch(loadingUpdate(isLoading));
  }, [isLoading]);

  const openFilters = () => {
    setIsopenFilters(!isOpenFilters);
  };

  const value = {
    windowWidth,
    isOpenFilters,
    openFilters,
    isopenNavigation,
    setIsopenNavigation
  };

  return (
    <MyContext.Provider value={value}>
      <Header totalCount={count} />
      {loading.loading && loading.isNotCircular ? (
        <ImageLoader isLoading={loading.loading} />
      ) : (
        <LoadingSpinner isLoading={loading.loading} />
      )}
      {children}
      <Footer />
    </MyContext.Provider>
  );
};

export default DefaultLayout;
