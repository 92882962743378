import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import StarRating from './StarRating';

const RatingModal = ({ show, handleClose, handleSubmit }) => {
  const [rating, setRating] = useState(0);
  const [content, setContent] = useState('');

  const handleRatingChange = (selectedRating) => {
    setRating(selectedRating);
  };

  const handleContentChange = (event) => {
    setContent(event.target.value);
  };

  const handleFormSubmit = () => {
    handleSubmit({ rating, content });
    setRating(0);
    setContent('');
    handleClose();
  };

  return (
    <Modal
      backdrop="static"
      show={show}
      onExit={() => {
        setRating(0);
        setContent('');
      }}
      onHide={() => {
        setRating(0);
        setContent('');
        handleClose();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Isi Rating</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formRating">
            <Form.Label>Rating</Form.Label>
            <StarRating onRate={handleRatingChange} />
          </Form.Group>
          <Form.Group controlId="formContent" className="mt-3">
            <Form.Label>Review</Form.Label>
            <Form.Control as="textarea" rows={3} value={content} onChange={handleContentChange} />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleFormSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RatingModal;
