import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Container, Row, Col, Card, Button, Form, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { handleErrors } from '../../../lib/handleErrors';
import Axios from '../../../lib/axios';
import { toast } from 'react-toastify';
import { debounce } from 'lodash';
import AsyncSelect from 'react-select/async';
import Map from '../../../components/GoogleMap/Map';

const AccountAddAddress = () => {
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { errors, isSubmitting, isValid }
  } = useForm({ mode: 'onChange' });
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      let response = await Axios().post(`/api/client/profile/address`, data);
      toast.success(response.data.message);
      reset();
      navigate('/account/list-address');
    } catch (error) {
      handleErrors(error);
    }
  };

  const loadOptions = async (inputValue, callback) => {
    try {
      setLoading(true);
      const response = await Axios().post(`/api/client/biteship/map/single`, { name: inputValue });
      const optionLabels = response.data.data.areas.map((option) => ({ value: option.id, label: option.name }));
      callback(optionLabels);
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedLoadOptions = debounce(loadOptions, 1000);

  const handleInputChange = (value) => {
    debouncedLoadOptions(value, () => {});
  };

  // const handleMapChange = (newState) => {
  //   let jsonState = JSON.stringify(newState);
  //   let objectJsonState = JSON.parse(jsonState);
  //   reset({
  //     destination_latitude: objectJsonState.mapPosition.lat,
  //     destination_longitude: objectJsonState.mapPosition.lng,
  //     address: objectJsonState.fullpath
  //   });
  // };

  const handleMapChange = (newState) => {
    const currentValues = getValues();
    reset({
      ...currentValues,
      destination_latitude: newState.lat,
      destination_longitude: newState.lng,
      address: newState.fullpath
    });
  };

  return (
    <Container className="my-5">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="d-flex gap-2"></div>
      </div>

      <Row>
        <Col xs={12}>
          <Card>
            <Card.Header>Add Address</Card.Header>
            <Card.Body>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group className="mb-3" controlId="areaName">
                  <Form.Label>Address</Form.Label>
                  <Map
                    {...register('address', {
                      required: true
                    })}
                    center={{ lat: -0.789275, lng: 113.921327 }}
                    height="300px"
                    zoom={15}
                    onMarkerChange={handleMapChange}
                  />
                </Form.Group>
                {errors.address && (
                  <Alert variant="danger" className="p-2">
                    {errors.address.message}
                  </Alert>
                )}
                <Form.Group className="mb-3" controlId="areaName">
                  <Form.Label>Area Selection</Form.Label>
                  <AsyncSelect
                    isDisabled={isSubmitting}
                    placeholder="Enter Area Name"
                    id="areaSelection"
                    onInputChange={handleInputChange}
                    onChange={(selectedOption) => {
                      setValue('destination_area_id', selectedOption ? selectedOption.value : null);
                      setValue('destination_area_name', selectedOption ? selectedOption.label : null);
                    }}
                    isLoading={loading}
                    loadOptions={debouncedLoadOptions}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    name="description"
                    disabled={isSubmitting}
                    {...register('description', {
                      required: { value: true, message: 'Description is required' }
                    })}
                    type="text"
                    placeholder="Enter Description"
                  />
                </Form.Group>
                {errors.description && (
                  <Alert variant="danger" className="p-2">
                    {errors.description.message}
                  </Alert>
                )}
                <div className="mt-3 d-flex">
                  <Button disabled={!isValid || isSubmitting} type="submit" variant="success">
                    Add Address
                  </Button>
                  <Button className="mx-3" variant="secondary" onClick={() => navigate('/account/list-address')}>
                    Back
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AccountAddAddress;
