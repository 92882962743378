/* eslint-disable react-hooks/exhaustive-deps */
import Axios from '../lib/axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../redux/userSlice';
import firebase from '../firebase/config';
import { loadingUpdate } from '../redux/loadingSlice';
import { setAddress } from '../redux/addressSlice';

export const useAuth = ({ middleware, redirectIfAuthenticated }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const publicRoute = [
    '/',
    '/account/email/signin',
    '/account/phone/signin',
    '/account/phone/verify-otp',
    '/account/email/verify-otp',
    '/account/email/signup',
    '/account/email/forgotpassword',
    '/account/email/resetpassword',
    '/return-policy',
    '/terms-of-use',
    '/privacy-policy',
    '/support',
    '/contact'
  ];

  const csrf = () => Axios().get('/sanctum/csrf-cookie');

  const login = async ({ data }, success, fail) => {
    setIsLoading(true);
    dispatch(setAddress(null));
    dispatch(loadingUpdate(true));
    await csrf();

    Axios()
      .post('/client/login', data)
      .then((response) => {
        currentUser();
        success(response);
        navigate('/');
      })
      .catch((error) => {
        fail(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const emailVerifyOtp = async ({ data }, success, fail) => {
    setIsLoading(true);
    dispatch(loadingUpdate(true));
    await csrf();

    Axios()
      .post('/client/email/verify_otp', data)
      .then((response) => {
        currentUser();
        success(response);
        navigate('/');
      })
      .catch((error) => {
        fail(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const logout = async () => {
    if (user) {
      setIsLoading(true);
      dispatch(setAddress(null));
      dispatch(loadingUpdate(true));
      await Axios()
        .post('/client/logout')
        .then(() => {
          if (firebase.auth()) {
            firebase.auth().signOut();
          }
          dispatch(setUser(null));
          window.location.reload();
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const currentUser = () => {
    setIsLoading(true);
    dispatch(loadingUpdate(true));
    Axios()
      .get('/api/client/user')
      .then((response) => {
        dispatch(setUser(response.data));
      })
      .catch((e) => {
        if (e.response.status === 401 && !publicRoute.includes(window.location.pathname)) {
          navigate('/account/email/signin');
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (middleware === 'guest' && redirectIfAuthenticated && user) {
      navigate(redirectIfAuthenticated);
    }

    if (middleware === 'guest' && user && redirectIfAuthenticated === '/') {
      navigate('/');
    }
  }, [user, middleware, redirectIfAuthenticated, navigate]);

  useEffect(() => {
    if (!user) {
      currentUser();
    }
  }, []);

  return {
    user,
    isLoading,
    isAuthenticated: !!user,
    login,
    emailVerifyOtp,
    logout
  };
};
