import { useQuery } from '@tanstack/react-query';
import { ReactComponent as IconBellFill } from 'bootstrap-icons/icons/bell-fill.svg';
import { ReactComponent as IconClock } from 'bootstrap-icons/icons/clock.svg';
import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Axios from '../../lib/axios';
import { loadingUpdate } from '../../redux/loadingSlice';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/id';
import { MyContext } from '../../App';

const NotificationView = () => {
  const dispatch = useDispatch();
  const context = useContext(MyContext);
  const currentPath = window.location.pathname;
  const shouldRefetch = currentPath === '/account/notification';
  const navigate = useNavigate();

  const { data: notifications, isLoading } = useQuery({
    queryKey: ['notifications'],
    queryFn: async () => {
      const res = await Axios().get('/api/client/notification/latest');
      return res.data.data;
    },
    refetchOnWindowFocus: shouldRefetch
  });

  useEffect(() => {
    if (isLoading === true) {
      dispatch(loadingUpdate(true));
    } else {
      dispatch(loadingUpdate(false));
    }
  }, [isLoading]);

  return (
    <>
      {context.windowWidth > 992 && (
        <div className="breadcrumbWrapper mb-4">
          <div className="container-fluid">
            <ul className="breadcrumb breadcrumb2 mb-0">
              <li>
                <Link to={'/'}>Home</Link>
              </li>
              <li>Notification</li>
            </ul>
          </div>
        </div>
      )}
      <div className="container mb-3">
        <div className="list-group">
          {notifications && notifications.notifications.length != 0 ? (
            notifications.notifications.map((value, index) => {
              dayjs.extend(relativeTime);
              const timeAgo = value && value.created_at && dayjs(value.created_at).fromNow();
              return (
                <Link
                  key={index}
                  to="/account/notifications"
                  className={
                    value.read_at
                      ? 'border border-secondary list-group-item list-group-item-action text-success mb-3'
                      : 'list-group-item list-group-item-action active mb-3'
                  }
                  style={
                    !value.read_at
                      ? {
                          backgroundColor: '#3BB77E',
                          color: 'white',
                          borderColor: '#3BB77E'
                        }
                      : {}
                  }
                  aria-current="true"
                >
                  <div className="d-flex w-100 justify-content-between">
                    <h5 className="mb-1">
                      <IconBellFill className="i-va" />
                      {!value.read_at && ' New Notification - '}
                      {value.read_at && ' '}
                      {value.id}
                    </h5>
                    <span className="small">
                      <IconClock /> {timeAgo}
                    </span>
                  </div>
                  <p
                    className="mb-1"
                    style={
                      !value.read_at
                        ? {
                            backgroundColor: '#3BB77E',
                            color: 'white',
                            borderColor: '#3BB77E'
                          }
                        : {}
                    }
                  >
                    {value.data.data}
                  </p>
                </Link>
              );
            })
          ) : (
            <div className="justify-content-center align-items-center d-flex my-5">
              <p className="text-center">No any notifications have been found yet!</p>
            </div>
          )}
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <button
            style={{ backgroundColor: '#3BB77E', color: 'white' }}
            onClick={() => navigate('/account/notifications')}
            className="btn my-3"
          >
            View All
          </button>
        </div>
      </div>
    </>
  );
};

export default NotificationView;
