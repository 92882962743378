/* eslint-disable jsx-a11y/alt-text */
import 'aos/dist/aos.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import 'datatables.net-bs5';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import 'font-awesome/css/font-awesome.min.css';
import React, { createContext } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css';
import './App.css';
import './responsive.css';

import DetailsPage from './pages/Details';
import Home from './pages/Home/index';
import Listing from './pages/Listing';
import NotFound from './pages/NotFound';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import Cart from './pages/cart';
import Checkout from './pages/checkout';

import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

import AOS from 'aos';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/lib/integration/react';
import data from './data';
import DefaultLayout from './layout/DefaultLayout';
import Contact from './pages/Contact';
import ProductListing from './pages/ProductListing';
import PromoListing from './pages/Promo/PromoListing';
import ForgotPasswordEmail from './pages/account/ForgotPasswordEmail';
import MyTransaction from './pages/account/MyTransaction';
import Notification from './pages/account/Notification';
import NotificationAll from './pages/account/NotificationAll';
import ResetPasswordEmail from './pages/account/ResetPasswordEmail';
import SignInEmail from './pages/account/SignInEmail';
import SignInPhone from './pages/account/SignInPhone';
import SignUpEmail from './pages/account/SignUpEmail';
import TransactionDetail from './pages/account/TranasctionDetail';
import VerifyOtpEmail from './pages/account/VerifyOtpEmail';
import VerifyOtpPhone from './pages/account/VerifyOtpPhone';
import Wishlist from './pages/account/Wishlist';
import AccountAddAddress from './pages/account/address/AccountAddAddress';
import AccountEditAddress from './pages/account/address/AccountEditAddress';
import MyListAddress from './pages/account/address/MyListAddress';
import Providers from './providers';
import { persistor, store } from './redux/Store';
import MyProfileView from './pages/account/MyProfile';
import ProductCategory from './pages/ProductCategory';
import PromoDetail from './pages/Promo/detail/PromoDetail';
import Support from './pages/Support';
import { SkeletonTheme } from 'react-loading-skeleton';
import 'skeleton-mammoth/dist/skeleton-mammoth.min.css';

const MyContext = createContext();

function App() {
  React.useEffect(() => {
    AOS.init({
      duration: 1200,
      easing: 'ease-in-out'
    });
  });
  return (
    data.productData.length !== 0 && (
      <BrowserRouter>
        <SkeletonTheme>
          <Providers>
            <ToastContainer
              style={{ zIndex: 10001 }}
              position="top-right"
              autoClose={1000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="colored"
            />
            <TawkMessengerReact propertyId={process.env.REACT_APP_TAWK_PROPERTY_ID} widgetId={process.env.REACT_APP_TAWK_WIDGET_ID} />

            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <DefaultLayout data={data}>
                  <Routes>
                    <Route exact={true} path="/" element={<Home data={data.productData} />} />
                    <Route exact={true} path="/account/notification" element={<Notification />} />
                    <Route exact={true} path="/account/notifications" element={<NotificationAll />} />
                    <Route exact={true} path="/account/email/signin" element={<SignInEmail />} />
                    <Route exact={true} path="/account/email/verify-otp" element={<VerifyOtpEmail />} />
                    <Route exact={true} path="/account/phone/signin" element={<SignInPhone />} />
                    <Route exact={true} path="/account/phone/verify-otp" element={<VerifyOtpPhone />} />
                    <Route exact={true} path="/account/email/signup" element={<SignUpEmail />} />
                    <Route exact={true} path="/account/email/forgotpassword" element={<ForgotPasswordEmail />} />
                    <Route exact={true} path="/account/email/resetpassword" element={<ResetPasswordEmail />} />
                    <Route exact={true} path="/account/transaction" element={<MyTransaction />} />
                    <Route exact={true} path="/contact" element={<Contact />} />
                    <Route exact={true} path="/account/transaction/detail/:id" element={<TransactionDetail />} />
                    {/* <Route exact={true} path="/account/profile" element={<MyProfileView />} /> */}
                    <Route exact={true} path="/account/profile" element={<MyProfileView />} />
                    <Route exact={true} path="/account/list-address" element={<MyListAddress />} />
                    <Route exact={true} path="/account/add-address" element={<AccountAddAddress />} />
                    <Route exact={true} path="/account/edit-address/:id" element={<AccountEditAddress />} />
                    <Route exact={true} path="/account/wishlist" element={<Wishlist />} />
                    <Route exact={true} path="/products" element={<ProductListing />} />
                    <Route exact={true} path="/promos" element={<PromoListing />} />
                    <Route exact={true} path="/promo/:id" element={<PromoDetail />} />
                    <Route exact={true} path="/category/:id" element={<ProductCategory />} />
                    <Route exact={true} path="/cat/:id/:id" element={<Listing data={data.productData} single={false} />} />
                    <Route exact={true} path="/product/:id" element={<DetailsPage />} />
                    <Route exact={true} path="/cart" element={<Cart />} />
                    <Route exact={true} path="/signIn" element={<SignIn />} />
                    <Route exact={true} path="/signUp" element={<SignUp />} />
                    <Route exact={true} path="/support" element={<Support />} />
                    <Route exact={true} path="/checkout" element={<Checkout />} />
                    <Route exact={true} path="*" element={<NotFound />} />
                  </Routes>
                </DefaultLayout>
              </PersistGate>
            </Provider>
          </Providers>
        </SkeletonTheme>
      </BrowserRouter>
    )
  );
}

export default App;

export { MyContext };
