import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Container, Row, Col, Card, Button, Form, Alert } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { handleErrors } from '../../../lib/handleErrors';
import Axios from '../../../lib/axios';
import { loadingUpdate } from '../../../redux/loadingSlice';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import AsyncSelect from 'react-select/async';
import Map from '../../../components/GoogleMap/Map';

const AccountEditAddress = () => {
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { errors, isSubmitting, isValid }
  } = useForm({ mode: 'onChange' });
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    try {
      let response = await Axios().post(`/api/client/profile/address/${id}`, data);
      toast.success(response.data.message);
      reset();
      navigate('/account/list-address');
    } catch (error) {
      handleErrors(error);
    }
  };

  const loadOptions = async (inputValue, callback) => {
    try {
      setLoading(true);
      const response = await Axios().post(`/api/client/biteship/map/single`, { name: inputValue });
      const optionLabels = response.data.data.areas.map((option) => ({ value: option.id, label: option.name }));
      callback(optionLabels);
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedLoadOptions = debounce(loadOptions, 1000);

  const handleInputChange = (value) => {
    debouncedLoadOptions(value, () => {});
  };

  const handleMapChange = (newState) => {
    const currentValues = getValues();
    reset({
      ...currentValues,
      destination_latitude: newState.lat,
      destination_longitude: newState.lng,
      address: newState.fullpath
    });
  };

  useEffect(() => {
    dispatch(loadingUpdate(true));
    try {
      const fetchData = async () => {
        const res = await Axios().get(`/api/client/profile/address/${id}`);
        reset(res.data.data);
      };
      fetchData();
    } catch (e) {
      handleErrors(e);
    } finally {
      dispatch(loadingUpdate(false));
    }
  }, [dispatch, id, reset]);

  return (
    <Container className="my-5">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="d-flex gap-2"></div>
      </div>

      <Row>
        <Col xs={12}>
          <Card>
            <Card.Header>Edit Address</Card.Header>
            <Card.Body>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group className="mb-3">
                  <Form.Label>Address</Form.Label>
                  {getValues('destination_latitude') && getValues('destination_longitude') && (
                    <Map
                      {...register('address', {
                        required: true
                      })}
                      center={{ lat: parseFloat(getValues('destination_latitude')), lng: parseFloat(getValues('destination_longitude')) }}
                      height="300px"
                      zoom={15}
                      onMarkerChange={handleMapChange}
                    />
                  )}
                </Form.Group>
                {errors.address && (
                  <Alert variant="danger" className="p-2">
                    {errors.address.message}
                  </Alert>
                )}
                <Form.Group className="mb-3" controlId="areaName">
                  <Form.Label>Area Selection</Form.Label>
                  {getValues('destination_area_name') ? (
                    <AsyncSelect
                      isDisabled={isSubmitting}
                      placeholder="Enter Area Name"
                      id="areaSelection"
                      onInputChange={handleInputChange}
                      onChange={(selectedOption) => {
                        setValue('destination_area_id', selectedOption ? selectedOption.value : null);
                        setValue('destination_area_name', selectedOption ? selectedOption.label : null);
                      }}
                      isLoading={loading}
                      loadOptions={debouncedLoadOptions}
                      defaultInputValue={getValues('destination_area_name')}
                      key="name"
                    />
                  ) : (
                    <p>Loading...</p>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    name="description"
                    disabled={isSubmitting}
                    {...register('description', {
                      required: { value: true, message: 'Description is required' }
                    })}
                    type="text"
                    placeholder="Enter Description"
                  />
                </Form.Group>
                {errors.description && (
                  <Alert variant="danger" className="p-2">
                    {errors.description.message}
                  </Alert>
                )}
                <div className="mt-3 d-flex gap-3">
                  <Button disabled={!isValid || isSubmitting} type="submit" variant="success">
                    Edit Address
                  </Button>
                  <Button className="mx-3" variant="secondary" onClick={() => navigate('/account/list-address')}>
                    Back
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AccountEditAddress;
