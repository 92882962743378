/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { Button } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import bannerImg from '../../assets/images/banner1.jpg';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';

import { MyContext } from '../../App';

const Sidebar = (props) => {
  const navigate = useNavigate();
  const [value, setValue] = useState([]);
  const [brandFilters, setBrandFilters] = React.useState([]);
  const ratingsArr = [1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5];
  const [totalLength, setTotalLength] = useState([]);

  const context = useContext(MyContext);

  var catLength = 0;
  var lengthArr = [];

  useEffect(() => {
    if (props.data && props.category) {
      props.category.length !== 0 &&
        props.category.map((item) => {
          props.data.length !== 0 &&
            props.data.map((item_) => {
              if (item_?.category?.toLowerCase() === item?.name?.toLowerCase()) {
                catLength += 1;
              }
            });
          lengthArr.push(catLength);
          catLength = 0;
        });

      const list = lengthArr.filter((item, index) => lengthArr.indexOf(item) === index);
      setTotalLength(list);
    }
  }, [props.category, props.data]);

  useEffect(() => {
    setValue([props.minPrice, props.maxPrice]);
  }, [props.minPrice, props.maxPrice]);

  useEffect(() => {
    if (props.company) {
      setBrandFilters(props.company);
    }
  }, [props.company]);

  useEffect(() => {
    filterByPrice(value[0], value[1]);
  }, [value]);

  const filterByBrand = (keyword) => {
    props.filterByBrand(keyword);
  };

  const filterByRating = (keyword) => {
    props.filterByRating(parseFloat(keyword));
  };

  const filterByPrice = (minValue, maxValue) => {
    props.filterByPrice(minValue, maxValue);
  };

  return (
    <>
      <div className={`sidebar ${context.isOpenFilters === true && 'open'}`}>
        {props.category && (
          <div className="card border-0 shadow res-hide">
            <h3>Category</h3>
            <div className="catList">
              {props.category &&
                props.category.length !== 0 &&
                props.category.map((item, index) => {
                  return (
                    <Link key={index} to={`/category/${item.name.toLowerCase()}`}>
                      <div className="catItem d-flex align-items-center">
                        <span className="img">
                          <img src="https://wp.alithemes.com/html/nest/demo/assets/imgs/theme/icons/category-1.svg" width={30} />
                        </span>
                        <h4 className="mb-0 ml-3 mr-3 text-capitalize">{item.name}</h4>
                        <span className="d-flex align-items-center justify-content-center rounded-circle ml-auto">
                          {totalLength[index]}
                        </span>
                      </div>
                    </Link>
                  );
                })}
            </div>
          </div>
        )}

        <div className="card border-0 shadow">
          <h3>Fill by price</h3>

          <RangeSlider value={value} onInput={setValue} min={props.minPrice} max={props.maxPrice} step={5} />

          <div className="d-flex pt-2 pb-2 priceRange">
            <span>
              From: <strong className="text-success">Rp: {value[0]}</strong>
            </span>
            <span className="ml-auto">
              From: <strong className="text-success">Rp: {value[1]}</strong>
            </span>
          </div>

          <div className="filters pt-5">
            <h5>Filter By Company</h5>

            <ul className="mb-0">
              <RadioGroup aria-labelledby="demo-radio-buttons-group-label" defaultValue="female" name="radio-buttons-group">
                {brandFilters.length !== 0 &&
                  brandFilters.map((item, index) => {
                    return (
                      <li key={index}>
                        <FormControlLabel
                          value={item.name}
                          control={<Radio onChange={() => filterByBrand(item.name)} />}
                          label={item.name}
                        />
                      </li>
                    );
                  })}
              </RadioGroup>
            </ul>
          </div>

          <div className="filters pt-0">
            <h5>Filter By Ratings</h5>
            <ul>
              <RadioGroup aria-labelledby="demo-radio-buttons-group-label" defaultValue="female" name="radio-buttons-group">
                {ratingsArr.length !== 0 &&
                  ratingsArr.map((item, index) => {
                    return (
                      <li key={index}>
                        <FormControlLabel value={item} control={<Radio onChange={() => filterByRating(item)} />} label={item} />
                      </li>
                    );
                  })}
              </RadioGroup>
            </ul>
          </div>

          <div className="d-flex filterWrapper">
            <Button className="btn btn-g w-100" onClick={() => context.openFilters()}>
              <FilterAltOutlinedIcon /> Filter
            </Button>
          </div>
        </div>

        <img style={{ cursor: 'pointer' }} onClick={() => navigate('/products')} src={bannerImg} className="w-100" />
      </div>
    </>
  );
};

export default Sidebar;
