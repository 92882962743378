import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { Button } from '@mui/material';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import Axios from '../../lib/axios';
import LoadingSpinner from '../LoadingSpinner';
import './style.css';

const Newsletter = () => {
  const [email, setEmail] = useState('');
  const [loadCircle, setLoadCircle] = useState(false);

  const handleErrors = (error) => {
    if (error) {
      if (error.includes('Member Exists')) {
        toast.error('This email is already subscribed.');
      } else if (error.includes('Forgotten Email Not Subscribed')) {
        toast.error('This email was permanently deleted and cannot be re-subscribed.');
      } else {
        toast.error(error || 'Failed to subscribe. Please try again.');
      }
    }
  };
  const handleSubscribe = async () => {
    try {
      setLoadCircle(true);
      const response = await Axios().post(
        `api/client/subscribe`,
        { email },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.status === 200) {
        toast.success('Successfully subscribed!');
        setEmail('');
      } else {
        toast.error(response.data.error || 'Failed to subscribe. Please try again.');
      }
      setLoadCircle(false);
    } catch (error) {
      if (error?.response?.data?.error) {
        handleErrors(error?.response?.data?.error);
      } else {
        toast.error('Failed to subscribe. Please try again.');
      }
      // if (error?.response) {
      //   handleErrors(error);
      // } else {
      //   toast.error('Failed to subscribe. Please try again.');
      // }
      setLoadCircle(false);
    }
  };

  return loadCircle == true ? (
    <LoadingSpinner isLoading={loadCircle} />
  ) : (
    <>
      <div className="newsLetterBanner">
        <SendOutlinedIcon />
        <input type="text" placeholder="Your email address" value={email} onChange={(e) => setEmail(e.target.value)} />
        <Button className="bg-g" onClick={handleSubscribe}>
          Subscribe
        </Button>
      </div>
    </>
  );
};

export default Newsletter;
